import React from 'react';
import { Button, Input, Form, Icon, notification } from 'antd';
import { Redirect } from 'react-router-dom';
import 'antd/dist/antd.css';
import './login.css';
import authen from './authen.js';
import reqwest from 'reqwest';

import cookie from 'js-cookie';

class App extends React.Component {
  state = {
    redirectToReferrer: false,
    isSendingRequest: false,
  };

  componentDidMount() {
    document.title = 'Login';

    const token = cookie.get('token');

    if (token) {
      reqwest({
        url: process.env.REACT_APP_API_URL + '/auth/',
        method: 'get',
        type: 'json',
        data: {
          token,
        },
      })
        .then((data) => {
          authen.authenticate(() => {
            this.setState({
              redirectToReferrer: true,
              username: data.message,
            });
          });
        })
        .catch((err) => {
          console.log({ err });
        });
    }
  }

  showModal = (message) => {
    this.setState({
      visible: true,
      message: message,
    });
  };

  closeModal = () => {
    this.setState({
      visible: false,
    });
  };

  handleLogin = (e) => {
    e.preventDefault();

    this.setState({ isSendingRequest: true });

    this.props.form.validateFields((err, values) => {
      if (err) {
        this.setState({ isSendingRequest: false });

        return;
      }

      this.props.pageLoading(true);

      reqwest({
        url: process.env.REACT_APP_API_URL + '/auth/login',
        method: 'post',
        type: 'json',
        data: values,
      })
        .then((data) => {
          this.props.pageLoading(false);

          cookie.set('token', data.message);

          reqwest({
            url: process.env.REACT_APP_API_URL + '/auth/',
            method: 'get',
            type: 'json',
            data: {
              token: cookie.get('token'),
            },
          })
            .then((data) => {
              this.props.setUsername(data.message);

              authen.authenticate(() => {
                this.setState({
                  redirectToReferrer: true,
                });
              });
            })
            .catch((err) => {
              console.log({ err });
            });
        })
        .catch((err) => {
          let message = err.message;

          if (err.responseText) {
            const jsonResponse = JSON.parse(err.responseText);
            message = jsonResponse.message || message;
          }

          notification.error({
            key: 'notification',
            message: 'Fail',
            description: message,
            duration: 5,
          });
        })

        .always(() => {
          // this.props.pageLoading(false);
          this.setState({ isSendingRequest: false });
        });
    });
  };

  render() {
    const { redirectToReferrer, isSendingRequest } = this.state;

    if (redirectToReferrer === true) {
      return <Redirect to="/careline_message" />;
    }

    const { getFieldDecorator } = this.props.form;

    return (
      <div
        id="login-component"
        style={{
          background:
            "url('../assets/images/people.png') no-repeat bottom center",
          backgroundSize: '100% auto',
        }}
      >
        <Form
          onSubmit={this.handleLogin}
          id="login-form"
          className="login-form"
          layout={'vertical'}
        >
          <div
            style={{
              height: 138,
              marginBottom: 30,
            }}
          >
            <img
              src="./assets/images/logo.png"
              alt=""
              style={{ display: 'block', margin: 'auto' }}
            />
          </div>

          <Form.Item label="">
            {getFieldDecorator('username', {
              rules: [
                { required: true, message: 'Please input your username!' },
              ],
            })(
              <Input
                size="large"
                placeholder="Username"
                autoComplete="new-password"
                prefix={
                  <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />
                }
              />,
            )}
          </Form.Item>

          <Form.Item label="">
            {getFieldDecorator('password', {
              rules: [
                { required: true, message: 'Please input your password!' },
              ],
            })(
              <Input.Password
                size="large"
                placeholder="Password"
                autoComplete="new-password"
                prefix={
                  <Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />
                }
              />,
            )}
          </Form.Item>

          <Button
            block
            size="large"
            icon="login"
            id="login-btn"
            htmlType="submit"
            loading={isSendingRequest}
            disabled={isSendingRequest}
          >
            Login
          </Button>
        </Form>
      </div>
    );
  }
}

export default Form.create({ name: 'normal_login' })(App);
