import React from 'react';
import {
  Modal,
  Select,
  Drawer,
  Form,
  Icon,
  Checkbox,
  Typography,
  Table,
  Button,
  Row,
  Col,
  Input,
  DatePicker,
} from 'antd';
import 'antd/dist/antd.css';
import './register.css';
import reqwest from 'reqwest';
import Dotdotdot from 'react-dotdotdot';

import moment from 'moment';
const { Search } = Input;
const { Text, Title } = Typography;

let searchTimeoutId = false;

class App extends React.Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        title: 'Register date',
        dataIndex: 'created_time',
        key: 'created_time',
        render: (data) => (
          <div>
            <Dotdotdot clamp={1}>
              <Text style={{ wordWrap: 'break-word' }}>
                {moment(data).format('DD/MM/YYYY')}
              </Text>
            </Dotdotdot>
          </div>
        ),
      },
      {
        title: 'Full name',
        dataIndex: 'fullname',
        key: 'fullname',
        render: (data) => (
          <div>
            <Text>{data}</Text>
          </div>
        ),
      },
      {
        title: 'Address',
        dataIndex: 'address',
        key: 'address',
        render: (data) => (
          <div>
            <Text>{data}</Text>
          </div>
        ),
      },
      {
        title: 'Phone number',
        dataIndex: 'phone',
        key: 'phone',
        render: (data, row) => (
          <div>
            <Text>{data}</Text>
          </div>
        ),
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        render: (data, row) => (
          <div>
            <Text>{data}</Text>
          </div>
        ),
      },
      {
        title: 'Pregnant',
        dataIndex: 'pregnant',
        key: 'pregnant',
        render: (data, row) => (
          <div>
            <Text>{data}</Text>
          </div>
        ),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: 100,
        align: 'center',
        render: (status) =>
          status === 1 ? (
            <i className="fa fa-check" style={{ fontSize: 15 }}></i>
          ) : (
            <i className="fa fa-times" style={{ fontSize: 15 }}></i>
          ),
      },
      {
        title: 'Manage',
        dataIndex: 'id',
        key: 'manage',
        width: 100,
        render: (key) => (
          <Button
            onClick={() => {
              this.showDrawer(key);
            }}
            className="icon-btn"
          >
            Edit
          </Button>
        ),
      },
    ];
  }

  handleExport() {
    window.open(
      process.env.REACT_APP_API_URL +
        '/club_register/export?search=' +
        this.state.searchValue +
        '&pregnantValue=' +
        this.state.pregnantValue +
        '&registerDateValue=' +
        this.state.registerDateValue,
    );
  }

  state = {
    data: [],
    pagination: { pageSize: 10 },
    loading: false,
    visible: false,
    deleteButton: false,
    saveButton: true,
    deleteModalVisible: false,
    deleteLoading: false,
    searchValue: '',
    pregnantValue: '',
    registerDateValue: '',
    child_required: false,
    child_pregnant_required: false,
    child_hidden: 'none',
    detail: {
      id: '',
      fullname: '',
      status: '1',
      phone: '',
      pregnant: false,
      have_other_children: false,
      address1: '',
      address2: '',
      child_fullname: '',
      child_birthdate: null,
      get_by: '',
    },
  };

  showDrawer = async (key) => {
    this.props.form.resetFields();

    const data = {
      id: '',
      fullname: '',
      phone: '',
      pregnant: false,
      have_other_children: false,
      address1: '',
      address2: '',
      child_fullname: '',
      child_birthdate: null,
      get_by: '',
      status: '1',
    };

    if ((parseInt(key, 10) || 0) > 0) {
      Object.assign(
        data,
        this.state.data.find((item) => item.key === key) || {},
      );

      // Convert to string
      Object.assign(data, {
        fullname: data.fullname || '',
        phone: data.phone || '',
        house: data.house || '',
        street: data.street || '',
        commune: data.commune || '',
        sangkat: data.sangkat || '',
        province: data.province || '',
        pregnant: data.pregnant || '',
        // pregnant_date: data.pregnant_date || '',
        have_other_children: data.have_other_children || '',
        child_fullname: data.child_fullname || '',
        // child_birthdate: data.child_birthdate || '',
        labour: data.labour || '',
        // opt_in: data.opt_in || '',
        gender: data.gender || '',
        child_fullname_pregnant: data.child_fullname_pregnant || '',
        // child_birthdate_pregnant: data.child_birthdate_pregnant || '',
        labour_pregnant: data.labour_pregnant || '',
        gender_pregnant: data.gender_pregnant || '',
        status: `${data.status || 0}`,
      });
    }

    this.props.form.setFieldsValue({
      id: data.id,
      fullname: data.fullname,
      phone: data.phone,
      house: data.house,
      street: data.street,
      commune: data.commune,
      sangkat: data.sangkat,
      province: data.province,
      pregnant: data.pregnant,
      pregnant_date: data.pregnant_date ? moment(data.pregnant_date) : null,
      have_other_children: data.have_other_children,
      child_fullname: data.child_fullname,
      child_birthdate: data.child_birthdate
        ? moment(data.child_birthdate)
        : null,
      labour: data.labour,
      opt_in: data.opt_in ? data.opt_in.split(',') : [],
      gender: data.gender,
      child_fullname_pregnant: data.child_fullname_pregnant,
      child_birthdate_pregnant: data.child_birthdate_pregnant
        ? moment(data.child_birthdate_pregnant)
        : null,
      labour_pregnant: data.labour_pregnant,
      gender_pregnant: data.gender_pregnant,
      status: data.status,
    });

    this.setState({
      deleteButton: key === -1 ? true : false,
      visible: true,
      detail: data,
      pregnant: key === -1 ? 'none' : data.pregnant === 'No' ? 'none' : 'block',
      pregnant_child:
        key === -1 ? 'none' : data.pregnant !== 'No' ? 'none' : 'block',
      child_required: data.have_other_children !== 'No',
      child_pregnant_required: data.pregnant === 'No',
      child_hidden:
        key === -1
          ? 'none'
          : data.have_other_children === 'No'
          ? 'none'
          : 'block',
      saveButton: true,
    });
  };

  showDeleteModal = () => {
    this.setState({
      deleteModalVisible: true,
    });
  };

  handleDeleteModalCancel = () => {
    this.setState({ deleteModalVisible: false });
  };

  handleDeleteModalOk = () => {
    const detail = this.state.detail;
    this.setState({ deleteLoading: true });

    reqwest({
      url: process.env.REACT_APP_API_URL + '/club_register/delete',
      method: 'post',
      data: {
        id: detail.id,
      },
      type: 'json',
    }).then(() => {
      this.setState({
        deleteLoading: false,
        deleteModalVisible: false,
        visible: false,
      });
      this.fetch();
    });
  };

  onSave = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (values.password !== values.confirmPassword) {
          this.props.form.setFields({
            confirmPassword: {
              value: values.confirmPassword,
              errors: [new Error('Confirm Password is not same Password')],
            },
          });
        } else {
          this.setState({ saveButton: false });

          values.child_birthdate = moment(values.child_birthdate).format(
            'YYYY-MM-DD',
          );

          if (values.have_other_children === 'No') {
            values.gender = '';
            values.child_birthdate = null;
            values.child_fullname = '';
          }

          values.child_birthdate_pregnant = moment(
            values.child_birthdate_pregnant,
          ).format('YYYY-MM-DD');

          if (values.opt_in) {
            values.opt_in = values.opt_in.join(',');
          }

          reqwest({
            url: process.env.REACT_APP_API_URL + '/club_register/save',
            method: 'post',
            data: values,
            type: 'json',
          }).then((data) => {
            if (data.success) {
              this.setState({ visible: false });
              this.fetch();
            } else {
              this.setState({ saveButton: true });
            }
          });
        }
      }
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  componentDidMount() {
    document.title = 'Club Register';
    this.fetch();
  }

  getIndexInParent = (el) => {
    return Array.from(el.parentNode.children).indexOf(el);
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });
    this.fetch({
      size: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters,
    });
  };

  fetch = (params = {}) => {
    this.props.pageLoading(true);
    reqwest({
      url: process.env.REACT_APP_API_URL + '/club_register',
      method: 'get',
      data: {
        size: this.state.pagination.pageSize,
        search: this.state.searchValue,
        pregnantValue: this.state.pregnantValue,
        registerDateValue: this.state.registerDateValue,
        ...params,
      },
      type: 'json',
    }).then((data) => {
      this.setState({
        data: data.results,
      });
      this.props.pageLoading(false);
      document.body.style.overflow = null;
    });
  };

  handleSearchChange(value) {
    this.setState({ searchValue: value });
    if (searchTimeoutId) {
      window.clearTimeout(searchTimeoutId);
    }
    searchTimeoutId = window.setTimeout(() => {
      this.fetch({
        pregnantValue: this.state.pregnantValue,
        registerDateValue: this.state.registerDateValue,
      });
    }, 1500);
  }

  handleIsPregnantChange = (value) => {
    this.setState({
      pregnant_required: value === 'Yes',
      pregnant: value === 'Yes' ? 'block' : 'none',
      child_pregnant_required: value !== 'Yes',
      pregnant_child: value !== 'Yes' ? 'block' : 'none',
    });
  };

  handleIsPregnantFilterChange = (value) => {
    this.setState({ pregnantValue: value });
    if (searchTimeoutId) {
      window.clearTimeout(searchTimeoutId);
    }
    searchTimeoutId = window.setTimeout(() => {
      this.fetch({
        pregnantValue: this.state.pregnantValue,
        registerDateValue: this.state.registerDateValue,
      });
    }, 1500);
  };

  handleIsRegisterDateFilterChange = (value) => {
    const date = value ? moment(value).format('DD/MM/YYYY') : '';
    this.setState({ registerDateValue: date });
    if (searchTimeoutId) {
      window.clearTimeout(searchTimeoutId);
    }
    searchTimeoutId = window.setTimeout(() => {
      this.fetch({
        pregnantValue: this.state.pregnantValue,
        registerDateValue: this.state.registerDateValue,
      });
    }, 1500);
  };

  handleHaveOtherChildChange = (value) => {
    this.setState({
      child_required: value !== 'No',
      child_hidden: value === 'No' ? 'none' : 'block',
    });
  };

  handleOptInChange = (value) => {
    const { detail } = this.state;
    detail.opt_in = value;
    this.setState({
      detail: detail,
    });
  };

  render() {
    const {
      saveButton,
      deleteButton,
      deleteModalVisible,
      deleteLoading,
      searchValue,
      detail,
    } = this.state;
    const { getFieldDecorator } = this.props.form;
    const options = [
      { label: 'Address', value: 'Address' },
      { label: 'Phone', value: 'Phone' },
      { label: 'SMS', value: 'SMS' },
      { label: 'Email', value: 'Email' },
    ];

    const optionItems = options.map((item, i) => (
      <Row key={i}>
        <Col span={12}>
          <Checkbox key={item.label} value={item.value}>
            {item.label}
          </Checkbox>
        </Col>
      </Row>
    ));

    return (
      <div id="register-component">
        <Row>
          <Col span={24}>
            <Title level={1}>Club Register</Title>
          </Col>
        </Row>
        <div className="paper-panel">
          <Row gutter={{ xs: 8, sm: 8, md: 8, lg: 8 }}>
            <Col span={7}>
              <Search
                placeholder="Full name / Phone Number "
                onChange={({ target: { value } }) =>
                  this.handleSearchChange(value)
                }
                style={{ width: '100%' }}
                value={searchValue}
              />
            </Col>
            <Col span={4}>
              <DatePicker
                format={'DD/MM/YYYY'}
                onChange={this.handleIsRegisterDateFilterChange}
              />
            </Col>
            <Col span={4}>
              <Select
                style={{ width: '100%', marginRight: 8 }}
                onChange={this.handleIsPregnantFilterChange}
                placeholder="Pregnant"
              >
                <Select.Option value="">All</Select.Option>
                <Select.Option value="Yes">Yes</Select.Option>
                <Select.Option value="No">No</Select.Option>
              </Select>
            </Col>
            <Col span={9} className="text-right">
              <Button
                type="default"
                style={{ minWidth: 140, marginRight: '8px' }}
                onClick={() => this.handleExport()}
              >
                Export
              </Button>
              <Button
                type="primary"
                style={{ minWidth: 140 }}
                onClick={() => this.showDrawer(-1)}
              >
                <Icon type="plus" />
                Create
              </Button>
            </Col>
          </Row>

          <Table
            rowKey="id"
            columns={this.columns}
            dataSource={this.state.data}
            pagination={this.state.pagination}
            loading={this.state.loading}
            onChange={this.handleTableChange}
          />

          <Drawer
            id="register-component-drawer"
            title="Manage Profile"
            width={460}
            closable={false}
            onClose={this.onClose}
            headerStyle={{ textTransform: 'uppercase', fontWeight: 'bold' }}
            visible={this.state.visible}
            bodyStyle={{ paddingBottom: 120 }}
          >
            <Form onSubmit={this.onSave} layout="vertical" hideRequiredMark>
              <Form.Item label="" style={{ display: 'none' }}>
                {getFieldDecorator('id', {
                  initialValue: detail.id,
                })(<Input />)}
              </Form.Item>
              <Row gutter={[16, 0]}>
                <Col span={12}>
                  <Form.Item label="Full name*">
                    {getFieldDecorator('fullname', {
                      validateTrigger: ['onBlur'],
                      rules: [
                        {
                          required: true,
                          message: 'Please enter full name',
                        },
                      ],
                    })(<Input placeholder="" />)}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Phone number*">
                    {getFieldDecorator('phone', {
                      validateTrigger: ['onBlur'],
                      rules: [
                        {
                          required: true,
                          message: 'Please enter phone number',
                        },
                      ],
                    })(<Input placeholder="" />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 0]}>
                <Col span={12}>
                  <Form.Item label="Password*">
                    {getFieldDecorator(
                      'password',
                      {},
                    )(<Input.Password placeholder="" />)}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Re-Password*">
                    {getFieldDecorator(
                      'confirmPassword',
                      {},
                    )(<Input.Password placeholder="" />)}
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item label="House N#">
                {getFieldDecorator('house', {
                  initialValue: detail.house,
                  rules: [
                    {
                      required: true,
                      message: 'Please enter House N#',
                    },
                  ],
                })(<Input placeholder="" />)}
              </Form.Item>
              <Form.Item label="Street">
                {getFieldDecorator('street', {
                  initialValue: detail.street,
                  rules: [
                    {
                      required: false,
                      message: 'Please enter Street',
                    },
                  ],
                })(<Input placeholder="" />)}
              </Form.Item>
              <Form.Item label="Commune">
                {getFieldDecorator('commune', {
                  initialValue: detail.commune,
                  rules: [
                    {
                      required: false,
                      message: 'Please enter Commune',
                    },
                  ],
                })(<Input placeholder="" />)}
              </Form.Item>
              <Form.Item label="Sangkat">
                {getFieldDecorator('sangkat', {
                  initialValue: detail.sangkat,
                  rules: [
                    {
                      required: false,
                      message: 'Please enter Sangkat',
                    },
                  ],
                })(<Input placeholder="" />)}
              </Form.Item>
              <Form.Item label="City/Province">
                {getFieldDecorator('province', {
                  initialValue: detail.province,
                  rules: [
                    {
                      required: false,
                      message: 'Please enter City/Province',
                    },
                  ],
                })(
                  <Select style={{ width: '100%', marginRight: 8 }}>
                    <Select.Option value="Banteay Meanchey">
                      Banteay Meanchey
                    </Select.Option>
                    <Select.Option value="Battambang">Battambang</Select.Option>
                    <Select.Option value="Kampong Cham">
                      Kampong Cham
                    </Select.Option>
                    <Select.Option value="Kampong Chhnang">
                      Kampong Chhnang
                    </Select.Option>
                    <Select.Option value="Kampong Speu">
                      Kampong Speu
                    </Select.Option>
                    <Select.Option value="Kampong Thom">
                      Kampong Thom
                    </Select.Option>
                    <Select.Option value="Kampot">Kampot</Select.Option>
                    <Select.Option value="Kandal">Kandal</Select.Option>
                    <Select.Option value="Koh Kong">Koh Kong</Select.Option>
                    <Select.Option value="Kratié">Kratié</Select.Option>
                    <Select.Option value="Mondulkiri">Mondulkiri</Select.Option>
                    <Select.Option value="មណ្ឌលគិរី">មណ្ឌលគិរី</Select.Option>
                    <Select.Option value="Preah Vihear">
                      Preah Vihear
                    </Select.Option>
                    <Select.Option value="Prey Veng">Prey Veng</Select.Option>
                    <Select.Option value="Pursat">Pursat</Select.Option>
                    <Select.Option value="Ratanak Kiri">
                      Ratanak Kiri
                    </Select.Option>
                    <Select.Option value="Siem Reap">Siem Reap</Select.Option>
                    <Select.Option value="Preah Sihanouk">
                      Preah Sihanouk
                    </Select.Option>
                    <Select.Option value="Steung Treng">
                      Steung Treng
                    </Select.Option>
                    <Select.Option value="Svay Rieng">Svay Rieng</Select.Option>
                    <Select.Option value="Takéo">Takéo</Select.Option>
                    <Select.Option value="Oddar Meanchey">
                      Oddar Meanchey
                    </Select.Option>
                    <Select.Option value="Kep">Kep</Select.Option>
                    <Select.Option value="Pailin">Pailin</Select.Option>
                    <Select.Option value="Tboung Khmum">
                      Tboung Khmum
                    </Select.Option>
                  </Select>,
                )}
              </Form.Item>
              <Row gutter={[16, 0]}>
                <Col span={12}>
                  <Form.Item label="Are you pregnant?">
                    {getFieldDecorator('pregnant', {
                      validateTrigger: ['onChange', 'onBlur'],
                      rules: [
                        { required: true, message: 'Please choose answer' },
                      ],
                    })(
                      <Select
                        style={{ width: '100%', marginRight: 8 }}
                        onChange={this.handleIsPregnantChange}
                      >
                        <Select.Option value="Yes">Yes</Select.Option>
                        <Select.Option value="No">No</Select.Option>
                      </Select>,
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Pregnant Date"
                    style={{ display: this.state.pregnant }}
                  >
                    {getFieldDecorator('pregnant_date', {
                      rules: [
                        {
                          required: this.state.pregnant_required,
                          message: 'Please enter Pregnant date',
                        },
                      ],
                    })(<DatePicker format={'DD/MM/YYYY'} />)}
                  </Form.Item>

                  <Form.Item
                    label="Child's full name"
                    style={{ display: this.state.pregnant_child }}
                  >
                    {getFieldDecorator('child_fullname_pregnant', {
                      validateTrigger: ['onChange', 'onBlur'],
                      rules: [
                        {
                          required: this.state.child_pregnant_required,
                          message: "Please enter child's full name",
                        },
                      ],
                    })(<Input placeholder="" />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row
                gutter={[16, 0]}
                style={{ display: this.state.pregnant_child }}
              >
                <Col span={12}>
                  <Form.Item label="Gender">
                    {getFieldDecorator('gender_pregnant', {
                      validateTrigger: ['onChange', 'onBlur'],
                      rules: [
                        {
                          required: this.state.child_pregnant_required,
                          message: 'Please select',
                        },
                      ],
                    })(
                      <Select
                        style={{ width: '100%', marginRight: 8 }}
                        placeholder="Please select"
                      >
                        <Select.Option value="Boy">Boy</Select.Option>
                        <Select.Option value="Girl">Girl</Select.Option>
                      </Select>,
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Child's birthdate">
                    {getFieldDecorator('child_birthdate_pregnant', {
                      rules: [
                        {
                          required: this.state.child_pregnant_required,
                          message: "Please enter child's date",
                        },
                      ],
                    })(<DatePicker format={'DD/MM/YYYY'} />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row
                gutter={[16, 0]}
                style={{ display: this.state.pregnant_child }}
              >
                <Col span={12}>
                  <Form.Item label="Labour">
                    {getFieldDecorator('labour_pregnant', {
                      validateTrigger: ['onBlur'],
                      rules: [
                        {
                          required: this.state.child_pregnant_required,
                          message: 'Please enter value',
                        },
                      ],
                    })(<Input placeholder="" />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 0]}>
                <Col span={12}>
                  <Form.Item label="Do you have other children?">
                    {getFieldDecorator('have_other_children', {
                      initialValue: detail.have_other_children,
                      validateTrigger: ['onChange', 'onBlur'],
                      rules: [
                        {
                          required: true,
                          message: 'Please select',
                        },
                      ],
                    })(
                      <Select
                        style={{ width: '100%', marginRight: 8 }}
                        dropdownClassName="have_other_children_clz"
                        onChange={this.handleHaveOtherChildChange}
                        placeholder="Please select"
                      >
                        <Select.Option value="No">No</Select.Option>
                        <Select.Option value="Yes">
                          Yes, I have other children.
                        </Select.Option>
                      </Select>,
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Child's full name"
                    style={{ display: this.state.child_hidden }}
                  >
                    {getFieldDecorator('child_fullname', {
                      validateTrigger: ['onChange', 'onBlur'],
                      rules: [
                        {
                          required: this.state.child_required,
                          message: "Please enter child's full name",
                        },
                      ],
                    })(<Input placeholder="" />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 0]}>
                <Col span={12}>
                  <Form.Item
                    label="Gender"
                    style={{ display: this.state.child_hidden }}
                  >
                    {getFieldDecorator('gender', {
                      validateTrigger: ['onChange', 'onBlur'],
                      rules: [
                        {
                          required: this.state.child_required,
                          message: 'Please select',
                        },
                      ],
                    })(
                      <Select
                        style={{ width: '100%', marginRight: 8 }}
                        onChange={this.handleIsPregnantChange}
                        placeholder="Please select"
                      >
                        <Select.Option value="Boy">Boy</Select.Option>
                        <Select.Option value="Girl">Girl</Select.Option>
                      </Select>,
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Child's birthdate"
                    style={{ display: this.state.child_hidden }}
                  >
                    {getFieldDecorator('child_birthdate', {
                      rules: [
                        {
                          required: this.state.child_required,
                          message: "Please enter child's date",
                        },
                      ],
                    })(<DatePicker format={'DD/MM/YYYY'} />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 0]}>
                <Col span={12} style={{ display: this.state.child_hidden }}>
                  <Form.Item label="Labour">
                    {getFieldDecorator('labour', {
                      validateTrigger: ['onBlur'],
                      rules: [
                        {
                          required: this.state.child_required,
                          message: 'Please enter value',
                        },
                      ],
                    })(<Input placeholder="" />)}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Status">
                    {getFieldDecorator('status', {
                      validateTrigger: ['onChange', 'onBlur'],
                      rules: [
                        {
                          required: true,
                          message: 'Please enter value',
                        },
                      ],
                    })(
                      <Select
                        style={{ width: '100%', marginRight: 8 }}
                        onChange={this.handleIsPregnantChange}
                        placeholder="Please select"
                      >
                        <Select.Option value="1">Active</Select.Option>
                        <Select.Option value="0">InActive</Select.Option>
                      </Select>,
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 0]}>
                <Col span={12}>
                  <Form.Item label="Opt in Channel*">
                    {getFieldDecorator('opt_in')(
                      <Checkbox.Group
                        style={{ width: '100%' }}
                        onChange={this.handleOptInChange}
                      >
                        {optionItems}
                      </Checkbox.Group>,
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}></Col>
              </Row>

              <div
                style={{
                  position: 'absolute',
                  right: 0,
                  bottom: 0,
                  width: '100%',
                  textAlign: 'left',
                  zIndex: 2,
                }}
              >
                <div
                  className="text-right"
                  style={{
                    paddingBottom: 10,
                    paddingRight: 16,
                    paddingLeft: 16,
                    paddingTop: 16,
                    background: 'rgba(255, 255, 255, 0.75)',
                  }}
                >
                  <Text className="">Please complete all required field*</Text>
                </div>
                <Row
                  style={{
                    width: '100%',
                    background: '#fff',
                    padding: '10px 16px',
                    borderTop: '1px solid #e9e9e9',
                  }}
                >
                  <Col span={12}>
                    <Button
                      onClick={this.onClose}
                      style={{ marginRight: 8 }}
                      className="close-btn"
                    >
                      Close
                    </Button>
                  </Col>
                  <Col span={12} className="text-right">
                    <Button
                      disabled={deleteButton}
                      style={{
                        marginRight: 8,
                      }}
                      onClick={this.showDeleteModal}
                    >
                      <i className="fa fa-close" style={{ marginRight: 5 }}></i>
                      Delete
                    </Button>
                    <Button
                      disabled={saveButton ? false : true}
                      type="primary"
                      htmlType="submit"
                      className="primary-action-btn"
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </div>
            </Form>
          </Drawer>
        </div>
        <Modal
          visible={deleteModalVisible}
          width={400}
          closable={false}
          footer={[
            <Button
              key="submit"
              loading={deleteLoading}
              onClick={this.handleDeleteModalOk}
            >
              Yes, Delete
            </Button>,
            <Button
              key="back"
              type="primary"
              onClick={this.handleDeleteModalCancel}
              style={{ minWidth: 120 }}
            >
              No
            </Button>,
          ]}
        >
          <div className="text-center">
            <p>Do you want to delete this item?</p>
          </div>
        </Modal>
      </div>
    );
  }
}

export default Form.create({ name: 'register' })(App);
