import React from 'react';
import {
  Modal,
  Icon,
  Typography,
  Table,
  Button,
  Row,
  Col,
  Input,
  Drawer,
  Form,
  Radio,
  Upload,
  message,
  notification,
} from 'antd';
import 'antd/dist/antd.css';
import './home_banner.css';
import reqwest from 'reqwest';
import _get from 'lodash/get';
import ColorPicker from './components/ColorPicker';

import dragula from 'dragula';
import 'dragula/dist/dragula.css';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const imageDataURI = require('image-data-uri');
// const { Search } = Input;
const { Text, Title } = Typography;

const Size = ReactQuill.Quill.import('attributors/style/size');
Size.whitelist = ['13px', '14px', '16px', '18px', '20px', '32px', '72px'];
ReactQuill.Quill.register(Size, true);

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 10;
  if (!isLt2M) {
    message.error('Image must smaller than 10MB!');
  }
  return isJpgOrPng && isLt2M;
}

class App extends React.Component {
  state = {
    data: [],
    key: false,
    loading: false,
    visible: false,
    isSendingRequest: false,
    imageUrl: false,
    saveButton: false,
    searchValue: '',
    detail: {
      key: undefined,
      title_en: '',
      title_kh: '',
      description_en: '',
      description_kh: '',
      link_en: '',
      link_kh: '',
      color: '',
      image: undefined,
      status: '1',
    },
  };

  constructor(props) {
    super(props);

    this.ref_editors = {
      description: {
        en: React.createRef(),
        kh: React.createRef(),
      },
    };

    this.columns = [
      {
        title: 'Sort',
        dataIndex: 'sort',
        key: 'display_index',
        width: 100,
        render: () => (
          <Icon
            type="menu"
            className="draggable"
            style={{
              fontSize: '13pt',
              fontWeight: 'bolder',
              cursor: 'pointer',
            }}
          />
        ),
      },
      {
        title: '',
        dataIndex: 'image',
        key: 'image',
        width: 300,
        render: (image) => (
          <img src={image} style={{ maxHeight: 150 }} alt="" />
        ),
      },
      {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        render: (title) => (
          <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
            <strong>{title[0]}</strong>
            <div>{title[1]}</div>
          </div>
        ),
      },
      {
        title: 'Published',
        dataIndex: 'status',
        key: 'active',
        width: 110,
        align: 'center',
        render: (status) => (
          <i
            className={`fa ${status === '1' ? 'fa-check' : 'fa-times'}`}
            style={{ fontSize: 15 }}
          />
        ),
      },
      {
        title: '',
        dataIndex: 'key',
        key: 'key',
        width: 70,
        render: (key) => (
          <Button
            onClick={() => {
              this.showDrawer(key);
            }}
            className="icon-btn"
          >
            <Icon type="edit" />
          </Button>
        ),
      },
    ];
  }

  componentDidMount() {
    document.title = 'Home Banner';

    this.fetch();

    const container = document.querySelector('.ant-table-tbody');
    const drake = dragula([container], {
      moves: (el) => {
        this.start = this.getIndexInParent(el);
        return true;
      },
    });

    drake.on('drop', (el) => {
      this.end = this.getIndexInParent(el);
      this.handleReorder(this.start, this.end);
    });
  }

  modules = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        ['link'],

        // [{ list: 'ordered' }, { list: 'bullet' }],
        // [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
        // [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
        // [{ 'direction': 'rtl' }],                         // text direction

        // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ size: Size.whitelist }],

        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        // [{ 'font': Font.whitelist }],
        // [{ align: [] }],

        ['clean'], // remove formatting button
      ],
      handlers: {
        // image: this.imageHandler,
      },
    },
  };

  // imageHandler() {
  //   const input = document.createElement('input');

  //   input.setAttribute('type', 'file');
  //   input.setAttribute('accept', 'image/*');
  //   input.click();

  //   input.onchange = async () => {
  //     const file = input.files[0];
  //     if (!validateImage(file)) {
  //       return false;
  //     }
  //     const formData = new FormData();

  //     formData.append('image', file);

  //     // Save current cursor state
  //     const range = this.quill.getSelection(true);

  //     // Insert temporary loading placeholder image
  //     // this.quill.insertEmbed(range.index, 'image', `${window.location.origin}/images/loaders/placeholder.gif`);

  //     // Move cursor to right side of image (easier to continue typing)
  //     this.quill.setSelection(range.index + 1);

  //     uploadImage(file, (cb) => {
  //       this.quill.insertEmbed(range.index, 'image', cb);
  //     });

  //     // Remove placeholder image
  //     // this.quill.deleteText(range.index, 1);

  //     // Insert uploaded image
  //     // this.quill.insertEmbed(range.index, 'image', res.body.image);
  //     // this.quill.insertEmbed(range.index, 'image', res);
  //   };
  // }

  fetch = (params = {}) => {
    this.props.pageLoading(true);
    this.setState({ loading: true });

    reqwest({
      url: process.env.REACT_APP_API_URL + '/home_banner',
      method: 'get',
      type: 'json',
      data: {
        search: this.state.searchValue,
        ...params,
      },
    })
      .then((data) => {
        this.setState({
          loading: false,
          data: data.results,
        });
        document.body.style.overflow = null;
      })
      .catch((err) => {})
      .always(() => {
        this.props.pageLoading(false);
        this.setState({ loading: false });
      });
  };

  saveRequest = (data = {}) => {
    return reqwest({
      url: process.env.REACT_APP_API_URL + '/home_banner/save',
      method: 'post',
      type: 'json',
      data,
    })
      .then(() => {
        notification.success({
          key: 'notification',
          message: 'Success',
          description: `${data.id ? 'Update' : 'Create'} complete!`,
          duration: 3,
        });

        this.setState({ visible: false });

        this.fetch();
      })
      .catch((err) => {
        let message = err.message;

        if (err.responseText) {
          let jsonResponse = JSON.parse(err.responseText);
          message = jsonResponse.message;
        }

        notification.error({
          key: 'notification',
          message: 'Fail',
          description: message,
          duration: 5,
        });
      })
      .always(() => {
        this.setState({ isSendingRequest: false });
      });
  };

  reorderRequest = (data = {}) => {
    // this.props.pageLoading(true);
    this.setState({ loading: true });

    reqwest({
      url: process.env.REACT_APP_API_URL + '/home_banner/reorder',
      method: 'post',
      type: 'json',
      data,
    })
      .then((data) => {})
      .catch((err) => {})
      .always(() => {
        // this.props.pageLoading(false);
        this.setState({ loading: false });
      });
  };

  deleteRequest = (data = {}) => {
    return reqwest({
      url: process.env.REACT_APP_API_URL + '/home_banner/delete',
      method: 'post',
      type: 'json',
      data,
    })
      .then(() => {
        notification.success({
          key: 'notification',
          message: 'Success',
          description: 'Delete complete!',
          duration: 3,
        });

        this.setState({ visible: false });

        this.fetch();
      })
      .catch((err) => {
        let message = err.message;

        if (err.responseText) {
          let jsonResponse = JSON.parse(err.responseText);
          message = jsonResponse.message;
        }

        notification.error({
          key: 'notification',
          message: 'Fail',
          description: message,
          duration: 5,
        });
      })
      .always(() => {
        this.setState({ isSendingRequest: false });
      });
  };

  getIndexInParent = (el) => {
    return Array.from(el.parentNode.children).indexOf(el);
  };

  showDrawer = async (key) => {
    const data = {
      key: undefined,
      title_en: '',
      title_kh: '',
      description_en: '',
      description_kh: '',
      link_en: '',
      link_kh: '',
      color: '',
      image: undefined,
      status: '1',
    };

    if ((parseInt(key, 10) || 0) > 0) {
      Object.assign(
        data,
        this.state.data.find((item) => item.key === key) || {},
      );

      // Convert to string
      Object.assign(data, {
        title_en: data.title_en || '',
        title_kh: data.title_kh || '',
        description_en: data.description_en || '',
        description_kh: data.description_kh || '',
        link_en: data.link_en || '',
        link_kh: data.link_kh || '',
        color: data.color || '',
        status: `${data.status || 0}`,
      });
    }

    this.props.form.setFieldsValue({
      id: data.key,
      title_en: data.title_en,
      title_kh: data.title_kh,
      description_en: data.description_en,
      description_kh: data.description_kh,
      link_en: data.link_en,
      link_kh: data.link_kh,
      color: data.color,
      status: data.status,
    });

    this.setState({
      detail: data,
      visible: true,
      isSendingRequest: false,
      imageUrl: undefined,
    });

    if (data.image) {
      imageDataURI
        .encodeFromURL(data.image)
        .then((base64) => {
          this.props.form.setFieldsValue({
            image: base64,
          });

          this.setState({
            imageUrl: base64,
          });
        })
        .catch((err) => {
          // console.log({ err });
        });
    }
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  onSave = (e) => {
    e.preventDefault();

    this.setState({ isSendingRequest: true });

    this.props.form.validateFields((err, values) => {
      if (err) {
        notification.error({
          key: 'notification',
          message: 'Fail',
          description: 'Please fill complete form!',
          duration: 3,
        });

        this.setState({ isSendingRequest: false });

        return;
      }

      // get mutation editors data with custom styles
      values.description_en =
        _get(
          this.ref_editors,
          'description.en.current.editor.root.innerHTML',
        ) || values.description_en;
      values.description_kh =
        _get(
          this.ref_editors,
          'description.kh.current.editor.root.innerHTML',
        ) || values.description_kh;

      values.color = _get(values, 'color.hex') || values.color;
      console.log({ values });
      this.saveRequest(values);
    });
  };

  // handleSearchChange(value) {
  //   this.setState({ searchValue: value });
  //   if (searchTimeoutId) {
  //     window.clearTimeout(searchTimeoutId);
  //   }
  //   searchTimeoutId = window.setTimeout(() => {
  //     this.fetch();
  //   }, 1500);
  // }

  handleImageBeforeUpload = (file, form_property_name, state_property_name) => {
    if (beforeUpload(file)) {
      this.setState({
        loading: true,
      });

      try {
        // Get this url from response in real world.
        getBase64(file, (imageUrl) => {
          const formObject = {};
          formObject[form_property_name] = imageUrl;
          this.props.form.setFieldsValue(formObject);

          const stateObject = {
            loading: false,
          };
          stateObject[state_property_name] = imageUrl;
          this.setState(stateObject);
        });
      } catch (err) {
        const formObject = {};
        formObject[form_property_name] = '';
        this.props.form.setFieldsValue(formObject);

        const stateObject = {
          loading: false,
        };
        stateObject[state_property_name] = '';
        this.setState(stateObject);
      }
    }

    // Alway return false for not allow to upload
    return false;
  };

  showDeleteConfirm = () => {
    Modal.confirm({
      title: 'Confirm',
      // icon: <ExclamationCircleOutlined />,
      content: 'Do you want to delete this item?',
      okText: 'Yes, Delete',
      cancelText: 'No',
      okType: 'danger',
      onOk: () => {
        const detail = this.state.detail;

        return this.deleteRequest({ id: detail.key });
      },
      onCancel: () => {
        Modal.destroyAll();
      },
    });
  };

  handleReorder = (dragIndex, draggedIndex) => {
    const data = [...this.state.data];
    const item = data.splice(dragIndex, 1)[0];
    data.splice(draggedIndex, 0, item);
    this.setState({
      data,
    });

    const postBody = [];
    for (let i = 0; i < data.length; i++) {
      postBody.push({
        key: data[i].key,
        display_order: i + 1,
      });
    }

    this.reorderRequest({ v: postBody });
  };

  handleColorChange(color, event) {
    console.log({ color, event });

    // this.setState({ color: color.hex });
    this.props.form.setFieldsValue({
      color: color.hex,
    });
  }

  render() {
    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? 'loading' : 'plus'} />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    const {
      isSendingRequest,
      imageUrl,
      // searchValue,
      detail,
    } = this.state;
    const { getFieldDecorator } = this.props.form;

    console.log({ detail });

    return (
      <div id="home-banner-component">
        <Drawer
          id="home-banner-component-drawer"
          title="HOME BANNER DETAIL"
          placement="right"
          closable={false}
          width={730}
          onClose={this.onClose}
          visible={this.state.visible}
          bodyStyle={{ paddingBottom: 80 }}
        >
          <Form onSubmit={this.onSave} layout={'vertical'}>
            <Form.Item label="" style={{ display: 'none' }}>
              {getFieldDecorator('id', {
                initialValue: '',
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Title (EN)">
              {getFieldDecorator('title_en', {
                initialValue: detail.title_en,
                rules: [
                  { required: false, message: 'Please input your title!' },
                ],
              })(<Input placeholder="" />)}
            </Form.Item>
            <Form.Item label="Title (BM)">
              {getFieldDecorator('title_kh', {
                initialValue: detail.title_kh,
                rules: [
                  { required: false, message: 'Please input your title!' },
                ],
              })(<Input placeholder="" />)}
            </Form.Item>

            <Form.Item label="Description (EN)">
              {getFieldDecorator('description_en', {
                initialValue: detail.description_en,
                rules: [
                  {
                    required: false,
                    message: 'Please input your description!',
                  },
                ],
              })(
                <ReactQuill
                  id="promotion-desc-en"
                  ref={this.ref_editors.description.en}
                  modules={this.modules}
                  height={200}
                />,
              )}
            </Form.Item>

            <Form.Item label="Description (BM)">
              {getFieldDecorator('description_kh', {
                initialValue: detail.description_kh,
                rules: [
                  {
                    required: false,
                    message: 'Please input your description!',
                  },
                ],
              })(
                <ReactQuill
                  id="promotion-desc-kh"
                  ref={this.ref_editors.description.kh}
                  modules={this.modules}
                  height={200}
                />,
              )}
            </Form.Item>

            <Form.Item label="Image">
              <Upload
                accept=".png, .jpg"
                name="Upload"
                listType="picture-card"
                className="photo-uploader"
                showUploadList={false}
                beforeUpload={(file) =>
                  this.handleImageBeforeUpload(file, 'image', 'imageUrl')
                }
              >
                {imageUrl ? (
                  <img src={imageUrl} alt="Upload" style={{ width: '100%' }} />
                ) : (
                  uploadButton
                )}
              </Upload>

              {getFieldDecorator('image', {
                rules: [{ required: true, message: 'Please upload image!' }],
              })(<Input type="hidden" />)}
              <div className="text-center">
                <Text>1900 x 800</Text>
              </div>
            </Form.Item>

            <Form.Item label="Link (EN)">
              {getFieldDecorator('link_en', {
                initialValue: detail.link_en || '',
                rules: [
                  {
                    type: 'url',
                    message: 'This field must be a valid url.',
                  },
                ],
              })(<Input placeholder="https://" />)}
            </Form.Item>

            <Form.Item label="Link (MM)">
              {getFieldDecorator('link_kh', {
                initialValue: detail.link_kh || '',
                rules: [
                  {
                    type: 'url',
                    message: 'This field must be a valid url.',
                  },
                ],
              })(<Input placeholder="https://" />)}
            </Form.Item>

            <Form.Item label="Text Color">
              {getFieldDecorator('color', {
                initialValue: detail.color || '',
              })(
                <ColorPicker
                  key={detail.key}
                  color={detail.color}
                  // onChange={this.handleColorChange.bind(this)}
                />,
              )}
            </Form.Item>

            <Form.Item label="Publish Status">
              {getFieldDecorator('status', {
                validateTrigger: ['onChange', 'onBlur'],
                initialValue: '1',
                rules: [
                  { required: true, message: 'Please input your status!' },
                ],
              })(
                <Radio.Group>
                  <Radio.Button value="1">
                    <i className="fa fa-check" style={{ marginRight: 5 }}></i>
                    Active
                  </Radio.Button>
                  <Radio.Button value="0">
                    <i className="fa fa-close" style={{ marginRight: 5 }}></i>
                    Inactive
                  </Radio.Button>
                </Radio.Group>,
              )}
            </Form.Item>

            <div
              style={{
                position: 'absolute',
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e8e8e8',
                padding: '10px 16px',
                left: 0,
                background: '#fff',
                borderRadius: '0 0 4px 4px',
                zIndex: 2,
              }}
            >
              <Row>
                <Col span={12}>
                  <Button
                    icon="close"
                    onClick={this.onClose}
                    style={{ backgroundColor: '#EDEDED' }}
                  >
                    Close
                  </Button>
                </Col>
                <Col span={12} className="text-right">
                  {detail.key && (
                    <Button
                      style={{
                        marginRight: 8,
                      }}
                      type="danger"
                      ghost
                      icon="delete"
                      onClick={this.showDeleteConfirm}
                      disabled={isSendingRequest}
                    >
                      Delete
                    </Button>
                  )}
                  <Button
                    htmlType="submit"
                    type="primary"
                    icon="save"
                    loading={isSendingRequest}
                    disabled={isSendingRequest}
                  >
                    {!detail.key ? 'Create' : 'Save'}
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        </Drawer>

        <Row>
          <Col span={24}>
            <Title level={1}>Home Banner</Title>
          </Col>
        </Row>
        <div className="paper-panel">
          <Row>
            <Col span={12}>
              {/* <Search
                placeholder="Title"
                onChange={({ target: { value } }) =>
                  this.handleSearchChange(value)
                }
                value={searchValue}
                style={{ width: 350 }}
              /> */}
            </Col>
            <Col span={12} className="text-right">
              <Button
                type="primary"
                onClick={() => this.showDrawer(-1)}
                // style={{ minWidth: 140 }}
              >
                <Icon type="plus" />
                Create
              </Button>
            </Col>
          </Row>
          <Table
            columns={this.columns}
            dataSource={this.state.data}
            loading={this.state.loading}
            rowKey={'key'}
          />
        </div>
      </div>
    );
  }
}

export default Form.create({ name: 'home_banner' })(App);
