import React from 'react';
import {
  Modal,
  Drawer,
  Form,
  Upload,
  message,
  Radio,
  Icon,
  Typography,
  Table,
  Button,
  Row,
  Col,
  Input,
  notification,
} from 'antd';
import 'antd/dist/antd.css';
import './promotion_banner.css';
import reqwest from 'reqwest';

import dragula from 'dragula';
import 'dragula/dist/dragula.css';

// const path = require('path');
// const imageToBase64 = require('image-to-base64');
const imageDataURI = require('image-data-uri');
const { /*Search,*/ TextArea } = Input;
const { Text, Title } = Typography;

let searchTimeoutId = false;

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}

class App extends React.Component {
  state = {
    data: [],
    key: false,
    loading: false,
    visible: false,
    isSendingRequest: false,
    imageUrl: false,
    searchValue: '',
    detail: { title: ['', ''] },
  };

  constructor(props) {
    super(props);

    this.columns = [
      {
        title: 'Sort',
        dataIndex: 'sort',
        key: 'display_index',
        width: 100,
        render: () => (
          <Icon
            type="menu"
            style={{
              fontSize: '13pt',
              fontWeight: 'bolder',
              cursor: 'pointer',
            }}
          />
        ),
      },
      {
        title: '',
        dataIndex: 'image',
        key: 'image',
        width: 300,
        render: (image) => (
          <img width="250" src={image} style={{ maxHeight: 150 }} alt="" />
        ),
      },
      {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        render: (title) => (
          <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
            <strong>{title[0]}</strong>
            <div>{title[1]}</div>
          </div>
        ),
      },
      {
        title: 'Published',
        dataIndex: 'status',
        key: 'status',
        width: 110,
        align: 'center',
        render: (status) => (
          <i
            className={`fa ${status === '1' ? 'fa-check' : 'fa-times'}`}
            style={{ fontSize: 15 }}
          />
        ),
      },
      {
        title: '',
        dataIndex: 'key',
        key: 'key',
        width: 70,
        render: (key) => (
          <Button
            onClick={() => {
              this.showDrawer(key);
            }}
            className="icon-btn"
          >
            <Icon type="edit" />
          </Button>
        ),
      },
    ];
  }

  componentDidMount() {
    document.title = 'Promotion Banner';

    this.fetch();

    const container = document.querySelector('.ant-table-tbody');
    const drake = dragula([container], {
      moves: (el) => {
        this.start = this.getIndexInParent(el);
        return true;
      },
    });

    drake.on('drop', (el) => {
      this.end = this.getIndexInParent(el);
      this.handleReorder(this.start, this.end);
    });
  }

  fetch = (params = {}) => {
    this.props.pageLoading(true);
    this.setState({ loading: true });

    reqwest({
      url: process.env.REACT_APP_API_URL + '/promotion_banner',
      method: 'get',
      data: {
        search: this.state.searchValue,
        ...params,
      },
      type: 'json',
    })
      .then((data) => {
        this.setState({
          data: data.results,
        });

        document.body.style.overflow = null;
      })
      .catch((err) => {})
      .always(() => {
        this.props.pageLoading(false);
        this.setState({ loading: false });
      });
  };

  saveRequest = (data = {}) => {
    return reqwest({
      url: process.env.REACT_APP_API_URL + '/promotion_banner/save',
      method: 'post',
      type: 'json',
      data,
    })
      .then(() => {
        notification.success({
          key: 'notification',
          message: 'Success',
          description: `${data.id ? 'Update' : 'Create'} complete!`,
          duration: 3,
        });

        this.setState({ visible: false });

        this.fetch();
      })
      .catch((err) => {
        let message = err.message;

        if (err.responseText) {
          let jsonResponse = JSON.parse(err.responseText);
          message = jsonResponse.message;
        }

        notification.error({
          key: 'notification',
          message: 'Fail',
          description: message,
          duration: 5,
        });
      })
      .always(() => {
        this.setState({ isSendingRequest: false });
      });
  };

  reorderRequest = (data = {}) => {
    // this.props.pageLoading(true);
    this.setState({ loading: true });

    reqwest({
      url: process.env.REACT_APP_API_URL + '/promotion_banner/reorder',
      method: 'post',
      type: 'json',
      data,
    })
      .then((data) => {})
      .catch((err) => {})
      .always(() => {
        // this.props.pageLoading(false);
        this.setState({ loading: false });
      });
  };

  deleteRequest = () => {
    const detail = this.state.detail;

    return reqwest({
      url: process.env.REACT_APP_API_URL + '/promotion_banner/delete',
      method: 'post',
      type: 'json',
      data: {
        id: detail.key,
      },
    })
      .then(() => {
        notification.success({
          key: 'notification',
          message: 'Success',
          description: 'Delete complete!',
          duration: 3,
        });

        this.setState({ visible: false });

        this.fetch();
      })
      .catch((err) => {
        let message = err.message;

        if (err.responseText) {
          let jsonResponse = JSON.parse(err.responseText);
          message = jsonResponse.message;
        }

        notification.error({
          key: 'notification',
          message: 'Fail',
          description: message,
          duration: 5,
        });
      })
      .always(() => {
        this.setState({ isSendingRequest: false });
      });
  };

  getIndexInParent = (el) => {
    return Array.from(el.parentNode.children).indexOf(el);
  };

  showDrawer = async (key) => {
    const data = {
      key: undefined,
      title_en: '',
      title_kh: '',
      description_en: '',
      description_kh: '',
      status: '1',
      image: undefined,
    };

    if ((parseInt(key, 10) || 0) > 0) {
      Object.assign(
        data,
        this.state.data.find((item) => item.key === key) || {},
      );

      // Convert to string
      Object.assign(data, {
        title_en: data.title_en || '',
        title_kh: data.title_kh || '',
        description_en: data.description_en || '',
        description_kh: data.description_kh || '',
        status: `${data.status || 0}`,
      });
    }

    this.props.form.setFieldsValue({
      id: data.key,
      title_en: data.title_en,
      title_kh: data.title_kh,
      description_en: data.description_en,
      description_kh: data.description_kh,
      status: data.status,
      image: undefined,
    });

    this.setState({
      detail: data,
      visible: true,
      isSendingRequest: false,
      imageUrl: undefined,
    });

    if (data.image) {
      // Returns a Promise
      imageDataURI
        .encodeFromURL(data.image)
        .then((base64) => {
          this.props.form.setFieldsValue({
            image: base64,
          });

          this.setState({
            imageUrl: base64,
          });
        })
        .catch((err) => {
          // console.log({ err });
        });
    }
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  onSave = (e) => {
    e.preventDefault();

    this.setState({ isSendingRequest: true });

    this.props.form.validateFields((err, values) => {
      if (err) {
        notification.error({
          key: 'notification',
          message: 'Fail',
          description: 'Please fill complete form!',
          duration: 3,
        });

        this.setState({ isSendingRequest: false });

        return;
      }

      this.saveRequest(values);
    });
  };

  handleSearchChange(value) {
    this.setState({ searchValue: value });
    if (searchTimeoutId) {
      window.clearTimeout(searchTimeoutId);
    }
    searchTimeoutId = window.setTimeout(() => {
      this.fetch();
    }, 1500);
  }

  handleImageBeforeUpload = (file, form_property_name, state_property_name) => {
    if (beforeUpload(file)) {
      this.setState({
        loading: true,
      });

      try {
        // Get this url from response in real world.
        getBase64(file, (imageUrl) => {
          const formObject = {};
          formObject[form_property_name] = imageUrl;
          this.props.form.setFieldsValue(formObject);

          const stateObject = {
            loading: false,
          };
          stateObject[state_property_name] = imageUrl;
          this.setState(stateObject);
        });
      } catch (err) {
        const formObject = {};
        formObject[form_property_name] = '';
        this.props.form.setFieldsValue(formObject);

        const stateObject = {
          loading: false,
        };
        stateObject[state_property_name] = '';
        this.setState(stateObject);
      }
    }

    // Alway return false for not allow to upload
    return false;
  };

  showDeleteConfirm = () => {
    Modal.confirm({
      title: 'Confirm',
      // icon: <ExclamationCircleOutlined />,
      content: 'Do you want to delete this item?',
      okText: 'Yes, Delete',
      cancelText: 'No',
      okType: 'danger',
      onOk: (e) => {
        const detail = this.state.detail;

        return this.deleteRequest({ id: detail.key });
      },
      onCancel: (e) => {
        Modal.destroyAll();
      },
    });
  };

  handleReorder = (dragIndex, draggedIndex) => {
    const data = [...this.state.data];
    const item = data.splice(dragIndex, 1)[0];
    data.splice(draggedIndex, 0, item);
    this.setState({
      data,
    });

    const postBody = [];
    for (let i = 0; i < data.length; i++) {
      postBody.push({
        key: data[i].key,
        display_order: i + 1,
      });
    }

    this.reorderRequest({ v: postBody });
  };

  render() {
    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? 'loading' : 'plus'} />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    const { isSendingRequest, imageUrl, detail } = this.state;
    const { getFieldDecorator } = this.props.form;

    return (
      <div id="promotion-banner-component">
        <Drawer
          id="promotion-banner-component-drawer"
          title="PROMOTION BANNER DETAIL"
          placement="right"
          closable={false}
          width={730}
          onClose={this.onClose}
          visible={this.state.visible}
          bodyStyle={{ paddingBottom: 80 }}
        >
          <Form onSubmit={this.onSave} layout={'vertical'}>
            <Form.Item label="" style={{ display: 'none' }}>
              {getFieldDecorator('id', {
                initialValue: '',
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Title (EN)">
              {getFieldDecorator('title_en', {
                initialValue: detail.title_en,
              })(<Input placeholder="" />)}
            </Form.Item>
            <Form.Item label="Title (BM)">
              {getFieldDecorator('title_kh', {
                initialValue: detail.title_kh,
              })(<Input placeholder="" />)}
            </Form.Item>
            <Form.Item label="Description (EN)">
              {getFieldDecorator('description_en', {
                initialValue: detail.description_en,
              })(<TextArea rows={4} placeholder="" />)}
            </Form.Item>
            <Form.Item label="Description (BM)">
              {getFieldDecorator('description_kh', {
                initialValue: detail.description_kh,
              })(<TextArea rows={4} placeholder="" />)}
            </Form.Item>
            <Form.Item label="Image">
              <Upload
                accept=".png, .jpg"
                name="Upload"
                listType="picture-card"
                className="photo-uploader"
                showUploadList={false}
                beforeUpload={(file) =>
                  this.handleImageBeforeUpload(file, 'image', 'imageUrl')
                }
              >
                {imageUrl ? (
                  <img src={imageUrl} alt="Upload" style={{ width: '100%' }} />
                ) : (
                  uploadButton
                )}
              </Upload>
              {getFieldDecorator('image', {
                rules: [
                  {
                    required: true,
                    message: 'Please upload image!',
                  },
                ],
              })(<Input type="hidden" />)}
              <div className="text-center">
                <Text>1900 x 800</Text>
              </div>
            </Form.Item>
            <Form.Item label="Publish Status">
              {getFieldDecorator('status', {
                validateTrigger: ['onChange', 'onBlur'],
                initialValue: '1',
                rules: [
                  { required: true, message: 'Please input your status!' },
                ],
              })(
                <Radio.Group>
                  <Radio.Button value="1">
                    <i className="fa fa-check" style={{ marginRight: 5 }}></i>
                    Active
                  </Radio.Button>
                  <Radio.Button value="0">
                    <i className="fa fa-close" style={{ marginRight: 5 }}></i>
                    Inactive
                  </Radio.Button>
                </Radio.Group>,
              )}
            </Form.Item>

            <div
              style={{
                position: 'absolute',
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e8e8e8',
                padding: '10px 16px',
                left: 0,
                background: '#fff',
                borderRadius: '0 0 4px 4px',
                zIndex: 2,
              }}
            >
              <Row>
                <Col span={12}>
                  <Button
                    icon="close"
                    onClick={this.onClose}
                    style={{ backgroundColor: '#EDEDED' }}
                  >
                    Close
                  </Button>
                </Col>
                <Col span={12} className="text-right">
                  {detail.key && (
                    <Button
                      style={{
                        marginRight: 8,
                      }}
                      type="danger"
                      ghost
                      icon="delete"
                      onClick={this.showDeleteConfirm}
                      disabled={isSendingRequest}
                    >
                      Delete
                    </Button>
                  )}
                  <Button
                    htmlType="submit"
                    type="primary"
                    icon="save"
                    loading={isSendingRequest}
                    disabled={isSendingRequest}
                  >
                    {!detail.key ? 'Create' : 'Save'}
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        </Drawer>

        <Row>
          <Col span={24}>
            <Title level={1}>Promotion Banner</Title>
          </Col>
        </Row>
        <div className="paper-panel">
          <Row>
            <Col span={12}>
              {/* <Search
                placeholder="Message / Customer"
                onChange={({ target: { value } }) =>
                  this.handleSearchChange(value)
                }
                style={{ width: 350 }}
              /> */}
            </Col>
            <Col span={12} className="text-right">
              <Button
                type="primary"
                // style={{ minWidth: 140 }}
                onClick={() => this.showDrawer(-1)}
              >
                <Icon type="plus" />
                Create
              </Button>
            </Col>
          </Row>
          <Table
            columns={this.columns}
            dataSource={this.state.data}
            pagination={this.state.pagination}
            loading={this.state.loading}
            onChange={this.handleTableChange}
            rowKey={'key'}
          />
        </div>
      </div>
    );
  }
}

export default Form.create({ name: 'promotion_banner_form' })(App);
