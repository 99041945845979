export function cleanupURL(url) {
  if (url) {
    return encodeURIComponent(
      url.toLowerCase().replace(/[^a-zA-Z\s\d]+/g, ' '),
    ).replace(/%20/g, '-');
  }

  return '';
}

export const getTopicName = (name) => {
  if (cleanupURL(name).includes('week')) {
    return 'week';
  } else if (cleanupURL(name).includes('month')) {
    return 'month';
  } else if (cleanupURL(name).includes('year')) {
    return 'year';
  }

  return cleanupURL(name);
};

export const isTimelyTopic = (name) => {
  if (cleanupURL(name).includes('week')) {
    return true;
  } else if (cleanupURL(name).includes('month')) {
    return true;
  } else if (cleanupURL(name).includes('year')) {
    return true;
  }

  return false;
};

export const getArticleURL = (data) => {
  if (data.title_en !== '') {
    if (isTimelyTopic(data.topic_name_en)) {
      return `${
        '/' +
        cleanupURL(data.category_name_en) +
        '/' +
        getTopicName(
          data.topic_name_en_new === null
            ? data.topic_name_en
            : data.topic_name_en_new,
        ) +
        '/' +
        (data.url_name !== '' && data.url_name !== null
          ? cleanupURL(data.url_name)
          : cleanupURL(data.title_en))
      }`;
    } else {
      // return `${'/'+cleanupURL(data.category_name_en)+'/'+getTopicName(data.topic_name_en)+'/article_id/?article_id='+cleanupURL(data.title_en)}`
      return `${
        '/' +
        cleanupURL(data.category_name_en) +
        '/' +
        getTopicName(
          !data.topic_name_en_new || data.topic_name_en_new === null
            ? data.topic_name_en
            : data.topic_name_en_new,
        ) +
        '/' +
        (data.url_name && data.url_name !== '' && data.url_name !== null
          ? cleanupURL(data.url_name)
          : cleanupURL(data.title_en))
      }`;
    }
  }

  return '';
};

export const getProductURL = (data) => {
  if (data.name !== '') {
    return `${
      '/product/' +
      data.type_name +
      '/' +
      (data.url_name !== '' && data.url_name !== null
        ? cleanupURL(data.url_name)
        : data.id)
    }`;
  }

  return '';
};

export const isEmpty = (obj) => {
  for (let prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      return false;
    }
  }

  return JSON.stringify(obj) === JSON.stringify({});
};

export const isNotEmptyVal = (val) => {
  let is_not_empty = false;
  if (val !== undefined && val !== null) {
    is_not_empty = true;
    if (Array.isArray(val)) {
      is_not_empty = val.length > 0;
    }
  }

  return is_not_empty;
};

export const deepFindObj = (obj, path) => {
  let paths = path.split('.'),
    current = obj;

  for (let i = 0; i < paths.length; ++i) {
    if (current[paths[i]] === undefined) {
      return undefined;
    } else {
      current = current[paths[i]];
    }
  }

  return current;
};

export const validateImage = (file) => {
  let message = '';

  // Check mime-type
  const isJpgOrPng = ['image/jpeg', 'image/png'].includes(file.type);
  if (!isJpgOrPng) {
    message = 'You can only upload JPG/PNG file!';
    return { validate: false, message };
  }

  // Check filesize
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message = 'Image must smaller than 2MB!';
    return { validate: false, message };
  }

  return { validate: isJpgOrPng && isLt2M, message };
};
