import React from 'react';
import {
  Modal,
  Drawer,
  Form,
  Radio,
  Icon,
  Typography,
  Select,
  Table,
  Button,
  Row,
  Col,
  Input,
  notification,
} from 'antd';
import 'antd/dist/antd.css';
import reqwest from 'reqwest';
import cookie from 'js-cookie';

import './system_user.css';

const { Search } = Input;
const { Title } = Typography;

let searchTimeoutId = false;

class App extends React.Component {
  state = {
    data: [],
    detail: {
      username: '',
      password: '',
      confirm: '',
      email: '',
      role: '',
      status: '1',
    },
    key: false,
    loading: false,
    visible: false,
    imageUrl: false,
    isSendingRequest: false,
    pagination: { pageSize: 10 },
    searchValue: '',
    roles: [],
    message: '',
  };

  constructor(props) {
    super(props);

    this.columns = [
      {
        title: 'Username',
        dataIndex: 'username',
        key: 'username',
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'Create Date',
        dataIndex: 'created_time',
        key: 'created_time',
        width: 200,
      },
      {
        title: 'Active',
        dataIndex: 'status',
        key: 'active',
        width: 100,
        align: 'center',
        render: (status) =>
          status === 1 ? (
            <i className="fa fa-check" style={{ fontSize: 15 }}></i>
          ) : (
            <i className="fa fa-times" style={{ fontSize: 15 }}></i>
          ),
      },
      {
        title: '',
        dataIndex: 'key',
        key: 'key',
        width: 70,
        render: (key) => (
          <Button
            onClick={() => {
              this.showDrawer(key);
            }}
            className="icon-btn"
          >
            <Icon type="edit" />
          </Button>
        ),
      },
    ];
  }

  componentDidMount() {
    document.title = 'System User';

    this.fetch();
    this.fetchUserRole();
  }

  fetch = (params = {}) => {
    this.props.pageLoading(true);

    reqwest({
      url: process.env.REACT_APP_API_URL + '/system_user',
      method: 'get',
      type: 'json',
      data: {
        search: this.state.searchValue,
        token: cookie.get('token'),
        ...params,
      },
    })
      .then((data) => {
        this.setState({
          data: data.results,
        });

        document.body.style.overflow = null;
      })
      .catch((err) => {})
      .always(() => {
        this.props.pageLoading(false);
      });
  };

  fetchUserRole = () => {
    // this.props.pageLoading(true);

    reqwest({
      url: process.env.REACT_APP_API_URL + '/system_user/init',
      method: 'get',
      type: 'json',
      data: {},
    })
      .then((data) => {
        this.setState({
          roles:
            (data && (data.results ? data.results.roles : undefined)) || [],
        });
      })
      .catch((err) => {})
      .always(() => {
        // this.props.pageLoading(false);
      });
  };

  saveRequest = (data = {}) => {
    return reqwest({
      url: process.env.REACT_APP_API_URL + '/system_user/save',
      method: 'post',
      type: 'json',
      data,
    })
      .then(() => {
        notification.success({
          key: 'notification',
          message: 'Success',
          description: `${data.id ? 'Update' : 'Create'} complete!`,
          duration: 3,
        });

        this.setState({ visible: false });

        this.fetch();
      })
      .catch((err) => {
        let message = err.message;

        if (err.responseText) {
          let jsonResponse = JSON.parse(err.responseText);
          message = jsonResponse.message;
        }

        notification.error({
          key: 'notification',
          message: 'Fail',
          description: message,
          duration: 5,
        });
      })
      .always(() => {
        this.setState({ isSendingRequest: false });
      });
  };

  deleteRequest = (data = {}) => {
    return reqwest({
      url: process.env.REACT_APP_API_URL + '/system_user/delete',
      method: 'post',
      type: 'json',
      data,
    })
      .then(() => {
        notification.success({
          key: 'notification',
          message: 'Success',
          description: 'Delete complete!',
          duration: 3,
        });

        this.setState({ visible: false });

        this.fetch();
      })
      .catch((err) => {
        let message = err.message;

        if (err.responseText) {
          let jsonResponse = JSON.parse(err.responseText);
          message = jsonResponse.message;
        }

        notification.error({
          key: 'notification',
          message: 'Fail',
          description: message,
          duration: 5,
        });
      })
      .always(() => {
        this.setState({ isSendingRequest: false });
      });
  };

  showDrawer = async (key) => {
    const data = {
      key: undefined,
      username: '',
      password: '',
      confirm: '',
      role: '',
      email: '',
      status: '1',
    };

    if (key) {
      Object.assign(
        data,
        this.state.data.find((item) => item.key === key) || {},
      );

      // Convert to string
      Object.assign(data, {
        password: '',
        confirm: '',
        status: `${data.status || 0}`,
      });
    }

    this.setState({
      imageUrl: null,
      visible: true,
      detail: data,
    });

    this.props.form.setFieldsValue({
      id: data.key,
      username: data.username,
      password: data.password,
      email: data.email,
      confirm: data.confirm,
      role: data.role,
      status: '' + data.status,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  onSave = (e) => {
    e.preventDefault();

    this.setState({ isSendingRequest: true });

    this.props.form.validateFields((err, values) => {
      if (err) {
        notification.error({
          key: 'notification',
          message: 'Fail',
          description: 'Please fill complete form!',
          duration: 3,
        });

        this.setState({ isSendingRequest: false });

        return;
      }

      this.saveRequest(values);
    });
  };

  handleSearchChange(value) {
    this.setState({ searchValue: value });
    if (searchTimeoutId) {
      window.clearTimeout(searchTimeoutId);
    }
    searchTimeoutId = window.setTimeout(() => {
      this.fetch();
    }, 1500);
  }

  showDeleteConfirm = () => {
    Modal.confirm({
      title: 'Confirm',
      // icon: <ExclamationCircleOutlined />,
      content: 'Do you want to delete this item?',
      okText: 'Yes, Delete',
      cancelText: 'No',
      okType: 'danger',
      onOk: (e) => {
        const detail = this.state.detail;

        return this.deleteRequest({ id: detail.key });
      },
      onCancel: (e) => {
        Modal.destroyAll();
      },
    });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('password')) {
      callback("The passwords don't match");
      // this.setState({submitDisabled: true});
    } else {
      // this.setState({submitDisabled: false});
      callback();
    }
  };

  compareToConfirmPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  };

  render() {
    const { isSendingRequest, roles, detail } = this.state;
    const { getFieldDecorator } = this.props.form;

    return (
      <div id="system-user-component">
        <Drawer
          id="system-user-component-drawer"
          title="SYSTEM USER"
          placement="right"
          closable={false}
          width={460}
          onClose={this.onClose}
          visible={this.state.visible}
          bodyStyle={{ paddingBottom: 80 }}
        >
          <Form onSubmit={this.onSave} layout={'vertical'}>
            <Form.Item label="" style={{ display: 'none' }}>
              {getFieldDecorator('id', {
                initialValue: '',
              })(<Input />)}
            </Form.Item>
            <Row gutter={[16, 0]}>
              <Col span={12}>
                <Form.Item label="Username">
                  {getFieldDecorator('username', {
                    initialValue: detail.username,
                    rules: [
                      {
                        required: detail.key ? false : true,
                        message: 'Please input your username!',
                      },
                    ],
                    normalize: (input) => input.toLowerCase(),
                  })(
                    <Input
                      placeholder=""
                      readOnly={detail.key ? true : false}
                    />,
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Email">
                  {getFieldDecorator('email', {
                    initialValue: detail.email,
                    rules: [
                      {
                        type: 'email',
                        message: 'The input is not valid E-mail!',
                      },
                      { required: true, message: 'Please input your email!' },
                    ],
                    normalize: (input) => input.toLowerCase(),
                  })(<Input placeholder="" autoComplete="new-password" />)}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 0]}>
              <Col span={12}>
                <Form.Item label="Password">
                  {getFieldDecorator('password', {
                    initialValue: detail.password,
                    rules: [
                      {
                        required: detail.key === -1,
                        message: 'Please enter your password!',
                      },
                      {
                        validator: this.validateToConfirmPassword,
                      },
                    ],
                  })(
                    <Input.Password
                      placeholder=""
                      autoComplete="new-password"
                    />,
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Re-Password">
                  {getFieldDecorator('confirm', {
                    initialValue: detail.confirm,
                    rules: [
                      {
                        required: detail.key === -1,
                        message: 'Please re-type your password!',
                      },
                      {
                        validator: this.compareToFirstPassword,
                      },
                    ],
                  })(
                    <Input.Password
                      placeholder=""
                      autoComplete="new-password"
                    />,
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Form.Item label="Role">
              {getFieldDecorator('role', {
                initialValue: '',
                rules: [{ required: true, message: 'Please choose role!' }],
              })(
                <Select>
                  <Select.Option key="" value="">
                    Please select
                  </Select.Option>
                  {roles.map((value) => (
                    <Select.Option key={value.id} value={value.id}>
                      {value.name}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </Form.Item>

            <Form.Item label="Publish Status">
              {getFieldDecorator('status', {
                initialValue: '' + detail.status,
                rules: [{ required: true, message: 'Please choose status!' }],
              })(
                <Radio.Group>
                  <Radio.Button value="1">
                    <i className="fa fa-check" style={{ marginRight: 5 }}></i>
                    Active
                  </Radio.Button>
                  <Radio.Button value="0">
                    <i className="fa fa-close" style={{ marginRight: 5 }}></i>
                    Inactive
                  </Radio.Button>
                </Radio.Group>,
              )}
            </Form.Item>

            <div
              style={{
                position: 'absolute',
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e8e8e8',
                padding: '10px 16px',
                left: 0,
                background: '#fff',
                borderRadius: '0 0 4px 4px',
                zIndex: 2,
              }}
            >
              <Row>
                <Col span={12}>
                  <Button
                    icon="close"
                    onClick={this.onClose}
                    style={{ backgroundColor: '#EDEDED' }}
                  >
                    Close
                  </Button>
                </Col>
                <Col span={12} className="text-right">
                  {detail.key && (
                    <Button
                      style={{
                        marginRight: 8,
                      }}
                      type="danger"
                      ghost
                      icon="delete"
                      onClick={this.showDeleteConfirm}
                      disabled={isSendingRequest}
                    >
                      Delete
                    </Button>
                  )}
                  <Button
                    htmlType="submit"
                    type="primary"
                    icon="save"
                    loading={isSendingRequest}
                    disabled={isSendingRequest}
                  >
                    {!detail.key ? 'Create' : 'Save'}
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        </Drawer>

        <Row>
          <Col span={24}>
            <Title level={1}>System User</Title>
          </Col>
        </Row>
        <div className="paper-panel">
          <Row>
            <Col span={12}>
              <Search
                placeholder="Username"
                onChange={({ target: { value } }) =>
                  this.handleSearchChange(value)
                }
                style={{ width: 350 }}
              />
            </Col>
            <Col span={12} className="text-right">
              <Button
                type="primary"
                icon="plus"
                // style={{ minWidth: 140 }}
                onClick={() => this.showDrawer(-1)}
              >
                Create
              </Button>
            </Col>
          </Row>
          <Table
            columns={this.columns}
            dataSource={this.state.data}
            pagination={this.state.pagination}
            loading={this.state.loading}
            onChange={this.handleTableChange}
            rowKey={'key'}
          />
        </div>
      </div>
    );
  }
}

export default Form.create({ name: 'system_user_form' })(App);
