import React from 'react';
import {
  Drawer,
  Upload,
  message,
  Form,
  Icon,
  Typography,
  Table,
  Button,
  Row,
  Col,
  Input,
  notification,
} from 'antd';
import 'antd/dist/antd.css';
import './meta.css';
import reqwest from 'reqwest';
import Dotdotdot from 'react-dotdotdot';
const imageDataURI = require('image-data-uri');

const { Search } = Input;
const { Text, Title } = Typography;

let searchTimeoutId = false;

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeCoverUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}

class App extends React.Component {
  state = {
    data: [],
    pagination: { pageSize: 10 },
    loading: false,
    visible: false,
    saveButton: false,
    imageUrl: false,
    searchValue: '',
    detail: {
      page_key: '',
      image: '',
      title: '',
      title_seo: '',
      desc: '',
      desc_seo: '',
      updated_time: '',
    },
  };

  constructor(props) {
    super(props);

    this.columns = [
      {
        title: 'Page Key',
        dataIndex: 'page_key',
        width: 150,
        render: (data) => (
          <div>
            <Dotdotdot clamp={1}>
              <Text style={{ wordWrap: 'break-word' }}>{data}</Text>
            </Dotdotdot>
          </div>
        ),
      },
      {
        title: 'Image',
        dataIndex: 'image',
        width: 200,
        render: (image) => (
          <img
            src={image}
            style={{ width: 'auto', maxHeight: '50px' }}
            alt=""
          />
        ),
      },
      {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        width: 250,
        render: (data) => (
          <div>
            <Text>{data}</Text>
          </div>
        ),
      },
      {
        title: 'Description',
        dataIndex: 'desc',
        key: 'desc',
        render: (data, row) => (
          <div>
            <Text>{data}</Text>
          </div>
        ),
      },
      {
        title: '',
        dataIndex: 'page_key',
        key: 'edit_page_key',
        width: 70,
        render: (key) => (
          <Button
            onClick={() => {
              this.showDrawer(key);
            }}
            className="icon-btn"
          >
            <Icon type="edit" />
          </Button>
        ),
      },
    ];
  }

  fetch = (params = {}) => {
    this.props.pageLoading(true);

    reqwest({
      url: process.env.REACT_APP_API_URL + '/meta',
      method: 'get',
      data: {
        size: this.state.pagination.pageSize,
        search: this.state.searchValue,
        ...params,
      },
      type: 'json',
    })
      .then((data) => {
        this.setState({
          data: data.results,
        });

        document.body.style.overflow = null;
      })
      .catch((err) => {})
      .then(() => {
        this.props.pageLoading(false);
      });
  };

  saveRequest = (data = {}) => {
    return reqwest({
      url: process.env.REACT_APP_API_URL + '/meta/save',
      method: 'post',
      type: 'json',
      data,
    })
      .then(() => {
        notification.success({
          key: 'notification',
          message: 'Success',
          description: `${data.page_key ? 'Update' : 'Create'} complete!`,
          duration: 3,
        });

        this.setState({ visible: false });

        this.fetch();
      })
      .catch((err) => {
        let message = err.message;

        if (err.responseText) {
          let jsonResponse = JSON.parse(err.responseText);
          message = jsonResponse.message;
        }

        notification.error({
          key: 'notification',
          message: 'Fail',
          description: message,
          duration: 5,
        });
      })
      .always(() => {
        this.setState({ isSendingRequest: false });
      });
  };

  showDrawer = async (key) => {
    this.props.pageLoading(true);

    const data = this.state.data.find((item) => item.page_key === key);

    // Convert to string
    Object.assign(data, {
      page_key: data.page_key || '',
      title: data.title || '',
      title_seo: data.title_seo || '',
      desc: data.desc || '',
      desc_seo: data.desc_seo || '',
    });

    this.props.form.setFieldsValue({
      page_key: data.page_key,
      title: data.title,
      title_seo: data.title_seo,
      desc: data.desc,
      desc_seo: data.desc_seo,
      image: undefined,
    });

    this.setState({
      detail: data,
      visible: true,
      isSendingRequest: false,
      imageUrl: undefined,
    });

    if (data.image) {
      imageDataURI
        .encodeFromURL(data.image)
        .then((base64) => {
          this.props.form.setFieldsValue({
            image: base64,
          });

          this.setState({
            imageUrl: base64,
          });
        })
        .catch((err) => {
          // console.log({ err });
        });
    }

    this.props.pageLoading(false);
  };

  onSave = (e) => {
    e.preventDefault();

    this.setState({ isSendingRequest: true });

    this.props.form.validateFields((err, values) => {
      if (err) {
        notification.error({
          key: 'notification',
          message: 'Fail',
          description: 'Please fill complete form!',
          duration: 3,
        });

        this.setState({ isSendingRequest: false });

        return;
      }

      this.saveRequest(values);
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  componentDidMount() {
    document.title = 'SEO Meta Data';

    this.fetch();
  }

  handleCoverChange = (info) => {
    if (info.file.status === 'uploading') {
      this.props.pageLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl) => {
        this.props.form.setFieldsValue({
          img: imageUrl,
        });
        this.setState({
          imageUrl,
        });

        this.props.pageLoading(false);
      });
    }
  };

  getIndexInParent = (el) => {
    return Array.from(el.parentNode.children).indexOf(el);
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });
    this.fetch({
      size: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters,
    });
  };

  handleSearchChange(value) {
    this.setState({ searchValue: value });
    if (searchTimeoutId) {
      window.clearTimeout(searchTimeoutId);
    }
    searchTimeoutId = window.setTimeout(() => {
      this.fetch();
    }, 1500);
  }

  render() {
    const { isSendingRequest, searchValue, detail, imageUrl } = this.state;
    const { getFieldDecorator } = this.props.form;

    const uploadCoverButton = (
      <div>
        <Icon type={this.state.loading ? 'loading' : 'plus'} />
        <div className="ant-upload-text">Upload</div>
      </div>
    );

    return (
      <div id="meta-component">
        <Drawer
          id="meta-component-drawer"
          title="Meta Data"
          placement="right"
          closable={false}
          width={730}
          onClose={this.onClose}
          visible={this.state.visible}
          bodyStyle={{ paddingBottom: 120 }}
        >
          <Form onSubmit={this.onSave} layout={'vertical'}>
            <Form.Item label="Page Key">
              {getFieldDecorator('page_key', {
                initialValue: detail.page_key,
                rules: [{ required: true, message: 'Please input Page Key!' }],
              })(<Input placeholder="" readOnly={true} />)}
            </Form.Item>
            <Form.Item label="Title">
              {getFieldDecorator('title', {
                initialValue: detail.title,
                rules: [{ required: true, message: 'Please input Title!' }],
              })(<Input placeholder="" />)}
            </Form.Item>
            <Form.Item label="Title SEO">
              {getFieldDecorator('title_seo', {
                initialValue: detail.title_seo,
                rules: [{ required: true, message: 'Please input Title!' }],
              })(<Input placeholder="" />)}
            </Form.Item>
            <Form.Item label="Description">
              {getFieldDecorator('desc', {
                initialValue: detail.desc,
                rules: [
                  { required: true, message: 'Please input description!' },
                ],
              })(<Input placeholder="" />)}
            </Form.Item>
            <Form.Item label="Description SEO">
              {getFieldDecorator('desc_seo', {
                initialValue: detail.desc_seo,
                rules: [
                  { required: true, message: 'Please input description!' },
                ],
              })(<Input placeholder="" />)}
            </Form.Item>
            <Form.Item label="Image">
              <Upload
                accept=".png, .jpg"
                name="image"
                listType="picture-card"
                className="photo-uploader"
                showUploadList={false}
                action={process.env.REACT_APP_API_URL + '/image/test/upload'}
                beforeUpload={beforeCoverUpload}
                onChange={this.handleCoverChange}
              >
                {imageUrl ? (
                  <img src={imageUrl} alt="Cover" style={{ width: '100%' }} />
                ) : (
                  uploadCoverButton
                )}
              </Upload>
              {getFieldDecorator('img')(<Input type="hidden" />)}
              <div className="text-center">
                <Text>1200 x 900</Text>
              </div>
            </Form.Item>
            <div
              style={{
                position: 'absolute',
                right: 0,
                bottom: 0,
                width: '100%',
                textAlign: 'left',
                zIndex: 2,
              }}
            >
              <div
                className="text-right"
                style={{
                  paddingBottom: 10,
                  paddingRight: 16,
                  paddingLeft: 16,
                  paddingTop: 16,
                  background: 'rgba(255, 255, 255, 0.75)',
                }}
              >
                <Text className="">Please complete all required field*</Text>
              </div>
              <Row
                style={{
                  width: '100%',
                  background: '#fff',
                  padding: '10px 16px',
                  borderTop: '1px solid #e9e9e9',
                }}
              >
                <Col span={12}>
                  <Button
                    icon="close"
                    onClick={this.onClose}
                    style={{ background: '#EDEDED' }}
                  >
                    Close
                  </Button>
                </Col>

                <Col span={12} className="text-right">
                  <Button
                    htmlType="submit"
                    type="primary"
                    icon="save"
                    loading={isSendingRequest}
                    disabled={isSendingRequest}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        </Drawer>
        <Row>
          <Col span={24}>
            <Title level={1}>Meta Data</Title>
          </Col>
        </Row>
        <div className="paper-panel">
          <Row>
            <Col span={12}>
              <Search
                placeholder="Search"
                onChange={({ target: { value } }) =>
                  this.handleSearchChange(value)
                }
                style={{ width: 350 }}
                value={searchValue}
              />
            </Col>
            <Col span={12} className="text-right"></Col>
          </Row>

          <Table
            rowKey="page_key"
            columns={this.columns}
            dataSource={this.state.data}
            pagination={this.state.pagination}
            loading={this.state.loading}
            onChange={this.handleTableChange}
          />
        </div>
      </div>
    );
  }
}

export default Form.create({ name: 'meta' })(App);
