import React, { Component } from 'react';
import { Route, Link, withRouter, Redirect } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { ClipLoader } from 'react-spinners';
import reqwest from 'reqwest';
import cookie from 'js-cookie';
import './App.css';

import authen from './authen.js';
import Article from './article';
import CarelineBanner from './careline_banner';
import CarelineMessage from './careline_message';
import EventSchedule from './event_schedule';
import Events from './event';
import GetSampleBanner from './get_sample_banner';
import HomeBanner from './home_banner';
import Login from './login';
import Meta from './meta';
import Product from './product';
import ProductBanner from './product_banner';
import ProductButton from './product_button';
import Promotion from './promotion';
import PromotionBanner from './promotion_banner';
import Register from './register';
import Sampling from './sampling';
import SystemUser from './system_user';
import Testimonials from './testimonials';
import UserList from './user_list';

const { Header, Content, Footer, Sider } = Layout;

const App = withRouter((props) => <RouterApp {...props} />);

const PrivateRoute = ({
  component: Component,
  pageLoading: PageLoading,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      cookie.get('token') ? (
        <Component {...props} pageLoading={PageLoading} />
      ) : (
        <Redirect to="/" />
      )
    }
  />
);

class RouterApp extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    const token = cookie.get('token');

    if (token) {
      this.pageLoading(true);

      reqwest({
        url: process.env.REACT_APP_API_URL + '/auth/',
        method: 'get',
        type: 'json',
        data: {
          token,
        },
      })
        .then((data) => {
          authen.authenticate(() => {
            this.setState({
              menu: 'careline_message',
              username: data.message,
              redirectToLogin: false,
              pageLoading: false,
            });

            if (this.props.location.pathname === '/') {
              this.props.history.push('/careline_message');
            }
          });
        })
        .catch((err) => {})
        .always(() => {
          this.pageLoading(false);
        });
    }
  }

  state = {
    menu: this.props.location.pathname.replace('/', ''),
    redirectToLogin: false,
    pageLoading: true,
    username: '',
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;

    let defaultMenu = (this.props?.location?.pathname || '').replace('/', '');
    defaultMenu =
      defaultMenu === '' || defaultMenu === '/'
        ? 'careline_message'
        : defaultMenu;

    this.setState({ menu: defaultMenu });
  }

  pageLoading = (loading = false) => {
    if (this._isMounted) {
      this.setState({ pageLoading: loading });
    }
  };

  setUsername = (username = '') => {
    if (this._isMounted) {
      this.setState({ username: username });
    }
  };

  handleLogout() {
    cookie.remove('token');

    authen.signout(() => {
      this.setState(() => ({
        menu: 'careline_message',
        redirectToLogin: true,
        username: '',
      }));

      if (this.props.location.pathname !== '/') {
        this.props.history.push('/');
      }

      // this.setState({
      //   redirectToLogin: false,
      // });
    });
  }

  render() {
    const { menu, pageLoading, username } = this.state;

    if (this.state.redirectToLogin) {
      return (
        <Login pageLoading={this.pageLoading} setUsername={this.setUsername} />
      );
    }

    return authen.isAuthenticated === false ? (
      <Route
        path="/"
        render={(props) => (
          <Login
            {...props}
            pageLoading={this.pageLoading}
            setUsername={this.setUsername}
          />
        )}
      />
    ) : (
      <Layout style={{ minHeight: '100vh' }}>
        <div
          className={'loading-panel ' + (pageLoading ? ' show' : '')}
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 1005,
          }}
        >
          <ClipLoader
            css={{
              position: 'fixed',
              zIndex: '9999',
              top: '50%',
              transform: 'translateY(-50%)',
              left: 0,
              right: 0,
              margin: 'auto',
            }}
            size={50}
            color={'#9B0000'}
            loading={pageLoading}
          />
        </div>
        <Header
          className="header"
          style={{ position: 'fixed', zIndex: 1, width: '100%' }}
        >
          <div className="logo">
            <img src="../assets/images/logo.png" alt="" />
          </div>

          <div className="text-right">
            <div id="top-menus">
              <div className="account-name item">{username}</div>
              <span
                className="item"
                style={{ cursor: 'pointer' }}
                onClick={() => this.handleLogout()}
              >
                <i
                  className="fa fa-sign-out"
                  style={{
                    fontSize: 18,
                    verticalAlign: 'middle',
                    lineHeight: 1,
                  }}
                ></i>{' '}
                Sign Out
              </span>
            </div>
          </div>
        </Header>

        <Layout style={{ marginTop: 64 }}>
          <Sider
            width={210}
            className="side-menu"
            style={{
              overflow: 'auto',
              position: 'fixed',
              left: 0,
              top: 64,
              bottom: 0,
              zIndex: 10,
            }}
          >
            <Menu id="side-menus" defaultSelectedKeys={[menu]} mode="inline">
              <Menu.Item key="careline_message">
                <div className="menu-item-icon">
                  <img src="./assets/images/mail.png" alt="" />
                </div>
                <span>Careline Message</span>
                <Link to="/careline_message" />
              </Menu.Item>

              {/* <Menu.Item key="testimonials">
                <div className="menu-item-icon">
                  <img src="./assets/images/testimonial.png" alt="" />
                </div>
                <span>Testimonials</span>
                <Link to="/testimonials" />
              </Menu.Item> */}

              <Menu.Item key="article">
                <div className="menu-item-icon">
                  <img src="./assets/images/article.png" alt="" />
                </div>
                <span className="menu-item-title">Article</span>
                <Link to="/article" />
              </Menu.Item>

              <Menu.Item key="product">
                <div className="menu-item-icon">
                  <img src="./assets/images/product.png" alt="" />
                </div>
                <span className="menu-item-title">Product</span>
                <Link to="/product" />
              </Menu.Item>

              <Menu.Item key="product_button">
                <div className="menu-item-icon">
                  <img src="./assets/images/product.png" alt="" />
                </div>
                <span className="menu-item-title">Product Button</span>
                <Link to="/product_button" />
              </Menu.Item>

              <Menu.Item key="meta">
                <div className="menu-item-icon">
                  <img src="./assets/images/seo.png" alt="" />
                </div>
                <span className="menu-item-title">SEO Meta Data</span>
                <Link to="/meta" />
              </Menu.Item>

              <Menu.Divider
                style={{
                  backgroundColor: '#DBDBDB',
                  marginTop: 10,
                  marginBottom: 10,
                }}
              />

              {/* <Menu.Item key="events">
                <div className="menu-item-icon">
                  <img src="./assets/images/event.png" alt="" />
                </div>
                <span className="menu-item-title">Event</span>
                <Link to="/events" />
              </Menu.Item> */}

              {/* <Menu.Item key="event_schedule">
                <div className="menu-item-icon">
                  <img src="./assets/images/event-schedule.png" alt="" />
                </div>
                <span className="menu-item-title">Event Schedule</span>
                <Link to="/event_schedule" />
              </Menu.Item> */}

              <Menu.Item key="promotion">
                <div className="menu-item-icon">
                  <img src="./assets/images/promotion.png" alt="" />
                </div>
                <span className="menu-item-title">Promotion</span>
                <Link to="/promotion" />
              </Menu.Item>

              <Menu.Divider
                style={{
                  backgroundColor: '#DBDBDB',
                  marginTop: 10,
                  marginBottom: 10,
                }}
              />

              <Menu.Item key="user_list">
                <div className="menu-item-icon">
                  <img src="./assets/images/sampling.png" alt="" />
                </div>
                <span className="menu-item-title">Get Sample</span>
                <Link to="/user_list" />
              </Menu.Item>

              <Menu.Item key="sampling">
                <div className="menu-item-icon">
                  <img src="./assets/images/sampling.png" alt="" />
                </div>
                <span className="menu-item-title">Request Sampling</span>
                <Link to="/sampling" />
              </Menu.Item>

              {/* <Menu.Item key="register">
                <div className="menu-item-icon">
                  <img src="./assets/images/register.png" alt="" />
                </div>
                <span className="menu-item-title">Club Register</span>
                <Link to="/register" />
              </Menu.Item> */}

              <Menu.Divider
                style={{
                  backgroundColor: '#DBDBDB',
                  marginTop: 10,
                  marginBottom: 10,
                }}
              />

              <Menu.Item key="home_banner">
                <div className="menu-item-icon">
                  <img src="./assets/images/home-banner.png" alt="" />
                </div>
                <span className="menu-item-title">Home Banner</span>
                <Link to="/home_banner" />
              </Menu.Item>

              <Menu.Item key="promotion_banner">
                <div className="menu-item-icon">
                  <img src="./assets/images/promotion-banner.png" alt="" />
                </div>
                <span className="menu-item-title">Promotion Banner</span>
                <Link to="/promotion_banner" />
              </Menu.Item>

              <Menu.Item key="product_banner">
                <div className="menu-item-icon">
                  <img src="./assets/images/product_banner.png" alt="" />
                </div>
                <span className="menu-item-title">Product Banner</span>
                <Link to="/product_banner" />
              </Menu.Item>

              <Menu.Item key="careline_banner">
                <div className="menu-item-icon">
                  <img src="./assets/images/careline-banner.png" alt="" />
                </div>
                <span className="menu-item-title">Careline Banner</span>
                <Link to="/careline_banner" />
              </Menu.Item>

              <Menu.Item key="get_sample_banner">
                <div className="menu-item-icon">
                  <img src="./assets/images/get-sample-banner.png" alt="" />
                </div>
                <span className="menu-item-title">Get Sample Banner</span>
                <Link to="/get_sample_banner" />
              </Menu.Item>

              <Menu.Divider
                style={{
                  backgroundColor: '#DBDBDB',
                  marginTop: 10,
                  marginBottom: 10,
                }}
              ></Menu.Divider>

              <Menu.Item key="system_user">
                <div className="menu-item-icon">
                  <img src="./assets/images/system-user.png" alt="" />
                </div>
                <span className="menu-item-title">System User</span>
                <Link to="/system_user" />
              </Menu.Item>
            </Menu>

            <div id="app-version">Application Version 1.0</div>
          </Sider>

          <Layout style={{ marginLeft: 210, minWidth: 814 }}>
            <Content
              style={{
                paddingTop: 30,
                paddingLeft: 58,
                paddingRight: 58,
                paddingBottom: 30,
              }}
            >
              <PrivateRoute
                path="/article"
                component={Article}
                pageLoading={this.pageLoading}
              />
              <PrivateRoute
                path="/careline_banner"
                component={CarelineBanner}
                pageLoading={this.pageLoading}
              />
              <PrivateRoute
                path="/careline_message"
                component={CarelineMessage}
                pageLoading={this.pageLoading}
              />
              <PrivateRoute
                path="/event_schedule"
                component={EventSchedule}
                pageLoading={this.pageLoading}
              />
              <PrivateRoute
                path="/events"
                component={Events}
                pageLoading={this.pageLoading}
              />
              <PrivateRoute
                path="/get_sample_banner"
                component={GetSampleBanner}
                pageLoading={this.pageLoading}
              />
              <PrivateRoute
                path="/home_banner"
                component={HomeBanner}
                pageLoading={this.pageLoading}
              />
              <PrivateRoute
                path="/meta"
                component={Meta}
                pageLoading={this.pageLoading}
              />
              <PrivateRoute
                path="/product"
                component={Product}
                pageLoading={this.pageLoading}
              />
              <PrivateRoute
                path="/product_banner"
                component={ProductBanner}
                pageLoading={this.pageLoading}
              />
              <PrivateRoute
                path="/product_button"
                component={ProductButton}
                pageLoading={this.pageLoading}
              />
              <PrivateRoute
                path="/promotion"
                component={Promotion}
                pageLoading={this.pageLoading}
              />
              <PrivateRoute
                path="/promotion_banner"
                component={PromotionBanner}
                pageLoading={this.pageLoading}
              />
              <PrivateRoute
                path="/register"
                component={Register}
                pageLoading={this.pageLoading}
              />
              <PrivateRoute
                path="/sampling"
                component={Sampling}
                pageLoading={this.pageLoading}
              />
              <PrivateRoute
                path="/system_user"
                component={SystemUser}
                pageLoading={this.pageLoading}
              />
              <PrivateRoute
                path="/testimonials"
                component={Testimonials}
                pageLoading={this.pageLoading}
              />
              <PrivateRoute
                path="/user_list"
                component={UserList}
                pageLoading={this.pageLoading}
              />
            </Content>

            <Footer style={{ display: 'none' }}></Footer>
          </Layout>
        </Layout>
      </Layout>
    );
  }
}

export default App;
