import React from 'react';
import { SyncOutlined } from '@ant-design/icons';
import {
  Modal,
  Form,
  Typography,
  Table,
  Button,
  Icon,
  Row,
  Col,
  Input,
  Select,
  Drawer,
  DatePicker,
  notification,
} from 'antd';
import 'antd/dist/antd.css';
import './sampling.css';
import reqwest from 'reqwest';
import Dotdotdot from 'react-dotdotdot';
import axios from 'axios';

import moment from 'moment';
const { RangePicker } = DatePicker;
const { Search } = Input;
const { Text, Title } = Typography;

let searchTimeoutId = false;

class App extends React.Component {
  state = {
    header_menu: [],
    data: [],
    brand_names: [],
    pagination: { pageSize: 10 },
    loading: false,
    visible: false,
    isSendingRequest: false,
    isExportingRequest: false,
    isGetSampleRequest: false,
    saveButton: false,
    searchText: '',
    searchMilk: '',
    dateStart: '',
    dateStop: '',
    detail: {
      id: null,
      create_date: null,
      tel: '',
      fullname: '',
      kid_name: '',
      kid_birth: '',
      current_milk_brand: '',
      step_no: 0,
      address: '',
      city: '',
      town: '',
      street: '',
      promotion_code: '',
    },
  };

  constructor(props) {
    super(props);

    this.columns = [
      {
        title: 'Requested',
        dataIndex: 'create_date',
        key: `create_date`,
        render: (data) => (
          <div>
            <Dotdotdot clamp={1}>
              <Text style={{ wordWrap: 'break-word' }}>
                {moment(data.substring(0, 10)).format('DD/MM/YYYY')}
              </Text>
            </Dotdotdot>
          </div>
        ),
      },
      {
        title: 'Phone number',
        dataIndex: 'tel',
        key: 'tel',
        render: (data, row) => (
          <div>
            <Text>{data}</Text>
          </div>
        ),
      },
      {
        title: 'Full name',
        dataIndex: 'fullname',
        key: 'fullname',
        render: (data) => (
          <div>
            <Dotdotdot clamp={1}>
              <Text style={{ wordWrap: 'break-word' }}>{data}</Text>
            </Dotdotdot>
          </div>
        ),
      },
      {
        title: "Kid's Name ",
        dataIndex: 'kid_name',
        key: 'kid_name',
        align: 'center',
        render: (pregnant) => (
          <div>
            <Text>{pregnant}</Text>
          </div>
        ),
      },
      {
        title: "Kid's Data of Birth",
        dataIndex: 'kid_birth',
        key: 'kid_birth',
        render: (data) => (
          <div>
            <Dotdotdot clamp={1}>
              <Text style={{ wordWrap: 'break-word' }}>
                {moment(data).format('DD/MM/YYYY')}
              </Text>
            </Dotdotdot>
          </div>
        ),
      },
      {
        title: 'Current milk brand',
        dataIndex: 'current_milk_brand',
        key: 'current_milk_brand',
        align: 'center',
        render: (pregnant) => (
          <div>
            <Text>{pregnant}</Text>
          </div>
        ),
      },
      {
        title: '',
        dataIndex: 'id',
        key: 'key',
        width: 100,
        render: (key) => (
          <Button
            style={{ width: 80 }}
            onClick={() => {
              this.showDrawer(key);
            }}
            className="icon-btn"
          >
            <Icon type="profile" />
            Detail
          </Button>
        ),
      },
    ];

    this.onInit();
  }

  componentDidMount() {
    document.title = 'Get Sample';
    // this.onInit()
  }

  fetch = (params = {}) => {
    this.props.pageLoading(true);

    reqwest({
      url: process.env.REACT_APP_API_URL + '/promotion_code',
      method: 'get',
      type: 'json',
      data: {
        search: this.state.searchText,
        milk: this.state.searchMilk,
        dateStart: this.state.dateStart,
        dateStop: this.state.dateStop,
        size: this.state.pagination.pageSize,
        ...params,
      },
    })
      .then((data) => {
        this.setState({
          data: data.results,
        });

        document.body.style.overflow = null;
      })
      .catch((err) => {})
      .always(() => {
        this.props.pageLoading(false);
      });
  };

  fetchBrand = () => {
    this.props.pageLoading(true);

    reqwest({
      url: process.env.REACT_APP_API_URL + '/brand',
      method: 'get',
      type: 'json',
    })
      .then((data) => {
        this.setState({
          brand_names: data.results,
        });

        document.body.style.overflow = null;
      })
      .always(() => {
        this.props.pageLoading(false);
      });
  };

  fetchGetsample = () => {
    //  Menu status
    // this.props.pageLoading(true);

    reqwest({
      url: process.env.REACT_APP_API_URL + '/header/get-sample',
      method: 'get',
      type: 'json',
    })
      .then((data) => {
        const header_menu =
          data.data && data.data.length ? { ...data.data[0] } : undefined;

        this.setState({ header_menu });

        document.body.style.overflow = null;
      })
      .always(() => {
        // this.props.pageLoading(false);
      });
    // console.log(this.state);
  };

  deleteRequest = (data = {}) => {
    return reqwest({
      url: process.env.REACT_APP_API_URL + '/promotion_code/delete',
      method: 'post',
      type: 'json',
      data,
    })
      .then(() => {
        notification.success({
          key: 'notification',
          message: 'Success',
          description: 'Delete complete!',
          duration: 3,
        });

        this.setState({ visible: false });

        this.fetch();
      })
      .catch((err) => {
        let message = err.message;

        if (err.responseText) {
          let jsonResponse = JSON.parse(err.responseText);
          message = jsonResponse.message;
        }

        notification.error({
          key: 'notification',
          message: 'Fail',
          description: message,
          duration: 5,
        });
      })
      .always(() => {
        this.setState({ isSendingRequest: false });
      });
  };

  exportRequest = (params = {}) => {
    this.setState({ isExportingRequest: true });

    axios
      .get(process.env.REACT_APP_API_URL + '/promotion_code/export', {
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json',
        },
        params,
      })
      .then((res) => {
        let fileName;

        const header = res.headers['content-disposition'];
        if (header) {
          fileName = header.split(';')[1].split('=')[1].replace(/["']/g, '');
        }

        if (!fileName) {
          fileName = `user_list_${+new Date()}.xlsx`; // whatever your file name .
        }
        console.log({ res });

        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;

        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove(); // you need to remove that element which is created before.
      })
      .catch((err) => {
        let message = err.message;

        if (err.responseText) {
          let jsonResponse = JSON.parse(err.responseText);
          message = jsonResponse.message;
        }

        notification.error({
          key: 'notification',
          message: 'Fail',
          description: message,
          duration: 5,
        });
      })
      .then(() => {
        // always executed
        this.setState({ isExportingRequest: false });
      });
  };

  setGetSampleStatusRequest = (data = {}) => {
    return reqwest({
      url: process.env.REACT_APP_API_URL + '/header/update-status',
      method: 'post',
      type: 'json',
      data,
    })
      .then(() => {
        notification.success({
          key: 'notification',
          message: 'Success',
          description: 'Toggle "Get Sample Menu" complete!',
          duration: 3,
        });

        this.fetchGetsample();
      })
      .catch((err) => {
        let message = err.message;

        if (err.responseText) {
          let jsonResponse = JSON.parse(err.responseText);
          message = jsonResponse.message;
        }

        notification.error({
          key: 'notification',
          message: 'Fail',
          description: message,
          duration: 5,
        });
      })
      .always(() => {
        this.setState({ isGetSampleRequest: false });
      });
  };

  onInit = () => {
    this.fetchBrand();
    this.fetchGetsample();
    this.fetch();
  };

  showDrawer = async (key) => {
    let data = {};

    data = this.state.data.find((item) => item.id === key) || {};
    data.key = data.id;

    this.setState({
      detail: data,
      visible: true,
    });
  };

  showDeleteConfirm = () => {
    Modal.confirm({
      title: 'Confirm',
      // icon: <ExclamationCircleOutlined />,
      content: 'Do you want to delete this item?',
      okText: 'Yes, Delete',
      cancelText: 'No',
      okType: 'danger',
      onOk: (e) => {
        const detail = this.state.detail;

        return this.deleteRequest({ id: detail.key });
      },
      onCancel: (e) => {
        Modal.destroyAll();
      },
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  getIndexInParent = (el) => {
    return Array.from(el.parentNode.children).indexOf(el);
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });
    // this.fetch({
    // 	size: pagination.pageSize,
    // 	page: pagination.current,
    // 	sortField: sorter.field,
    // 	sortOrder: sorter.order,
    // 	...filters,
    // });
  };

  handleSearchChange(value) {
    this.setState({ searchText: value });
    window.clearTimeout(searchTimeoutId);
    searchTimeoutId = window.setTimeout(() => {
      this.fetch();
    }, 1500);
  }

  handleSelectMilk = (value) => {
    this.setState({ searchMilk: value });
    window.clearTimeout(searchTimeoutId);
    searchTimeoutId = window.setTimeout(() => {
      this.fetch();
    }, 1500);
  };

  handleToggleStatusGetSampleMenu = () => {
    this.setState({ isGetSampleRequest: false });

    let header_menu = this.state.header_menu;
    let { id, status } = { ...(header_menu || {}) };
    console.log({ header_menu, id, status });

    this.setGetSampleStatusRequest({
      id,
      status: status === 1 ? '0' : '1',
    });

    // reqwest({
    //   url: process.env.REACT_APP_API_URL + '/header/update-status',
    //   method: 'post',
    //   data: {
    //     id: value.id,
    //     status: value.status,
    //   },
    //   type: 'json',
    // }).then((res) => {
    //   // this.setState({
    //   // 	header_menu: data.data[0]
    //   // });
    //   this.fetchGetsample();
    //   this.props.pageLoading(false);
    //   // console.log(res);
    //   // this.setState({ deleteLoading: false, deleteModalVisible: false, visible: false });
    //   this.fetch();
    // });
    // console.log('value',value);
  };

  handleExport() {
    // window.open(
    //   process.env.REACT_APP_API_URL +
    //     '/promotion_code/export?search=' +
    //     this.state.searchText +
    //     '&milk=' +
    //     this.state.searchMilk +
    //     '&dateStart=' +
    //     this.state.dateStart +
    //     '&dateStop=' +
    //     this.state.dateStop,
    // );

    this.exportRequest({
      search: this.state.searchText || '',
      milk: this.state.searchMilk || '',
      dateStart: this.state.dateStart || '',
      dateStop: this.state.dateStop || '',
    });
  }

  handleIsRegisterDateFilterChange = (value) => {
    this.setState({
      dateStart: moment(value[0]).format('YYYY-MM-DD'),
      dateStop: moment(value[1]).format('YYYY-MM-DD'),
    });

    window.clearTimeout(searchTimeoutId);
    searchTimeoutId = window.setTimeout(() => {
      this.fetch();
    }, 1500);
  };

  render() {
    const {
      isSendingRequest,
      isExportingRequest,
      isGetSampleRequest,
      searchText,
      detail,
      brand_names,
      searchMilk,
      header_menu,
    } = this.state;

    return (
      <div id="sampling-component">
        <Row>
          <Col span={24}>
            <Title level={1}>Get Sample</Title>
          </Col>
        </Row>
        <Drawer
          title="Detail"
          placement="right"
          closable={false}
          width={550}
          onClose={this.onClose}
          visible={this.state.visible}
          bodyStyle={{ paddingBottom: 80 }}
        >
          <Row>
            <Col span={6} style={{ textAlign: 'right', marginRight: '8px' }}>
              <Text>Phone number:</Text>
            </Col>
            <Col span={8}>
              <Text>{detail.tel}</Text>
            </Col>
          </Row>
          <Row>
            <Col span={6} style={{ textAlign: 'right', marginRight: '8px' }}>
              <Text>Name:</Text>
            </Col>
            <Col span={8}>
              <Text>{detail.fullname}</Text>
            </Col>
          </Row>
          <Row>
            <Col span={6} style={{ textAlign: 'right', marginRight: '8px' }}>
              <Text>Kid's Name:</Text>
            </Col>
            <Col span={8}>
              <Text>{detail.kid_name}</Text>
            </Col>
          </Row>
          <Row>
            <Col span={6} style={{ textAlign: 'right', marginRight: '8px' }}>
              <Text>Kid's Date of Birth:</Text>
            </Col>
            <Col span={8}>
              <Text>{moment(detail.kid_birth).format('DD/MM/YYYY')}</Text>
            </Col>
          </Row>
          <Row>
            <Col span={6} style={{ textAlign: 'right', marginRight: '8px' }}>
              <Text>Current milk brand:</Text>
            </Col>
            <Col span={8}>
              <Text>{detail.current_milk_brand}</Text>
            </Col>
          </Row>
          <Row>
            <Col span={6} style={{ textAlign: 'right', marginRight: '8px' }}>
              <Text>Address:</Text>
            </Col>
            <Col span={8}>
              <Text>{detail.address}</Text>
            </Col>
          </Row>
          <Row>
            <Col span={6} style={{ textAlign: 'right', marginRight: '8px' }}>
              <Text>City:</Text>
            </Col>
            <Col span={8}>
              <Text>{detail.city}</Text>
            </Col>
          </Row>
          <Row>
            <Col span={6} style={{ textAlign: 'right', marginRight: '8px' }}>
              <Text>Township:</Text>
            </Col>
            <Col span={8}>
              <Text>{detail.town}</Text>
            </Col>
          </Row>
          <Row>
            <Col span={6} style={{ textAlign: 'right', marginRight: '8px' }}>
              <Text>Street:</Text>
            </Col>
            <Col span={8}>
              <Text>{detail.street}</Text>
            </Col>
          </Row>
          <div
            style={{
              position: 'absolute',
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e8e8e8',
              padding: '10px 16px',
              left: 0,
              background: '#fff',
              borderRadius: '0 0 4px 4px',
              zIndex: 2,
            }}
          >
            <Row>
              <Col span={12}>
                <Button
                  icon="close"
                  onClick={this.onClose}
                  style={{ backgroundColor: '#EDEDED' }}
                >
                  Close
                </Button>
              </Col>
              <Col span={12} className="text-right">
                {detail.key && (
                  <Button
                    type="danger"
                    ghost
                    icon="delete"
                    onClick={this.showDeleteConfirm}
                    disabled={isSendingRequest}
                  >
                    Delete
                  </Button>
                )}
              </Col>
            </Row>
          </div>
        </Drawer>

        <div className="paper-panel">
          <Row gutter={{ xs: 8, sm: 8, md: 8, lg: 8 }}>
            <Col span={16}>
              <Row gutter={8}>
                <Col span={8}>
                  <Search
                    placeholder="Phone number / Name / Kid's Name "
                    onChange={({ target: { value } }) =>
                      this.handleSearchChange(value)
                    }
                    style={{ width: '100%', marginRight: 15 }}
                    value={searchText}
                  />
                </Col>
                <Col span={6}>
                  <Select
                    style={{ width: '100%', marginRight: 8 }}
                    placeholder="Current milk branch"
                    value={searchMilk}
                    onChange={this.handleSelectMilk}
                  >
                    <Select.Option key={`brand`} value="">
                      Select Current milk branch
                    </Select.Option>
                    {brand_names.map((row, index) => {
                      return (
                        <Select.Option key={`brand${index}`} value={row.id}>
                          {row.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Col>
                <Col span={8}>
                  <RangePicker
                    format={'DD/MM/YYYY'}
                    onChange={this.handleIsRegisterDateFilterChange}
                  />
                </Col>
                <Col span={2}>
                  <Button onClick={() => this.onInit()}>
                    <SyncOutlined
                      style={{
                        color: 'blue',
                        fontSize: 16,
                        fontWeight: 'bold',
                      }}
                    />
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col span={8} className="text-right">
              {header_menu && (
                <Button
                  icon="bulb"
                  loading={isGetSampleRequest}
                  disabled={isGetSampleRequest}
                  onClick={() => this.handleToggleStatusGetSampleMenu()}
                  style={{
                    // minWidth: 140,
                    marginRight: '8px',
                    // float: 'right',
                    color: 'white',
                    background: header_menu.status === '1' ? 'green' : 'red',
                  }}
                >
                  Get Sample: {header_menu.status === '1' ? 'Show' : 'Hide'}
                </Button>
              )}

              <Button
                type="primary"
                icon="file-excel"
                loading={isExportingRequest}
                disabled={isExportingRequest}
                onClick={() => this.handleExport()}
              >
                Export
              </Button>
            </Col>
          </Row>

          <Table
            rowKey="id"
            columns={this.columns}
            dataSource={this.state.data}
            pagination={this.state.pagination}
            loading={this.state.loading}
            onChange={this.handleTableChange}
          />
        </div>
      </div>
    );
  }
}

export default Form.create({ name: 'sampling' })(App);
