import React from 'react';
import {
  Modal,
  Typography,
  Table,
  Button,
  Row,
  Col,
  Form,
  Drawer,
  Input,
  Icon,
  notification,
} from 'antd';
import 'antd/dist/antd.css';
import './careline_message.css';
import reqwest from 'reqwest';
import moment from 'moment';
import Dotdotdot from 'react-dotdotdot';
import axios from 'axios';

const { Search } = Input;
const { Text, Title } = Typography;

let searchTimeoutId = false;

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      pagination: { pageSize: 10 },
      loading: false,
      visible: false,
      isSendingRequest: false,
      isExportingRequest: false,
      searchValue: '',
      detail: { title: ['', ''] },
    };

    this.columns = [
      {
        title: 'Submit Date',
        dataIndex: 'submitDate',
        width: 120,
        key: 'submitDate',
        render: (data) => (
          <div>
            <Text style={{ wordWrap: 'break-word' }}>
              {moment(data).format('DD/MM/YYYY')}
            </Text>
          </div>
        ),
      },
      {
        title: 'Title/Detail',
        dataIndex: 'title',
        key: 'title',
        render: (title) => (
          <div>
            <Dotdotdot clamp={1}>
              <strong>{title[0]}</strong>
            </Dotdotdot>
            <Dotdotdot clamp={2}>{title[1]}</Dotdotdot>
          </div>
        ),
      },
      {
        title: 'Contact',
        dataIndex: 'contact',
        key: 'contact',
        width: 250,
        render: (contact) => (
          <div>
            <strong>{contact[0]}</strong>
            <div>{contact[1]}</div>
            <div>{contact[2]}</div>
          </div>
        ),
      },
      {
        title: '',
        dataIndex: 'key',
        key: 'key',
        width: 120,
        render: (key) => (
          <Button
            onClick={() => {
              this.showDrawer(key);
            }}
          >
            <Icon type="profile" />
            Detail
          </Button>
        ),
      },
    ];
  }

  componentDidMount() {
    document.title = 'Careline Message';
    this.fetch();
  }

  fetch = (params = {}) => {
    this.props.pageLoading(true);

    reqwest({
      url: process.env.REACT_APP_API_URL + '/careline_message',
      method: 'get',
      type: 'json',
      data: {
        size: this.state.pagination.pageSize,
        search: this.state.searchValue,
        ...params,
      },
    })
      .then((data) => {
        const pagination = { ...this.state.pagination };
        // Read total count from server
        // pagination.total = data.totalCount;
        pagination.total = data.total;
        document.body.style.overflow = null;
        this.setState({
          data: data.results,
          pagination,
        });
      })
      .catch((err) => {})
      .always(() => {
        this.props.pageLoading(false);
      });
  };

  deleteRequest = (data = {}) => {
    return reqwest({
      url: process.env.REACT_APP_API_URL + '/careline_message/delete',
      method: 'post',
      type: 'json',
      data,
    })
      .then(() => {
        notification.success({
          key: 'notification',
          message: 'Success',
          description: 'Delete complete!',
          duration: 3,
        });

        this.setState({ visible: false });

        this.fetch();
      })
      .catch((err) => {
        let message = err.message;

        if (err.responseText) {
          let jsonResponse = JSON.parse(err.responseText);
          message = jsonResponse.message;
        }

        notification.error({
          key: 'notification',
          message: 'Fail',
          description: message,
          duration: 5,
        });
      })
      .always(() => {
        this.setState({ isSendingRequest: false });
      });
  };

  exportRequest = (params = {}) => {
    this.setState({ isExportingRequest: true });

    axios
      .get(process.env.REACT_APP_API_URL + '/careline_message/export', {
        // responseType: 'arraybuffer',
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json',
        },
        params,
      })
      .then((res) => {
        const header = res.headers['content-disposition'];
        let fileName;
        if (header) {
          fileName = header.split(';')[1].split('=')[1].replace(/["']/g, '');
        }

        if (!fileName) {
          fileName = `careline_message_${+new Date()}.xlsx`; // whatever your file name .
        }

        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;

        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove(); // you need to remove that element which is created before.
      })
      .catch((err) => {
        let message = err.message;

        if (err.responseText) {
          let jsonResponse = JSON.parse(err.responseText);
          message = jsonResponse.message;
        }

        notification.error({
          key: 'notification',
          message: 'Fail',
          description: message,
          duration: 5,
        });
      })
      .then(() => {
        // always executed
        this.setState({ isExportingRequest: false });
      });
  };

  showDrawer = (key) => {
    const data = {};

    Object.assign(data, this.state.data.find((item) => item.key === key) || {});

    this.setState({
      detail: data,
      visible: data.key ? true : false,
    });
  };

  showDeleteConfirm = () => {
    Modal.confirm({
      title: 'Confirm',
      // icon: <ExclamationCircleOutlined />,
      content: 'Do you want to delete this item?',
      okText: 'Yes, Delete',
      cancelText: 'No',
      okType: 'danger',
      onOk: () => {
        const detail = this.state.detail;

        return this.deleteRequest({ id: detail.key });
      },
      onCancel: () => {
        Modal.destroyAll();
      },
    });
  };

  handleSearchChange(value) {
    this.setState({ searchValue: value });
    if (searchTimeoutId) {
      window.clearTimeout(searchTimeoutId);
    }
    searchTimeoutId = window.setTimeout(() => {
      this.fetch();
    }, 1500);
  }

  handleDeleteModalOk = () => {
    const detail = this.state.detail;
    this.setState({ deleteLoading: true });

    reqwest({
      url: process.env.REACT_APP_API_URL + '/careline_message/delete',
      method: 'post',
      data: {
        id: detail.key,
      },
      type: 'json',
    }).then(() => {
      this.setState({
        deleteLoading: false,
        deleteModalVisible: false,
        visible: false,
      });
      this.fetch();
    });
  };

  handleDeleteModalCancel = () => {
    this.setState({ deleteModalVisible: false });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });
    this.fetch({
      size: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters,
    });
  };

  handleExport() {
    // window.open(
    //   process.env.REACT_APP_API_URL +
    //     '/careline_message/export?search=' +
    //     this.state.searchValue,
    // );

    this.exportRequest({ search: this.state.searchValue || '' });
  }

  render() {
    const { isSendingRequest, isExportingRequest, searchValue, detail } =
      this.state;

    return (
      <div id="careline-component">
        <Drawer
          title="Detail"
          width={380}
          closable={false}
          onClose={this.onClose}
          headerStyle={{ textTransform: 'uppercase', fontWeight: 'bold' }}
          visible={this.state.visible}
          bodyStyle={{ paddingBottom: 80 }}
        >
          <div>
            <Form.Item label={detail.title[0]} colon={false}>
              <div className="">{detail.title[1]}</div>
            </Form.Item>
          </div>

          <div
            style={{
              position: 'absolute',
              right: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'left',
            }}
          >
            <Row>
              <Col span={12}>
                <Button
                  icon="close"
                  onClick={this.onClose}
                  style={{ backgroundColor: '#EDEDED' }}
                >
                  Close
                </Button>
              </Col>
              <Col span={12} className="text-right">
                {detail.key && (
                  <Button
                    style={{
                      marginRight: 8,
                    }}
                    type="danger"
                    ghost
                    icon="delete"
                    onClick={this.showDeleteConfirm}
                    disabled={isSendingRequest}
                  >
                    Delete
                  </Button>
                )}
              </Col>
            </Row>
          </div>
        </Drawer>

        <Row>
          <Col span={24}>
            <Title level={1}>Careline Message</Title>
          </Col>
        </Row>
        <div className="paper-panel">
          <Row>
            <Col span={12}>
              <Search
                placeholder="Message / Customer"
                onChange={({ target: { value } }) =>
                  this.handleSearchChange(value)
                }
                value={searchValue}
                style={{ width: 350 }}
              />
            </Col>
            <Col span={12} className="text-right">
              <Button
                type="primary"
                icon="file-excel"
                loading={isExportingRequest}
                disabled={isExportingRequest}
                onClick={() => this.handleExport()}
              >
                Export
              </Button>
            </Col>
          </Row>
          <Table
            columns={this.columns}
            dataSource={this.state.data}
            pagination={this.state.pagination}
            loading={this.state.loading}
            onChange={this.handleTableChange}
            rowKey={'key'}
          />
        </div>
      </div>
    );
  }
}

export default App;
