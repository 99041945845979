import React from 'react';
import {
  Modal,
  Icon,
  Typography,
  Table,
  Button,
  Row,
  Select,
  Checkbox,
  Col,
  Input,
  Drawer,
  Form,
  Radio,
  Upload,
  message,
  notification,
} from 'antd';
import 'antd/dist/antd.css';
import './product.css';
import reqwest from 'reqwest';

import Dotdotdot from 'react-dotdotdot';
import ReactQuill from 'react-quill';
import HtmlEditButton from 'quill-html-edit-button';
import 'react-quill/dist/quill.snow.css';

import dragula from 'dragula';
import 'dragula/dist/dragula.css';
import moment from 'moment';
import { Tabs } from 'antd';
import _get from 'lodash/get';

const { TabPane } = Tabs;
const { Search } = Input;
const { Text, Title } = Typography;
const imageDataURI = require('image-data-uri');

// Quill editor : size
const Size = ReactQuill.Quill.import('attributors/style/size');
Size.whitelist = ['13px', '14px', '16px', '18px', '20px', '32px', '72px'];
ReactQuill.Quill.register(Size, true);

// Quill editor : HTML edit button
ReactQuill.Quill.register({ 'modules/htmlEditButton': HtmlEditButton }, true);

let searchTimeoutId = false;

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}

function validateImage(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}

function uploadImage(file, cb) {
  let reader = new FileReader();
  reader.readAsDataURL(file);

  reader.onload = function () {
    const b64 = reader.result;
    reqwest({
      url: process.env.REACT_APP_API_URL + '/image/save',
      method: 'post',
      data: {
        image: b64,
      },
      type: 'json',
    }).then((data) => {
      cb(data.src);
    });
  };

  reader.onerror = function (error) {
    //
  };

  return false;
}

class App extends React.Component {
  state = {
    data: [],
    key: false,
    loading: false,
    visible: false,
    isSendingRequest: false,
    imageUrl: false,
    searchValue: '',
    detail: { title: ['', ''] },
  };

  constructor(props) {
    super(props);

    this.ref_editors = {
      information: {
        en: React.createRef(),
        bm: React.createRef(),
      },
      preparation: {
        en: React.createRef(),
        bm: React.createRef(),
      },
      nutrition: {
        en: React.createRef(),
        bm: React.createRef(),
      },
    };

    this.columns = [
      {
        title: 'Product Name',
        dataIndex: 'name',
        key: 'name',
        render: (data) => (
          <div>
            <Dotdotdot clamp={1}>
              <Text>{data}</Text>
            </Dotdotdot>
          </div>
        ),
      },
      {
        title: 'Created Date',
        dataIndex: 'created_time',
        key: 'created_time',
        width: 200,
        render: (data) => (
          <div>
            <Text style={{ wordWrap: 'break-word' }}>
              {moment(data).format('DD/MM/YYYY')}
            </Text>
          </div>
        ),
      },
      {
        title: 'Published',
        dataIndex: 'status',
        key: 'published',
        align: 'center',
        width: 110,
        render: (status) => (
          <i
            className={`fa ${status === '1' ? 'fa-check' : 'fa-times'}`}
            style={{ fontSize: 15 }}
          />
        ),
      },
      {
        title: '',
        dataIndex: 'id',
        key: 'key',
        width: 70,
        render: (key) => (
          <Button
            onClick={() => {
              this.showDrawer(key);
            }}
            className="icon-btn"
          >
            <Icon type="edit" />
          </Button>
        ),
      },
    ];
  }

  componentDidMount() {
    document.title = 'Product';

    this.props.form.setFieldsValue({});

    this.fetch();

    const container = document.querySelector('.ant-table-tbody');
    const drake = dragula([container], {
      moves: (el) => {
        this.start = this.getIndexInParent(el);
        return true;
      },
    });

    drake.on('drop', (el) => {
      this.end = this.getIndexInParent(el);
      this.handleReorder(this.start, this.end);
    });
  }

  fetch = (params = {}) => {
    this.props.pageLoading(true);
    this.setState({ loading: true });

    reqwest({
      url: process.env.REACT_APP_API_URL + '/product',
      method: 'get',
      type: 'json',
      data: {
        search: this.state.searchValue,
        ...params,
      },
    })
      .then((data) => {
        this.setState({
          data: data.results,
        });

        document.body.style.overflow = null;
      })
      .catch((err) => {})
      .always(() => {
        this.props.pageLoading(false);
        this.setState({ loading: false });
      });
  };

  saveRequest = (data = {}) => {
    return reqwest({
      url: process.env.REACT_APP_API_URL + '/product/save',
      method: 'post',
      type: 'json',
      data,
    })
      .then(() => {
        notification.success({
          key: 'notification',
          message: 'Success',
          description: `${data.id ? 'Update' : 'Create'} complete!`,
          duration: 3,
        });

        this.setState({ visible: false });

        this.fetch();
      })
      .catch((err) => {
        notification.error({
          key: 'notification',
          message: 'Fail',
          description: err.message,
          duration: 5,
        });
      })
      .always(() => {
        this.setState({ isSendingRequest: false });
      });
  };

  reorderRequest = (data = {}) => {
    // this.props.pageLoading(true);
    this.setState({ loading: true });

    reqwest({
      url: process.env.REACT_APP_API_URL + '/product/reorder',
      method: 'post',
      type: 'json',
      data,
    })
      .then((data) => {})
      .catch((err) => {})
      .always(() => {
        // this.props.pageLoading(false);
        this.setState({ loading: false });
      });
  };

  deleteRequest = (data = {}) => {
    return reqwest({
      url: process.env.REACT_APP_API_URL + '/product/delete',
      method: 'post',
      type: 'json',
      data,
    })
      .then(() => {
        notification.success({
          key: 'notification',
          message: 'Success',
          description: 'Delete complete!',
          duration: 3,
        });

        this.setState({ visible: false });

        this.fetch();
      })
      .catch((err) => {
        let message = err.message;

        if (err.responseText) {
          let jsonResponse = JSON.parse(err.responseText);
          message = jsonResponse.message;
        }

        notification.error({
          key: 'notification',
          message: 'Fail',
          description: message,
          duration: 5,
        });
      })
      .always(() => {
        this.setState({ isSendingRequest: false });
      });
  };

  getIndexInParent = (el) => {
    return Array.from(el.parentNode.children).indexOf(el);
  };

  showDrawer = async (key) => {
    const data = {
      key: undefined,
      id: '',
      alias: '',
      type: '1',
      name: '',
      name_bm: '',
      desc: '',
      desc_bm: '',
      sub_title: '',
      sub_title_bm: '',
      information: '',
      information_kh: '',
      preparation: '',
      preparation_kh: '',
      nutrition_info: '',
      nutrition_info_kh: '',
      image: '',
      status: '1',
    };

    if ((parseInt(key, 10) || 0) > 0) {
      Object.assign(
        data,
        this.state.data.find((item) => item.key === key) || {},
      );

      // Convert to string
      Object.assign(data, {
        name: data.name || '',
        name_bm: data.name_bm || '',
        sub_title: data.sub_title || '',
        sub_title_bm: data.sub_title_bm || '',
        desc: data.desc || '',
        desc_bm: data.desc_bm || '',
        information: data.information || '',
        information_kh: data.information_kh || '',
        preparation: data.preparation || '',
        preparation_kh: data.preparation_kh || '',
        nutrition_info: data.nutrition_info || '',
        nutrition_info_kh: data.nutrition_info_kh || '',
        image: data.img || '',
        status: `${data.status || 0}`,
        type: `${data.type || ''}`,
      });
    }

    this.props.form.setFieldsValue(data);

    this.setState({
      visible: true,
      detail: data,
      imageUrl: undefined,
      isSendingRequest: false,
    });

    if (data.img) {
      imageDataURI
        .encodeFromURL(data.img)
        .then((base64) => {
          this.props.form.setFieldsValue({
            image: base64,
          });

          this.setState({
            imageUrl: base64,
          });
        })
        .catch((err) => {
          // console.log({ err });
        });
    }
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  onSave = (e) => {
    e.preventDefault();

    this.setState({ isSendingRequest: true });

    this.props.form.validateFields((err, values) => {
      if (err) {
        notification.error({
          key: 'notification',
          message: 'Fail',
          description: 'Please fill complete form!',
          duration: 3,
        });

        this.setState({ isSendingRequest: false });

        return;
      }

      values.information_use = values.information_use ? 1 : 0;
      values.preparation_use = values.preparation_use ? 1 : 0;
      values.nutrition_use = values.nutrition_use ? 1 : 0;

      values.information =
        _get(
          this.ref_editors,
          'information.en.current.editor.root.innerHTML',
        ) || values.information;
      values.information_kh =
        _get(
          this.ref_editors,
          'information.bm.current.editor.root.innerHTML',
        ) || values.information_kh;

      values.preparation =
        _get(
          this.ref_editors,
          'preparation.en.current.editor.root.innerHTML',
        ) || values.preparation;
      values.preparation_kh =
        _get(
          this.ref_editors,
          'preparation.bm.current.editor.root.innerHTML',
        ) || values.preparation_kh;

      values.nutrition_info =
        _get(this.ref_editors, 'nutrition.en.current.editor.root.innerHTML') ||
        values.preparation;
      values.nutrition_info_kh =
        _get(this.ref_editors, 'nutrition.bm.current.editor.root.innerHTML') ||
        values.preparation_kh;

      // get mutation editors data with custom styles
      // let mut_information_en = deepFindObj(
      //   this.ref_editors,
      //   'information.en.current.editor.root.innerHTML',
      // );
      // let mut_information_bm = deepFindObj(
      //   this.ref_editors,
      //   'information.bm.current.editor.root.innerHTML',
      // );
      // let mut_preparation_en = deepFindObj(
      //   this.ref_editors,
      //   'preparation.en.current.editor.root.innerHTML',
      // );
      // let mut_preparation_bm = deepFindObj(
      //   this.ref_editors,
      //   'preparation.bm.current.editor.root.innerHTML',
      // );
      // let mut_nutrition_en = deepFindObj(
      //   this.ref_editors,
      //   'nutrition.en.current.editor.root.innerHTML',
      // );
      // let mut_nutrition_bm = deepFindObj(
      //   this.ref_editors,
      //   'nutrition.bm.current.editor.root.innerHTML',
      // );
      // console.log({ mut_nutrition_bm });

      // // mutation editors data with custom styles
      // values.information = isNotEmptyVal(mut_information_en)
      //   ? mut_information_en
      //   : values.information;
      // values.information_kh = isNotEmptyVal(mut_information_bm)
      //   ? mut_information_bm
      //   : values.information_kh;
      // values.preparation = isNotEmptyVal(mut_preparation_en)
      //   ? mut_preparation_en
      //   : values.preparation;
      // values.preparation_kh = isNotEmptyVal(mut_preparation_bm)
      //   ? mut_preparation_bm
      //   : values.preparation_kh;
      // values.nutrition_info = isNotEmptyVal(mut_nutrition_en)
      //   ? mut_nutrition_en
      //   : values.nutrition_info;
      // values.nutrition_info_kh = isNotEmptyVal(mut_nutrition_bm)
      //   ? mut_nutrition_bm
      //   : values.nutrition_info_kh;

      console.log({ values });
      this.saveRequest(values);
    });
  };

  handleChange = (info) => {
    if (info.file.status === 'uploading') {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl) => {
        this.props.form.setFieldsValue({
          image: imageUrl,
        });
        this.setState({
          imageUrl,
          loading: false,
        });
      });
    }
  };

  handleSearchChange(value) {
    this.setState({ searchValue: value });
    if (searchTimeoutId) {
      window.clearTimeout(searchTimeoutId);
    }
    searchTimeoutId = window.setTimeout(() => {
      this.fetch();
    }, 1500);
  }

  showDeleteConfirm = () => {
    Modal.confirm({
      title: 'Confirm',
      // icon: <ExclamationCircleOutlined />,
      content: 'Do you want to delete this item?',
      okText: 'Yes, Delete',
      cancelText: 'No',
      okType: 'danger',
      onOk: (e) => {
        const detail = this.state.detail;

        return this.deleteRequest({ id: detail.key });
      },
      onCancel: (e) => {
        Modal.destroyAll();
      },
    });
  };

  handleReorder = (dragIndex, draggedIndex) => {
    const data = [...this.state.data];
    const item = data.splice(dragIndex, 1)[0];
    data.splice(draggedIndex, 0, item);
    this.setState({
      data,
    });

    const postBody = [];
    for (let i = 0; i < data.length; i++) {
      postBody.push({
        key: data[i].key,
        display_order: i + 1,
      });
    }

    this.reorderRequest({ v: postBody });
  };

  modules = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        ['link', 'image', 'video'],

        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
        [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
        // [{ 'direction': 'rtl' }],                         // text direction

        // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ size: Size.whitelist }],

        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        // [{ 'font': Font.whitelist }],
        [{ align: [] }],

        ['clean'], // remove formatting button
      ],
      handlers: {
        image: this.imageHandler,
      },
    },
    htmlEditButton: {
      debug: false,
      syntax: true,
      okText: 'Confirm',
      cancelText: 'Cancel',
      buttonHTML: '&lt;&gt;',
      buttonTitle: 'Show HTML source',
      msg: 'Edit HTML here, when you click "Confirm" the quill editor\'s contents will be replaced',
      editorModules: {},
    },
  };

  imageHandler() {
    const input = document.createElement('input');

    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      const file = input.files[0];

      if (!validateImage(file)) {
        return false;
      }
      const formData = new FormData();

      formData.append('image', file);

      // Save current cursor state
      const range = this.quill.getSelection(true);

      // Insert temporary loading placeholder image
      // this.quill.insertEmbed(range.index, 'image', `${window.location.origin}/images/loaders/placeholder.gif`);

      // Move cursor to right side of image (easier to continue typing)
      this.quill.setSelection(range.index + 1);

      uploadImage(file, (cb) => {
        this.quill.insertEmbed(range.index, 'image', cb);
      });

      // Remove placeholder image
      // this.quill.deleteText(range.index, 1);

      // Insert uploaded image
      // this.quill.insertEmbed(range.index, 'image', res.body.image);
      // this.quill.insertEmbed(range.index, 'image', res);
    };
  }

  getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {};
  };

  render() {
    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? 'loading' : 'plus'} />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    const { isSendingRequest, imageUrl, searchValue, detail } = this.state;
    const { getFieldDecorator } = this.props.form;

    return (
      <div id="product-component">
        <Drawer
          id="product-component-drawer"
          title="PRODUCT DETAIL"
          placement="right"
          closable={false}
          width={730}
          onClose={this.onClose}
          visible={this.state.visible}
          bodyStyle={{ paddingBottom: 80 }}
        >
          <Form onSubmit={this.onSave} layout={'vertical'}>
            <Form.Item label="" style={{ display: 'none' }}>
              {getFieldDecorator('id', {
                initialValue: detail.id || '',
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Alias Link">
              {getFieldDecorator('alias', {
                initialValue: detail.alias || '',
                rules: [
                  {
                    required: true,
                    message: 'Please input Product alias link',
                  },
                ],
              })(<Input placeholder="" maxLength={255} />)}
            </Form.Item>
            <Form.Item label="Name (EN)">
              {getFieldDecorator('name', {
                initialValue: detail.name || '',
                rules: [
                  { required: true, message: 'Please input Product Name EN!' },
                ],
              })(<Input placeholder="" maxLength={1000} />)}
            </Form.Item>
            <Form.Item label="Name (BM)">
              {getFieldDecorator('name_bm', {
                initialValue: detail.name_bm || '',
                rules: [
                  { required: true, message: 'Please input Product Name BM!' },
                ],
              })(<Input placeholder="" maxLength={1000} />)}
            </Form.Item>
            <Form.Item label="Subtitle (EN)">
              {getFieldDecorator('sub_title', {
                initialValue: detail.sub_title || '',
                rules: [
                  {
                    required: true,
                    message: 'Please input Product Subtitle EN!',
                  },
                ],
              })(<Input placeholder="" maxLength={1000} />)}
            </Form.Item>
            <Form.Item label="Subtitle (BM)">
              {getFieldDecorator('sub_title_bm', {
                initialValue: detail.sub_title_bm || '',
                rules: [
                  {
                    required: true,
                    message: 'Please input Product Subtitle BM!',
                  },
                ],
              })(<Input placeholder="" maxLength={1000} />)}
            </Form.Item>
            <Form.Item label="Description (EN)">
              {getFieldDecorator('desc', {
                initialValue: detail.desc || '',
                rules: [
                  {
                    required: true,
                    message: 'Please input Product Description EN!',
                  },
                ],
              })(<Input placeholder="" maxLength={1000} />)}
            </Form.Item>
            <Form.Item label="Description (BM)">
              {getFieldDecorator('desc_bm', {
                initialValue: detail.desc_bm || '',
                rules: [
                  {
                    required: true,
                    message: 'Please input Product Description BM!',
                  },
                ],
              })(<Input placeholder="" maxLength={1000} />)}
            </Form.Item>
            <Form.Item label="Category">
              {getFieldDecorator('type', {
                initialValue: detail.type || '',
                rules: [
                  {
                    required: true,
                    message: 'Please input Product Description!',
                  },
                ],
              })(
                <Select
                  style={{ width: '100%', marginRight: 8 }}
                  placeholder="Please select"
                >
                  <Select.Option value="1">Dugro</Select.Option>
                  <Select.Option value="2">Supergold</Select.Option>
                  <Select.Option value="3">Dumilk</Select.Option>
                </Select>,
              )}
            </Form.Item>

            <Row gutter={[16, 0]}>
              <Col span={12}>
                <Form.Item label="Image">
                  <Upload
                    accept=".png, .jpg"
                    name="Upload"
                    listType="picture-card"
                    showUploadList={false}
                    className="photo-uploader"
                    action={
                      process.env.REACT_APP_API_URL + '/image/test/upload'
                    }
                    beforeUpload={beforeUpload}
                    onChange={this.handleChange}
                  >
                    {imageUrl ? (
                      <img
                        src={imageUrl}
                        alt="Upload"
                        style={{ width: '100%' }}
                      />
                    ) : (
                      uploadButton
                    )}
                  </Upload>

                  {getFieldDecorator('image', {
                    rules: [
                      {
                        required: true,
                        message: 'Please upload image!',
                      },
                    ],
                  })(<Input type="hidden" />)}
                  <div className="text-center">
                    <Text>300 x 200</Text>
                  </div>
                </Form.Item>
              </Col>
            </Row>

            <Row className="ant-form-item" gutter={[16, 0]}>
              {/* <Form.Item> */}
              <Col>
                <Tabs defaultActiveKey="1">
                  <TabPane tab="Product Information" key="1">
                    <div className="ant-row ant-form-item">
                      {getFieldDecorator('information_use', {
                        initialValue: detail.information_use || '',
                        valuePropName: 'checked',
                      })(
                        <Checkbox>
                          <strong>Use this section</strong>
                        </Checkbox>,
                      )}
                    </div>

                    <Form.Item label="Description (EN)">
                      {getFieldDecorator('information', {
                        initialValue: detail.information || '',
                      })(
                        <ReactQuill
                          ref={this.ref_editors.information.en}
                          id="product-information"
                          modules={this.modules}
                        />,
                      )}
                    </Form.Item>

                    <Form.Item label="Description (BM)">
                      {getFieldDecorator('information_kh', {
                        initialValue: detail.information_kh || '',
                      })(
                        <ReactQuill
                          ref={this.ref_editors.information.bm}
                          id="product-information-kh"
                          modules={this.modules}
                        />,
                      )}
                    </Form.Item>
                  </TabPane>
                  <TabPane tab="Preparation" key="2">
                    <div className="ant-row ant-form-item">
                      {getFieldDecorator('preparation_use', {
                        initialValue: detail.preparation_use || '',
                        valuePropName: 'checked',
                      })(
                        <Checkbox>
                          <strong>Use this section</strong>
                        </Checkbox>,
                      )}
                    </div>

                    <Form.Item label="Description (EN)">
                      {getFieldDecorator('preparation', {
                        initialValue: detail.preparation || '',
                      })(
                        <ReactQuill
                          ref={this.ref_editors.preparation.en}
                          id="product-preparation"
                          modules={this.modules}
                        />,
                      )}
                    </Form.Item>

                    <Form.Item label="Description (BM)">
                      {getFieldDecorator('preparation_kh', {
                        initialValue: detail.preparation_kh || '',
                      })(
                        <ReactQuill
                          ref={this.ref_editors.preparation.bm}
                          id="product-preparation-kh"
                          modules={this.modules}
                        />,
                      )}
                    </Form.Item>
                  </TabPane>
                  <TabPane tab="Nutrition Information" key="3">
                    <div className="ant-row ant-form-item">
                      {getFieldDecorator('nutrition_use', {
                        initialValue: detail.nutrition_use || '',
                        valuePropName: 'checked',
                      })(
                        <Checkbox>
                          <strong>Use this section</strong>
                        </Checkbox>,
                      )}
                    </div>

                    <Form.Item label="Description (EN)">
                      {getFieldDecorator('nutrition_info', {
                        initialValue: detail.nutrition_info || '',
                      })(
                        <ReactQuill
                          ref={this.ref_editors.nutrition.en}
                          id="product-nutrition_info"
                          modules={this.modules}
                        />,
                      )}
                    </Form.Item>

                    <Form.Item label="Description (BM)">
                      {getFieldDecorator('nutrition_info_kh', {
                        initialValue: detail.nutrition_info_kh,
                      })(
                        <ReactQuill
                          ref={this.ref_editors.nutrition.bm}
                          id="product-nutrition_info-kh"
                          modules={this.modules}
                        />,
                      )}
                    </Form.Item>
                  </TabPane>
                </Tabs>
              </Col>
              {/* </Form.Item> */}
            </Row>

            <Form.Item label="Publish Status">
              {getFieldDecorator('status', {
                validateTrigger: ['onChange', 'onBlur'],
                initialValue: detail.status || '1',
                rules: [
                  { required: true, message: 'Please input your status!' },
                ],
              })(
                <Radio.Group>
                  <Radio.Button value="1">
                    <i className="fa fa-check" style={{ marginRight: 5 }}></i>
                    Active
                  </Radio.Button>
                  <Radio.Button value="0">
                    <i className="fa fa-close" style={{ marginRight: 5 }}></i>
                    Inactive
                  </Radio.Button>
                </Radio.Group>,
              )}
            </Form.Item>

            <div
              style={{
                position: 'absolute',
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e8e8e8',
                padding: '10px 16px',
                left: 0,
                background: '#fff',
                borderRadius: '0 0 4px 4px',
                zIndex: 2,
              }}
            >
              <Row>
                <Col span={12}>
                  <Button
                    icon="close"
                    onClick={this.onClose}
                    style={{ backgroundColor: '#EDEDED' }}
                  >
                    Close
                  </Button>
                </Col>
                <Col span={12} className="text-right">
                  {detail.key && (
                    <Button
                      style={{
                        marginRight: 8,
                      }}
                      type="danger"
                      ghost
                      icon="delete"
                      onClick={this.showDeleteConfirm}
                      disabled={isSendingRequest}
                    >
                      Delete
                    </Button>
                  )}
                  <Button
                    htmlType="submit"
                    type="primary"
                    icon="save"
                    loading={isSendingRequest}
                    disabled={isSendingRequest}
                  >
                    {!detail.key ? 'Create' : 'Save'}
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        </Drawer>

        <Row>
          <Col span={24}>
            <Title level={1}>Products</Title>
          </Col>
        </Row>
        <div className="paper-panel">
          <Row>
            <Col span={12}>
              <Search
                placeholder="Product Name"
                onChange={({ target: { value } }) =>
                  this.handleSearchChange(value)
                }
                value={searchValue}
                style={{ width: 350 }}
              />
            </Col>
            <Col span={12} className="text-right">
              <Button
                type="primary"
                onClick={() => this.showDrawer(-1)}
                // style={{ minWidth: 140 }}
              >
                <Icon type="plus" />
                Create
              </Button>
            </Col>
          </Row>
          <Table
            columns={this.columns}
            dataSource={this.state.data}
            loading={this.state.loading}
            rowKey={'key'}
          />
        </div>
      </div>
    );
  }
}

export default Form.create({ name: 'product' })(App);
