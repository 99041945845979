import React from 'react';
import {
  Modal,
  Icon,
  Typography,
  Table,
  Button,
  Row,
  Select,
  Col,
  Input,
  Drawer,
  Form,
  Radio,
  notification,
} from 'antd';
import 'antd/dist/antd.css';
import './product_button.css';
import reqwest from 'reqwest';
import Dotdotdot from 'react-dotdotdot';
import moment from 'moment';

// const { Search } = Input;
const { Text, Title } = Typography;

var searchTimeoutId = false;

class App extends React.Component {
  state = {
    data: [],
    product_data: [],
    key: false,
    loading: false,
    visible: false,
    isSendingRequest: false,
    // searchValue: '',
    detail: { title: ['', ''] },
  };

  constructor(props) {
    super(props);

    this.columns = [
      {
        title: 'Product name',
        dataIndex: 'product_name',
        key: 'product_name',
        render: (data) => (
          <div>
            <Dotdotdot clamp={1}>
              <Text>{data ? data : '-'}</Text>
            </Dotdotdot>
          </div>
        ),
      },
      {
        title: 'Title EN',
        dataIndex: 'title_en',
        key: 'title_en',
        render: (data) => (
          <div>
            <Dotdotdot clamp={1}>
              <Text>{data}</Text>
            </Dotdotdot>
          </div>
        ),
      },
      {
        title: 'Title BM',
        dataIndex: 'title_bm',
        key: 'title_bm',
        render: (data) => (
          <div>
            <Dotdotdot clamp={1}>
              <Text>{data}</Text>
            </Dotdotdot>
          </div>
        ),
      },
      {
        title: 'Link',
        dataIndex: 'link',
        key: 'link',
        width: 100,
        align: 'center',
        render: (link) =>
          link != null && link.length > 0 ? (
            <i className="fa fa-check" style={{ fontSize: 15 }}></i>
          ) : (
            <i className="fa fa-times" style={{ fontSize: 15 }}></i>
          ),
      },
      {
        title: 'Published',
        dataIndex: 'status',
        key: 'published',
        width: 100,
        align: 'center',
        render: (status) =>
          '' + status === '1' ? (
            <i className="fa fa-check" style={{ fontSize: 15 }}></i>
          ) : (
            <i className="fa fa-times" style={{ fontSize: 15 }}></i>
          ),
      },
      {
        title: 'Updated Date',
        dataIndex: 'updated_time',
        key: 'updated_time',
        width: 200,
        render: (data) => (
          <div>
            <Text style={{ wordWrap: 'break-word' }}>
              {moment(data).format('DD/MM/YYYY HH:mm:ss')}
            </Text>
          </div>
        ),
      },
      {
        title: '',
        dataIndex: 'id',
        key: 'id',
        width: 50,
        render: (key) => (
          <Button
            onClick={() => {
              this.showDrawer(key);
            }}
            className="icon-btn"
          >
            <Icon type="setting" />
          </Button>
        ),
      },
    ];
  }

  componentDidMount() {
    document.title = 'Product Button';

    this.fetch();
    this.fetchProduct();
  }

  fetch = (params = {}) => {
    this.props.pageLoading(true);
    this.setState({ loading: true });

    reqwest({
      url: process.env.REACT_APP_API_URL + '/product_button',
      method: 'get',
      type: 'json',
      data: {
        // search: this.state.searchValue,
        ...params,
      },
    })
      .then((data) => {
        this.setState({
          loading: false,
          data: data.results,
        });

        document.body.style.overflow = null;
      })
      .catch((err) => {})
      .always(() => {
        this.props.pageLoading(false);
        this.setState({ loading: false });
      });
  };

  fetchProduct = (params = {}) => {
    this.props.pageLoading(true);
    this.setState({ loading: true });

    reqwest({
      url: process.env.REACT_APP_API_URL + '/product',
      method: 'get',
      type: 'json',
      data: {
        // search: this.state.searchValue,
        ...params,
      },
    })
      .then((data) => {
        this.setState({
          loading: false,
          product_data: data.results,
        });

        document.body.style.overflow = null;
      })
      .catch((err) => {})
      .always(() => {
        this.props.pageLoading(false);
        this.setState({ loading: false });
      });
  };

  saveRequest = (data = {}) => {
    return reqwest({
      url: process.env.REACT_APP_API_URL + '/product_button/save',
      method: 'post',
      type: 'json',
      data,
    })
      .then(() => {
        notification.success({
          key: 'notification',
          message: 'Success',
          description: `${data.id ? 'Update' : 'Create'} complete!`,
          duration: 3,
        });

        this.setState({ visible: false });

        this.fetch();
      })
      .catch((err) => {
        notification.error({
          key: 'notification',
          message: 'Fail',
          description: err.message,
          duration: 5,
        });
      })
      .always(() => {
        this.setState({ isSendingRequest: false });
      });
  };

  deleteRequest = (data = {}) => {
    var detail = this.state.detail;

    return reqwest({
      url: process.env.REACT_APP_API_URL + '/product_button/delete',
      method: 'post',
      type: 'json',
      data: {
        id: detail.key,
      },
    })
      .then(() => {
        notification.success({
          key: 'notification',
          message: 'Success',
          description: 'Delete complete!',
          duration: 3,
        });

        this.setState({ visible: false });

        this.fetch();
      })
      .catch((err) => {
        let message = err.message;

        if (err.responseText) {
          let jsonResponse = JSON.parse(err.responseText);
          message = jsonResponse.message;
        }

        notification.error({
          key: 'notification',
          message: 'Fail',
          description: message,
          duration: 5,
        });
      })
      .always(() => {
        this.setState({ isSendingRequest: false });
      });
  };

  getIndexInParent = (el) => {
    return Array.from(el.parentNode.children).indexOf(el);
  };

  showDrawer = async (key) => {
    let data = {};

    if (key === -1) {
      data = {
        key: undefined,
        id: undefined,
        product_id: undefined,
        title_en: '',
        title_bm: '',
        link: '',
        status: '1',
      };
    } else {
      data = this.state.data.find((item) => item.id === key);
    }

    data.status = '' + data.status;

    this.props.form.setFieldsValue({
      id: data.id,
      product_id: data.product_id,
      title_en: data.title_en,
      title_bm: data.title_bm,
      link: data.link,
      status: data.status,
    });

    this.setState({
      visible: true,
      detail: data,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  onSave = (e) => {
    e.preventDefault();

    this.setState({ isSendingRequest: true });

    this.props.form.validateFields((err, values) => {
      if (err) {
        notification.error({
          key: 'notification',
          message: 'Fail',
          description: 'Please fill complete form!',
          duration: 3,
        });

        this.setState({ isSendingRequest: false });

        return;
      }

      this.saveRequest(values);
    });
  };

  handleSearchChange(value) {
    this.setState({ searchValue: value });
    if (searchTimeoutId) {
      window.clearTimeout(searchTimeoutId);
    }
    searchTimeoutId = window.setTimeout(() => {
      this.fetch();
    }, 1500);
  }

  showDeleteConfirm = () => {
    Modal.confirm({
      title: 'Confirm',
      // icon: <ExclamationCircleOutlined />,
      content: 'Do you want to delete this item?',
      okText: 'Yes, Delete',
      cancelText: 'No',
      okType: 'danger',
      onOk: (e) => {
        var detail = this.state.detail;

        return this.deleteRequest({ id: detail.key });
      },
      onCancel: (e) => {
        Modal.destroyAll();
      },
    });
  };

  showDeleteModal = () => {
    this.setState({
      deleteModalVisible: true,
    });
  };

  handleDeleteModalCancel = () => {
    this.setState({ deleteModalVisible: false });
  };

  handleDeleteModalOk = () => {
    var detail = this.state.detail;
    this.setState({ deleteLoading: true });

    reqwest({
      url: process.env.REACT_APP_API + '/product_button/delete',
      method: 'post',
      data: {
        id: detail.id,
      },
      type: 'json',
    }).then(() => {
      this.setState({
        deleteLoading: false,
        deleteModalVisible: false,
        visible: false,
      });
      this.fetch();
    });
  };

  render() {
    const { isSendingRequest, detail, product_data } = this.state;
    const { getFieldDecorator } = this.props.form;

    return (
      <div id="product-button-component">
        <Drawer
          id="product-button-component-drawer"
          title="PRODUCT BUTTON DETAIL"
          placement="right"
          closable={false}
          width={730}
          onClose={this.onClose}
          visible={this.state.visible}
          bodyStyle={{ paddingBottom: 80 }}
        >
          <Form onSubmit={this.onSave} layout={'vertical'}>
            <Form.Item label="" style={{ display: 'none' }}>
              {getFieldDecorator('id', {
                initialValue: '',
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Product">
              {getFieldDecorator('product_id', {
                initialValue: detail.product_id || '',
                rules: [
                  {
                    required: true,
                    message: 'Please select Product!',
                  },
                ],
              })(
                <Select
                  style={{ width: '100%', marginRight: 8 }}
                  placeholder="Please select"
                >
                  <Select.Option value="">- Select product -</Select.Option>
                  {product_data !== undefined &&
                    product_data.length > 0 &&
                    product_data.map((value) => (
                      <Select.Option key={value.id} value={value.id}>
                        {value.name}
                      </Select.Option>
                    ))}
                </Select>,
              )}
            </Form.Item>
            <Form.Item label="Title EN">
              {getFieldDecorator('title_en', {
                initialValue: detail.title_en || '',
                rules: [{ required: true, message: 'Please input Title EN!' }],
              })(<Input placeholder="" maxLength={255} />)}
            </Form.Item>
            <Form.Item label="Title BM">
              {getFieldDecorator('title_bm', {
                initialValue: detail.title_bm || '',
                rules: [{ required: true, message: 'Please input Title BM!' }],
              })(<Input placeholder="" maxLength={255} />)}
            </Form.Item>
            <Form.Item label="Link">
              {getFieldDecorator('link', {
                initialValue: detail.link || '',
                rules: [
                  {
                    required: false,
                    message: 'Please input Product button link',
                  },
                  {
                    type: 'url',
                    message: 'This field must be a valid url.',
                  },
                ],
              })(<Input placeholder="" maxLength={255} />)}
            </Form.Item>
            <Form.Item label="Publish Status*">
              {getFieldDecorator('status', {
                validateTrigger: ['onChange', 'onBlur'],
                initialValue: '1',
                rules: [
                  { required: true, message: 'Please input your status!' },
                ],
              })(
                <Radio.Group>
                  <Radio.Button value="1">
                    <i className="fa fa-check" style={{ marginRight: 5 }}></i>
                    Active
                  </Radio.Button>
                  <Radio.Button value="0">
                    <i className="fa fa-close" style={{ marginRight: 5 }}></i>
                    Inactive
                  </Radio.Button>
                </Radio.Group>,
              )}
            </Form.Item>

            <div
              style={{
                position: 'absolute',
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e8e8e8',
                padding: '10px 16px',
                left: 0,
                background: '#fff',
                borderRadius: '0 0 4px 4px',
                zIndex: 2,
              }}
            >
              <Row>
                <Col span={12}>
                  <Button
                    icon="close"
                    onClick={this.onClose}
                    style={{ backgroundColor: '#EDEDED' }}
                  >
                    Close
                  </Button>
                </Col>
                <Col span={12} className="text-right">
                  {detail.key && (
                    <Button
                      style={{
                        marginRight: 8,
                      }}
                      type="danger"
                      ghost
                      icon="delete"
                      onClick={this.showDeleteConfirm}
                      disabled={isSendingRequest}
                    >
                      Delete
                    </Button>
                  )}
                  <Button
                    htmlType="submit"
                    type="primary"
                    icon="save"
                    loading={isSendingRequest}
                    disabled={isSendingRequest}
                  >
                    {!detail.key ? 'Create' : 'Save'}
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        </Drawer>

        <Row>
          <Col span={24}>
            <Title level={1}>Product Button</Title>
          </Col>
        </Row>
        <div className="paper-panel">
          <Row>
            <Col span={12}>
              {/* <Search
                placeholder="Product Name"
                onChange={({ target: { value } }) =>
                  this.handleSearchChange(value)
                }
                value={searchValue}
                style={{ width: 350 }}
              /> */}
            </Col>
            <Col span={12} className="text-right">
              <Button
                type="primary"
                onClick={() => this.showDrawer(-1)}
                // style={{ minWidth: 140 }}
              >
                <Icon type="plus" />
                Create
              </Button>
            </Col>
          </Row>
          <Table
            columns={this.columns}
            dataSource={this.state.data}
            loading={this.state.loading}
            rowKey={'key'}
          />
        </div>
      </div>
    );
  }
}

export default Form.create({ name: 'product_button' })(App);
