import React from 'react';
import {
  Radio,
  message,
  Modal,
  Upload,
  InputNumber,
  Drawer,
  Form,
  Icon,
  Typography,
  Table,
  Button,
  Row,
  Col,
  Input,
} from 'antd';
import 'antd/dist/antd.css';
import './testimonials.css';
import reqwest from 'reqwest';
import Dotdotdot from 'react-dotdotdot';

import dragula from 'dragula';
import 'dragula/dist/dragula.css';

const path = require('path');
const image2base64 = require('image-to-base64');
const { Search, TextArea } = Input;
const { Text, Title } = Typography;

let searchTimeoutId = false;

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}

class App extends React.Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        title: 'Sort',
        dataIndex: 'display_index',
        key: 'display_index',
        render: () => (
          <Icon
            type="menu"
            className="draggable"
            style={{
              fontSize: '13pt',
              fontWeight: 'bolder',
              cursor: 'pointer',
            }}
          />
        ),
      },
      {
        title: '',
        dataIndex: 'image',
        key: 'image',
        width: 80,
        render: (image) => (
          <img
            width="80"
            style={{ minWidth: '80px', maxWidth: '80px' }}
            src={image}
            alt=""
          />
        ),
      },
      {
        title: 'Message',
        dataIndex: 'testimonail',
        key: 'message',
        render: (data) => (
          <div>
            <Dotdotdot clamp={1}>
              <Text style={{ wordWrap: 'break-word' }}>{data[0]}</Text>
            </Dotdotdot>
          </div>
        ),
      },
      {
        title: 'Customer',
        dataIndex: 'customer_name',
        key: 'customer',
        render: (data) => (
          <div>
            <Text>{data[0]}</Text>
            <br />
            <Text>{data[1]}</Text>
          </div>
        ),
      },
      {
        title: 'Child',
        dataIndex: 'child',
        key: 'child',
        render: (data, row) => (
          <div>
            <Text>{data + ' Age ' + row.child_age}</Text>
          </div>
        ),
      },
      {
        title: 'Published',
        dataIndex: 'status',
        key: 'active',
        width: 100,
        align: 'center',
        render: (status) =>
          status === '1' ? (
            <i className="fa fa-check" style={{ fontSize: 15 }}></i>
          ) : (
            <i className="fa fa-times" style={{ fontSize: 15 }}></i>
          ),
      },
      {
        title: '',
        dataIndex: 'key',
        key: 'key',
        width: 70,
        render: (key) => (
          <Button
            onClick={() => {
              this.showDrawer(key);
            }}
            className="icon-btn"
          >
            <Icon type="edit" />
          </Button>
        ),
      },
    ];
  }

  state = {
    data: [],
    pagination: { pageSize: 10 },
    loading: false,
    visible: false,
    deleteModalVisible: false,
    deleteLoading: false,
    deleteButton: false,
    saveButton: false,
    imageUrl: false,
    imageBase64: '',
    searchValue: '',
    detail: {
      id: '',
      testimonail: '',
      status: '1',
      image: false,
      customer_name: '',
      child: 'son',
      child_age: '',
    },
  };

  showDrawer = async (key) => {
    const data = {};

    if (key === -1) {
      Object.assign(data, {
        title: ['', ''],
        status: '1',
        image: false,
      });

      this.setState({
        imageUrl: null,
      });
    } else {
      Object.assign(
        data,
        this.state.data.find((item) => item.key === key) || {},
      );
    }

    this.props.form.setFieldsValue({
      id: data.key,
      testimonail_kh: data.testimonail_kh,
      testimonail_en: data.testimonail_en,
      customer_name_kh: data.customer_name_kh,
      customer_name_en: data.customer_name_en,
      child: data.child,
      child_age: data.child_age,
      status: data.status,
      image: false,
    });

    this.setState({
      visible: true,
      detail: data,
      deleteButton: key === -1 ? false : true,
      saveButton: true,
    });

    image2base64(data.image) // you can also to use url
      .then((response) => {
        let b64 = 'data:image/';
        let ext = path.extname(data.image);
        if (ext === '.jpg' || ext === '.jpeg') {
          b64 += 'jpeg;base64,' + response;
        } else if (ext === '.png') {
          b64 += 'png;base64,' + response;
        } else {
          b64 = false;
        }
        if (b64 !== 'data:image/') {
          this.props.form.setFieldsValue({
            image: b64,
          });

          this.setState({
            imageUrl: b64,
          });
        }
      })
      .catch((error) => {});
  };

  onSave = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ saveButton: false });
        reqwest({
          url: process.env.REACT_APP_API_URL + '/testimonails/save',
          method: 'post',
          data: values,
          type: 'json',
        }).then((data) => {
          if (data.success) {
            this.setState({ visible: false });
            this.fetch();
          } else {
            this.setState({ saveButton: true });
          }
        });
      }
    });
  };

  handleChange = (info) => {
    if (info.file.status === 'uploading') {
      this.props.pageLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl) => {
        this.props.form.setFieldsValue({
          image: imageUrl,
        });
        this.setState({
          imageUrl,
        });
        this.props.pageLoading(false);
      });
    }
  };

  showDeleteModal = () => {
    this.setState({
      deleteModalVisible: true,
    });
  };

  handleDeleteModalOk = () => {
    const detail = this.state.detail;
    this.setState({ deleteLoading: true });

    reqwest({
      url: process.env.REACT_APP_API_URL + '/testimonails/delete',
      method: 'post',
      data: {
        id: detail.key,
      },
      type: 'json',
    }).then(() => {
      this.setState({
        deleteLoading: false,
        deleteModalVisible: false,
        visible: false,
      });
      this.fetch();
    });
  };

  handleDeleteModalCancel = () => {
    this.setState({ deleteModalVisible: false });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  componentDidMount() {
    document.title = 'Testimonial';
    this.fetch();

    const container = document.querySelector('.ant-table-tbody');
    const drake = dragula([container], {
      moves: (el) => {
        this.start = this.getIndexInParent(el);
        return true;
      },
    });

    drake.on('drop', (el) => {
      this.end = this.getIndexInParent(el);
      this.handleReorder(this.start, this.end);
    });
  }

  getIndexInParent = (el) => {
    return Array.from(el.parentNode.children).indexOf(el);
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });
    this.fetch({
      size: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters,
    });
  };

  handleReorder = (dragIndex, draggedIndex) => {
    const data = [...this.state.data];
    const item = data.splice(dragIndex, 1)[0];
    data.splice(draggedIndex, 0, item);
    this.setState({
      data,
    });

    const postBody = [];
    for (let i = 0; i < data.length; i++) {
      postBody.push({
        key: data[i].key,
        display_order: i + 1,
      });
    }

    reqwest({
      url: process.env.REACT_APP_API_URL + '/testimonails/reorder',
      method: 'post',
      data: {
        v: postBody,
      },
      type: 'json',
    }).then(() => {});
  };

  fetch = (params = {}) => {
    this.props.pageLoading(true);
    reqwest({
      url: process.env.REACT_APP_API_URL + '/testimonails',
      method: 'get',
      data: {
        size: this.state.pagination.pageSize,
        search: this.state.searchValue,
        ...params,
      },
      type: 'json',
    }).then((data) => {
      this.setState({
        data: data.results,
      });
      this.props.pageLoading(false);

      document.body.style.overflow = null;
    });
  };

  handleSearchChange(value) {
    this.setState({ searchValue: value });
    if (searchTimeoutId) {
      window.clearTimeout(searchTimeoutId);
    }
    searchTimeoutId = window.setTimeout(() => {
      this.fetch();
    }, 1500);
  }

  render() {
    const {
      saveButton,
      deleteButton,
      deleteModalVisible,
      deleteLoading,
      imageUrl,
      searchValue,
      detail,
    } = this.state;
    const { getFieldDecorator } = this.props.form;
    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? 'loading' : 'plus'} />
        <div className="ant-upload-text">Upload</div>
      </div>
    );

    return (
      <div id="testimonial-component">
        <Row>
          <Col span={24}>
            <Title level={1}>Testimonials</Title>
          </Col>
        </Row>
        <div className="paper-panel">
          <Row>
            <Col span={12}>
              <Search
                placeholder="Message / Customer"
                onChange={({ target: { value } }) =>
                  this.handleSearchChange(value)
                }
                style={{ width: 350 }}
                value={searchValue}
              />
            </Col>
            <Col span={12} className="text-right">
              <Button
                type="primary"
                style={{ minWidth: 140 }}
                onClick={() => this.showDrawer(-1)}
              >
                <Icon type="plus" />
                Create
              </Button>
            </Col>
          </Row>

          <Table
            columns={this.columns}
            dataSource={this.state.data}
            pagination={this.state.pagination}
            loading={this.state.loading}
            onChange={this.handleTableChange}
            rowKey={'key'}
          />

          <Drawer
            id="testimonial-component-drawer"
            title="Testimonial"
            width={380}
            closable={false}
            onClose={this.onClose}
            headerStyle={{ textTransform: 'uppercase', fontWeight: 'bold' }}
            visible={this.state.visible}
            bodyStyle={{ paddingBottom: 120 }}
          >
            <Form onSubmit={this.onSave} layout="vertical" hideRequiredMark>
              <Form.Item label="" style={{ display: 'none' }}>
                {getFieldDecorator('id', {
                  initialValue: detail.id,
                })(<Input />)}
              </Form.Item>
              <Form.Item>
                <Upload
                  name="photo"
                  listType="picture-card"
                  className="photo-uploader"
                  showUploadList={false}
                  action={process.env.REACT_APP_API_URL + '/image/test/upload'}
                  beforeUpload={beforeUpload}
                  onChange={this.handleChange}
                >
                  {imageUrl ? (
                    <img
                      src={imageUrl}
                      alt="avatar"
                      style={{ width: '100%' }}
                    />
                  ) : (
                    uploadButton
                  )}
                </Upload>
                <div className="text-center">
                  <Text className="">300 x 200</Text>
                </div>
                {getFieldDecorator('image')(<Input type="hidden" />)}
              </Form.Item>
              <Form.Item label="Testimonial BM" colon={false}>
                {getFieldDecorator('testimonail_kh', {
                  initialValue: detail.testimonail_th,
                  validateTrigger: ['onChange', 'onBlur'],
                  rules: [
                    {
                      required: true,
                      message: 'Please enter text',
                    },
                  ],
                })(<TextArea rows={2} required={true}></TextArea>)}
                <Text>(Below 70 charactor or 2 line on text box)</Text>
              </Form.Item>
              <Form.Item label="Testimonial EN" colon={false}>
                {getFieldDecorator('testimonail_en', {
                  initialValue: detail.testimonail_en,
                  validateTrigger: ['onChange', 'onBlur'],
                  rules: [
                    {
                      required: true,
                      message: 'Please enter text',
                    },
                  ],
                })(<TextArea rows={2} required={true}></TextArea>)}
                <Text>(Below 70 charactor or 2 line on text box)</Text>
              </Form.Item>
              <Form.Item label="Customer Name BM*">
                {getFieldDecorator('customer_name_kh', {
                  initialValue: detail.customer_name_th,
                  rules: [
                    {
                      required: true,
                      message: 'Please enter customer name',
                    },
                  ],
                })(<Input placeholder="" />)}
              </Form.Item>
              <Form.Item label="Customer Name EN*">
                {getFieldDecorator('customer_name_en', {
                  initialValue: detail.customer_name_en,
                  rules: [
                    {
                      required: true,
                      message: 'Please enter customer name',
                    },
                  ],
                })(<Input placeholder="" />)}
              </Form.Item>
              <Form.Item label="Child*">
                {getFieldDecorator('child', {
                  validateTrigger: ['onChange', 'onBlur'],
                  initialValue: detail.child,
                  rules: [
                    { required: true, message: 'Please choose child type.!' },
                  ],
                })(
                  <Radio.Group>
                    <Radio.Button value="Son">Son</Radio.Button>
                    <Radio.Button value="Daughter">Daughter</Radio.Button>
                  </Radio.Group>,
                )}
              </Form.Item>
              <Form.Item label="Child Age*">
                {getFieldDecorator('child_age', {
                  initialValue: detail.child_age,
                  rules: [
                    {
                      required: true,
                      message: 'Please enter child age',
                    },
                  ],
                })(<InputNumber placeholder="" />)}
              </Form.Item>
              <Form.Item label="Publish Status*">
                {getFieldDecorator('status', {
                  initialValue: detail.status,
                  rules: [{ required: true, message: 'Please choose status!' }],
                })(
                  <Radio.Group>
                    <Radio.Button value="1">
                      <i className="fa fa-check" style={{ marginRight: 5 }}></i>
                      Active
                    </Radio.Button>
                    <Radio.Button value="0">
                      <i className="fa fa-close" style={{ marginRight: 5 }}></i>
                      Inactive
                    </Radio.Button>
                  </Radio.Group>,
                )}
              </Form.Item>

              <div
                style={{
                  position: 'absolute',
                  right: 0,
                  bottom: 0,
                  width: '100%',
                  textAlign: 'left',
                  zIndex: 2,
                }}
              >
                <div
                  className="text-right"
                  style={{
                    paddingBottom: 10,
                    paddingRight: 16,
                    paddingLeft: 16,
                    paddingTop: 16,
                    background: 'rgba(255, 255, 255, 0.75)',
                  }}
                >
                  <Text className="">Please complete all required field*</Text>
                </div>
                <Row
                  style={{
                    width: '100%',
                    background: '#fff',
                    padding: '10px 16px',
                    borderTop: '1px solid #e9e9e9',
                  }}
                >
                  <Col span={12}>
                    <Button
                      onClick={this.onClose}
                      style={{ marginRight: 8 }}
                      className="close-btn"
                    >
                      Close
                    </Button>
                  </Col>
                  <Col span={12} className="text-right">
                    <Button
                      disabled={deleteButton ? false : true}
                      onClick={this.showDeleteModal}
                      style={{ marginRight: 8 }}
                      className="secondary-action-btn"
                    >
                      <i className="fa fa-close" style={{ marginRight: 5 }}></i>
                      Delete
                    </Button>
                    <Button
                      disabled={saveButton ? false : true}
                      type="primary"
                      htmlType="submit"
                      className="primary-action-btn"
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </div>
            </Form>
          </Drawer>
        </div>

        <Modal
          visible={deleteModalVisible}
          width={400}
          closable={false}
          footer={[
            <Button
              key="submit"
              loading={deleteLoading}
              onClick={this.handleDeleteModalOk}
            >
              Yes, Delete
            </Button>,
            <Button
              key="back"
              type="primary"
              onClick={this.handleDeleteModalCancel}
              style={{ minWidth: 120 }}
            >
              No
            </Button>,
          ]}
        >
          <div className="text-center">
            <p>Do you want to delete this item?</p>
          </div>
        </Modal>
      </div>
    );
  }
}

export default Form.create({ name: 'testimonail' })(App);
