import React from 'react';
import PropTypes from 'prop-types';
import reactCSS from 'reactcss';
import { TwitterPicker } from 'react-color';

const config = {
  width: '420px',
  colors: [
    '#000000',
    '#4A4A4A',
    '#9B9B9B',
    '#FFFFFF',
    '#D0021B',
    '#F5A623',
    '#F8E71C',
    '#8B572A',
    '#7ED321',
    '#417505',
    '#BD10E0',
    '#9013FE',
    '#4A90E2',
    '#50E3C2',
    '#B8E986',
    '#EB144C',
    '#F78DA7',
  ],
};

class ColorPicker extends React.Component {
  state = {
    displayColorPicker: false,
    color: {
      r: '241',
      g: '112',
      b: '19',
      a: '1',
    },
  };

  constructor(props) {
    super(props);

    // initially set loading to true and current src of image to placeholder image
    if (props.color) {
      this.state = {
        color: { ...this.hexToRgb(props.color), a: '1' },
      };
    } else {
      this.state = {
        color: { r: '0', g: '0', b: '0', a: '1' },
      };
    }
  }

  hexToRgb = (hex) => {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
      return r + r + g + g + b + b;
    });

    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  };

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  handleChange = (color) => {
    this.setState({ color: color.rgb });
    this.setState({ displayColorPicker: false });
    if (this.props.onChange && typeof this.props.onChange === 'function') {
      this.props.onChange(color);
    }
  };

  render() {
    const styles = reactCSS({
      default: {
        swatch: {
          padding: '5px',
          background: '#fff',
          borderRadius: '4px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
        },
        color: {
          width: '50px',
          height: '22px',
          borderRadius: '4px',
          background: `rgba(${this.state.color.r}, ${this.state.color.g}, ${this.state.color.b}, ${this.state.color.a})`,
        },
        popover: {
          position: 'absolute',
          zIndex: '2',
          // marginTop: '-38px',
          // marginLeft: '70px',
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
      },
    });

    return (
      <div style={{ position: 'relative' }}>
        <div style={styles.swatch} onClick={this.handleClick}>
          <div style={styles.color} />
        </div>
        {this.state.displayColorPicker ? (
          <div style={styles.popover}>
            <div style={styles.cover} onClick={this.handleClose} />
            <TwitterPicker
              color={this.state.color}
              onChange={this.handleChange}
              triangle="top-left"
              colors={config.colors}
              width={config.width}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

ColorPicker.propTypes = {
  color: PropTypes.string,
  onChange: PropTypes.func,
};
export default ColorPicker;
