import React from 'react';
import {
  Drawer,
  Modal,
  Form,
  Radio,
  message,
  Upload,
  Select,
  Icon,
  Typography,
  Table,
  Button,
  Row,
  Col,
  Input,
  notification,
} from 'antd';
import 'antd/dist/antd.css';
import './article.css';
import reqwest from 'reqwest';

import ReactQuill, { Quill } from 'react-quill';
import { getArticleURL, isEmpty } from './util/var';
import 'react-quill/dist/quill.snow.css';

import NumberFormat from 'react-number-format';
const imageDataURI = require('image-data-uri');
const { Search, TextArea } = Input;
const { Text, Title } = Typography;

const Link = Quill.import('formats/link');
Quill.debug(false);
class MyLink extends Link {
  static create(value) {
    let node = super.create(value);
    value = this.sanitize(value);
    if (!value.startsWith('http')) {
      value = 'http://' + value;
    }
    node.setAttribute('href', value);

    return node;
  }
}
Quill.register(MyLink);
const Size = ReactQuill.Quill.import('attributors/style/size');
Size.whitelist = ['13px', '14px', '16px', '18px', '20px', '32px', '72px'];
ReactQuill.Quill.register(Size, true);

let searchTimeoutId = false;
function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeCoverUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}

function beforeThumbnailUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}

function validateImage(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}

function uploadImage(file, cb) {
  let reader = new FileReader();
  reader.readAsDataURL(file);

  reader.onload = function () {
    const b64 = reader.result;
    reqwest({
      url: process.env.REACT_APP_API_URL + '/image/save',
      method: 'post',
      data: {
        image: b64,
      },
      type: 'json',
    }).then((data) => {
      cb(data.src);
    });
  };

  reader.onerror = function (error) {};

  return false;
}

class App extends React.Component {
  state = {
    data: [],
    detail: {
      id: '',
      title_en: '',
      title_kh: '',
      short_description_en: '',
      short_description_kh: '',
      detail_en: '',
      detail_kh: '',
      detail_footer_en: '',
      detail_footer_kh: '',
      cover_photo: '',
      thumbnail_photo: '',
      status: 1,
      cl_content_en: '',
      cl_content_mm: '',
      cl_button_link_en: '',
      cl_button_link_mm: '',
      cl_button_name_en: '',
      cl_button_name_mm: '',
      cl_status: 1,
      category: '',
      topic: '',
    },
    description: '',
    pagination: {},
    loading: false,
    visible: false,
    isSendingRequest: false,
    imageCoverUrl: false,
    imageThumbnailUrl: false,
    searchValue: '',
    searchCategory: '',
    searchTopic: '',
    category: [],
    topic: [],
    allTopic: [],
    showTopic: [],
  };

  constructor(props) {
    super(props);

    this.columns = [
      {
        title: '',
        dataIndex: 'thumbnail_photo',
        key: 'image',
        width: 100,
        render: (image) => <img width="100%" src={image} alt="Thumbnail" />,
      },
      {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        render: (data) => (
          <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
            <Text>{data[0]}</Text>
            <br />
            <Text>{data[1]}</Text>
          </div>
        ),
      },
      {
        title: 'Category',
        dataIndex: 'category_name',
        key: 'category',
        width: 100,
        render: (data) => <Text>{data[1]}</Text>,
      },
      {
        title: 'Topic',
        dataIndex: 'topic_name',
        key: 'topic',
        width: 130,
        render: (data) => <Text>{data[1]}</Text>,
      },
      {
        title: 'View (Unique)',
        dataIndex: 'unique_view',
        key: 'unique_view',
        align: 'center',
        width: 150,
        render: (data) => (
          <Text>
            <NumberFormat
              value={data}
              displayType={'text'}
              thousandSeparator={true}
            />
          </Text>
        ),
      },
      {
        title: 'Published',
        dataIndex: 'status',
        key: 'published',
        align: 'center',
        width: 110,
        render: (status) => (
          <i
            className={`fa ${status === '1' ? 'fa-check' : 'fa-times'}`}
            style={{ fontSize: 15 }}
          />
        ),
      },
      {
        title: '',
        dataIndex: 'key',
        key: 'key',
        width: 70,
        render: (key) => (
          <Button
            onClick={() => {
              this.showDrawer(key);
            }}
            className="icon-btn"
          >
            <Icon type="edit" />
          </Button>
        ),
      },
    ];
  }

  componentDidMount() {
    document.title = 'Article';

    this.fetch();
    this.fetchInit();
  }

  fetch = (params = {}) => {
    this.props.pageLoading(true);

    reqwest({
      url: process.env.REACT_APP_API_URL + '/article',
      method: 'get',
      type: 'json',
      data: {
        search: this.state.searchValue,
        category: this.state.searchCategory,
        topic: this.state.searchTopic,
        ...params,
      },
    })
      .then((data) => {
        this.setState({
          data: data.results,
        });

        document.body.style.overflow = null;
      })
      .catch((err) => {})
      .always(() => {
        this.props.pageLoading(false);
      });
  };

  fetchInit = () => {
    reqwest({
      url: process.env.REACT_APP_API_URL + '/article/init',
      method: 'get',
      type: 'json',
    })
      .then((data) => {
        this.setState({
          allTopic: (data.results ? data.results.topic : undefined) || [],
          category: (data.results ? data.results.category : undefined) || [],
        });
      })
      .catch((err) => {});
  };

  saveRequest = (data = {}) => {
    return reqwest({
      url: process.env.REACT_APP_API_URL + '/article/save',
      method: 'post',
      type: 'json',
      data,
    })
      .then(() => {
        notification.success({
          key: 'notification',
          message: 'Success',
          description: `${data.id ? 'Update' : 'Create'} complete!`,
          duration: 3,
        });

        this.setState({ visible: false });

        this.fetch();
      })
      .catch((err) => {
        let message = err.message;

        if (err.responseText) {
          let jsonResponse = JSON.parse(err.responseText);
          message = jsonResponse.message;
        }

        notification.error({
          key: 'notification',
          message: 'Fail',
          description: message,
          duration: 5,
        });
      })
      .always(() => {
        this.setState({ isSendingRequest: false });
      });
  };

  deleteRequest = (data = {}) => {
    return reqwest({
      url: process.env.REACT_APP_API_URL + '/article/delete',
      method: 'post',
      type: 'json',
      data,
    })
      .then(() => {
        notification.success({
          key: 'notification',
          message: 'Success',
          description: 'Delete complete!',
          duration: 3,
        });

        this.setState({ visible: false });

        this.fetch();
      })
      .catch((err) => {
        let message = err.message;

        if (err.responseText) {
          let jsonResponse = JSON.parse(err.responseText);
          message = jsonResponse.message;
        }

        notification.error({
          key: 'notification',
          message: 'Fail',
          description: message,
          duration: 5,
        });
      })
      .always(() => {
        this.setState({ isSendingRequest: false });
      });
  };

  handleSearchChange(value) {
    this.setState({ searchValue: value });
    if (searchTimeoutId) {
      window.clearTimeout(searchTimeoutId);
    }
    searchTimeoutId = window.setTimeout(() => {
      this.fetch();
    }, 1500);
  }

  showDrawer = async (key) => {
    // this.props.pageLoading(true);
    const data = {
      key: undefined,
      category: '',
      topic: '',
      title_en: '',
      title_kh: '',
      short_description_en: '',
      short_description_kh: '',
      detail_en: '',
      detail_kh: '',
      detail_footer_en: '',
      detail_footer_kh: '',
      text_bm: '',
      link_to_bm: '',
      text_en: '',
      link_to_en: '',
      cover_photo: undefined,
      thumbnail_photo: undefined,
      status: '1',
      cl_content_en: '',
      cl_content_mm: '',
      cl_button_link_en: '',
      cl_button_link_mm: '',
      cl_button_name_en: '',
      cl_button_name_mm: '',
      cl_status: '1',
    };

    if ((parseInt(key, 10) || 0) > 0) {
      Object.assign(
        data,
        this.state.data.find((item) => item.key === key) || {},
      );

      // Convert to string
      Object.assign(data, {
        category: `${data.category || ''}`,
        topic: `${data.topic || ''}`,
        title_en: data.title_en || '',
        title_kh: data.title_kh || '',
        short_description_en: data.short_description_en || '',
        short_description_kh: data.short_description_kh || '',
        detail_en: data.detail_en || '',
        detail_kh: data.detail_kh || '',
        detail_footer_en: data.detail_footer_en || '',
        detail_footer_kh: data.detail_footer_kh || '',
        text_bm: data.text_bm || '',
        link_to_bm: data.link_to_bm || '',
        text_en: data.text_en || '',
        link_to_en: data.link_to_en || '',
        status: `${data.status || 0}`,
        cl_content_en: data.cl_content_en || '',
        cl_content_mm: data.cl_content_mm || '',
        cl_button_link_en: data.cl_button_link_en || '',
        cl_button_link_mm: data.cl_button_link_mm || '',
        cl_button_name_en: data.cl_button_name_en || '',
        cl_button_name_mm: data.cl_button_name_mm || '',
        cl_status: `${data.cl_status || 0}`,
      });
    }

    this.populateTopic(data.category);

    this.props.form.setFieldsValue({
      id: data.key,
      category: data.category,
      topic: data.topic,
      title_en: data.title_en,
      title_kh: data.title_kh,
      short_description_en: data.short_description_en,
      short_description_kh: data.short_description_kh,
      detail_en: data.detail_en,
      detail_kh: data.detail_kh,
      detail_footer_en: data.detail_footer_en,
      detail_footer_kh: data.detail_footer_kh,
      text_bm: data.text_bm,
      link_to_bm: data.link_to_bm,
      text_en: data.text_en,
      link_to_en: data.link_to_en,
      cover_photo: data.cover_photo,
      thumbnail_photo: data.thumbnail_photo,
      status: data.status,
      cl_content_en: data.cl_content_en,
      cl_content_mm: data.cl_content_mm,
      cl_button_link_en: data.cl_button_link_en,
      cl_button_link_mm: data.cl_button_link_mm,
      cl_button_name_en: data.cl_button_name_en,
      cl_button_name_mm: data.cl_button_name_mm,
      cl_status: data.cl_status,
    });

    this.setState({
      detail: data,
      visible: true,
      isSendingRequest: undefined,
      imageCoverUrl: undefined,
      imageThumbnailUrl: undefined,
    });

    if (data.cover_photo) {
      imageDataURI
        .encodeFromURL(data.cover_photo)
        .then((base64) => {
          this.props.form.setFieldsValue({
            cover_photo: base64,
          });

          this.setState({
            imageCoverUrl: base64,
          });
        })
        .catch((err) => {
          // console.log({ err });
        });
    }
    if (data.thumbnail_photo) {
      imageDataURI
        .encodeFromURL(data.thumbnail_photo)
        .then((base64) => {
          this.props.form.setFieldsValue({
            thumbnail_photo: base64,
          });

          this.setState({
            imageThumbnailUrl: base64,
          });
        })
        .catch((err) => {
          // console.log({ err });
        });
    }
    // this.props.pageLoading(false);
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  handleCategoryChange = (value) => {
    this.setState({ searchCategory: value, topic: [], searchTopic: '' });
    if (searchTimeoutId) {
      window.clearTimeout(searchTimeoutId);
    }
    searchTimeoutId = window.setTimeout(() => {
      this.fetch();
    }, 1500);

    const topic = this.state.allTopic.filter(
      ({ article_category }) => article_category === value,
    );

    this.setState({
      topic,
    });
  };

  handleFormCategoryChange = (value) => {
    this.populateTopic(value);
  };

  populateTopic = (value) => {
    const showTopic = this.state.allTopic.filter(
      ({ article_category }) => article_category === value,
    );

    this.setState({
      showTopic,
    });

    this.props.form.setFieldsValue({
      topic: '',
    });
  };

  handleTopicChange = (value) => {
    this.setState({ searchTopic: value });
    if (searchTimeoutId) {
      window.clearTimeout(searchTimeoutId);
    }
    searchTimeoutId = window.setTimeout(() => {
      this.fetch();
    }, 1500);
  };

  showDeleteConfirm = () => {
    Modal.confirm({
      title: 'Confirm',
      // icon: <ExclamationCircleOutlined />,
      content: 'Do you want to delete this item?',
      okText: 'Yes, Delete',
      cancelText: 'No',
      okType: 'danger',
      onOk: () => {
        const detail = this.state.detail;

        return this.deleteRequest({ id: detail.key });
      },
      onCancel: () => {
        Modal.destroyAll();
      },
    });
  };

  onSave = (e) => {
    e.preventDefault();

    this.setState({ isSendingRequest: true });

    this.props.form.validateFields((err, values) => {
      if (err) {
        notification.error({
          key: 'notification',
          message: 'Fail',
          description: 'Please fill complete form!',
          duration: 3,
        });

        this.setState({ isSendingRequest: false });

        return;
      }

      this.saveRequest(values);
    });
  };

  handleCoverChange = (info) => {
    if (info.file.status === 'uploading') {
      this.props.pageLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageCoverUrl) => {
        this.props.form.setFieldsValue({
          cover_photo: imageCoverUrl,
        });
        this.setState({
          imageCoverUrl,
        });
        this.props.pageLoading(false);
      });
    }
  };

  handleThumbnailChange = (info) => {
    if (info.file.status === 'uploading') {
      this.props.pageLoading(true);
      return;
    }

    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageThumbnailUrl) => {
        this.props.form.setFieldsValue({
          thumbnail_photo: imageThumbnailUrl,
        });
        this.setState({
          imageThumbnailUrl,
        });
        this.props.pageLoading(false);
      });
    }
  };

  handleDetailChange = (value) => {
    // console.log(value);
    // this.setState({ detail: value })
  };

  modules = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        ['link', 'image', 'video'],

        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
        [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
        // [{ 'direction': 'rtl' }],                         // text direction

        // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ size: Size.whitelist }],

        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        // [{ 'font': Font.whitelist }],
        [{ align: [] }],

        ['clean'], // remove formatting button
      ],
      handlers: {
        image: this.imageHandler,
      },
    },
  };

  imageHandler() {
    const input = document.createElement('input');

    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      if (!validateImage(file)) {
        return false;
      }
      const formData = new FormData();

      formData.append('image', file);

      // Save current cursor state
      const range = this.quill.getSelection(true);

      // Insert temporary loading placeholder image
      // this.quill.insertEmbed(range.index, 'image', `${window.location.origin}/images/loaders/placeholder.gif`);

      // Move cursor to right side of image (easier to continue typing)
      this.quill.setSelection(range.index + 1);

      uploadImage(file, (cb) => {
        this.quill.insertEmbed(range.index, 'image', cb);
      });

      // Remove placeholder image
      // this.quill.deleteText(range.index, 1);

      // Insert uploaded image
      // this.quill.insertEmbed(range.index, 'image', res.body.image);
      // this.quill.insertEmbed(range.index, 'image', res);
    };
  }

  getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {};
  };

  render() {
    const {
      isSendingRequest,
      searchTopic,
      imageCoverUrl,
      imageThumbnailUrl,
      detail,
      topic,
      showTopic,
      category,
    } = this.state;

    const uploadCoverButton = (
      <div>
        <Icon type={this.state.loading ? 'loading' : 'plus'} />
        <div className="ant-upload-text">Upload</div>
      </div>
    );

    const uploadThumbnailButton = (
      <div>
        <Icon type={this.state.loading ? 'loading' : 'plus'} />
        <div className="ant-upload-text">Upload</div>
      </div>
    );

    const { getFieldDecorator } = this.props.form;

    return (
      <div id="article-component">
        <Drawer
          id="article-component-drawer"
          title="ARTICLE"
          placement="right"
          closable={false}
          width={730}
          onClose={this.onClose}
          visible={this.state.visible}
          bodyStyle={{ paddingBottom: 120 }}
        >
          <Form onSubmit={this.onSave} layout={'vertical'}>
            <Form.Item label="Title (BM)">
              {getFieldDecorator('title_kh', {
                initialValue: detail.title_kh,
                rules: [{ required: true, message: 'Please input Title!' }],
              })(<Input placeholder="" maxLength={55} />)}
            </Form.Item>
            <Form.Item label="Title (EN)">
              {getFieldDecorator('title_en', {
                initialValue: detail.title_en,
                rules: [{ required: true, message: 'Please input Title!' }],
              })(<Input placeholder="" maxLength={55} />)}
            </Form.Item>
            <Row gutter={[16, 0]}>
              <Col span={12}>
                <Form.Item style={{ display: 'none' }}>
                  {getFieldDecorator('id', {
                    initialValue: detail.id,
                  })(<Input />)}
                </Form.Item>
                <Form.Item label="Category">
                  {getFieldDecorator('category', {
                    validateTrigger: ['onChange', 'onBlur'],
                    initialValue: '',
                    rules: [
                      { required: true, message: 'Please choose category!' },
                    ],
                  })(
                    <Select
                      style={{ width: '100%', marginRight: 8 }}
                      onChange={this.handleFormCategoryChange}
                    >
                      <Select.Option key="" value="">
                        Please select
                      </Select.Option>
                      {category.map((value) => (
                        <Select.Option key={value.id} value={value.id}>
                          {value.name_en}
                        </Select.Option>
                      ))}
                    </Select>,
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Topic">
                  {getFieldDecorator('topic', {
                    validateTrigger: ['onChange', 'onBlur'],
                    initialValue: '',
                    rules: [
                      { required: true, message: 'Please choose topic!' },
                    ],
                  })(
                    <Select style={{ width: '100%' }}>
                      <Select.Option key="" value="">
                        Please select
                      </Select.Option>
                      {showTopic.map((value) => (
                        <Select.Option key={value.id} value={value.id}>
                          {value.name_en}
                        </Select.Option>
                      ))}
                    </Select>,
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Form.Item label="Short Description (BM)">
              {getFieldDecorator('short_description_kh', {
                initialValue: detail.short_description_kh,
                rules: [
                  {
                    required: true,
                    message: 'Please input short description!',
                  },
                ],
              })(<Input placeholder="" />)}
            </Form.Item>
            <Form.Item label="Short Description (EN)">
              {getFieldDecorator('short_description_en', {
                initialValue: detail.short_description_en,
                rules: [
                  {
                    required: true,
                    message: 'Please input short description!',
                  },
                ],
              })(<Input placeholder="" />)}
            </Form.Item>
            <Form.Item label="Detail (BM)">
              {getFieldDecorator('detail_kh', {
                initialValue: detail.detail_kh || '',
                rules: [{ required: true, message: 'Please input detail!' }],
              })(
                <ReactQuill
                  ref={(el) => {
                    this.quill = el;
                  }}
                  id="detail-kh"
                  onChange={this.handleDetailChange}
                  modules={this.modules}
                  height={300}
                />,
              )}
            </Form.Item>
            <Form.Item label="Detail (EN)">
              {getFieldDecorator('detail_en', {
                initialValue: detail.detail_en || '',
                rules: [{ required: true, message: 'Please input detail!' }],
              })(
                <ReactQuill
                  id="detail-en"
                  onChange={this.handleDetailChange}
                  modules={this.modules}
                  height={300}
                />,
              )}
            </Form.Item>
            <Form.Item label="Detail Footer (BM)">
              {getFieldDecorator('detail_footer_kh', {
                initialValue: detail.detail_footer_kh || '',
                rules: [{ required: true, message: 'Please input detail!' }],
              })(
                <ReactQuill
                  id="detail-footer-en"
                  onChange={this.handleDetailChange}
                  modules={this.modules}
                  height={300}
                />,
              )}
            </Form.Item>
            <Form.Item label="Detail Footer (EN)">
              {getFieldDecorator('detail_footer_en', {
                initialValue: detail.detail_footer_en || '',
                rules: [{ required: true, message: 'Please input detail!' }],
              })(
                <ReactQuill
                  id="detail-footer-en"
                  onChange={this.handleDetailChange}
                  modules={this.modules}
                  height={300}
                />,
              )}
            </Form.Item>
            <Row gutter={[16, 0]}>
              <Col span={12}>
                <Form.Item label="Cover Photo">
                  <Upload
                    name="cover_photo"
                    listType="picture-card"
                    className="photo-uploader"
                    showUploadList={false}
                    action={
                      process.env.REACT_APP_API_URL + '/image/test/upload'
                    }
                    beforeUpload={beforeCoverUpload}
                    onChange={this.handleCoverChange}
                  >
                    {imageCoverUrl ? (
                      <img
                        src={imageCoverUrl}
                        alt="Cover"
                        style={{ width: '100%' }}
                      />
                    ) : (
                      uploadCoverButton
                    )}
                  </Upload>
                  {getFieldDecorator('cover_photo', {
                    rules: [
                      {
                        required: true,
                        message: 'Please upload cover photo!',
                      },
                    ],
                  })(<Input type="hidden" />)}
                  <div className="text-center">
                    <Text>1200 x 900</Text>
                  </div>
                </Form.Item>
              </Col>
              <Col span={6} offset={3}>
                <Form.Item label="Thumbnail">
                  <Upload
                    name="thumbnail_photo"
                    listType="picture-card"
                    className="thumbnail-uploader"
                    showUploadList={false}
                    action={
                      process.env.REACT_APP_API_URL + '/image/test/upload'
                    }
                    beforeUpload={beforeThumbnailUpload}
                    onChange={this.handleThumbnailChange}
                  >
                    {imageThumbnailUrl ? (
                      <img
                        src={imageThumbnailUrl}
                        alt="Thumbnail"
                        style={{ width: '100%' }}
                      />
                    ) : (
                      uploadThumbnailButton
                    )}
                  </Upload>
                  {getFieldDecorator('thumbnail_photo', {
                    rules: [
                      {
                        required: true,
                        message: 'Please upload thumbnail!',
                      },
                    ],
                  })(<Input type="hidden" />)}
                  <div className="text-center">
                    <Text>300 x 200</Text>
                  </div>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item label="Text (BM)">
              {getFieldDecorator('text_bm', {
                initialValue: detail.title_kh,
                rules: [{ required: true, message: 'Please input Title!' }],
              })(<Input placeholder="" />)}
            </Form.Item>
            <Form.Item label="Link to (BM)">
              {getFieldDecorator('link_to_bm', {
                initialValue: detail.title_kh,
                rules: [{ required: true, message: 'Please input Title!' }],
              })(<Input placeholder="" />)}
            </Form.Item>
            <Form.Item label="Text (EN)">
              {getFieldDecorator('text_en', {
                initialValue: detail.title_kh,
                rules: [{ required: true, message: 'Please input Title!' }],
              })(<Input placeholder="" />)}
            </Form.Item>
            <Form.Item label="Link to (EN)">
              {getFieldDecorator('link_to_en', {
                initialValue: detail.title_kh,
                rules: [{ required: true, message: 'Please input Title!' }],
              })(<Input placeholder="" />)}
            </Form.Item>
            <Form.Item label="Preview URL">
              <a
                href={`${process.env.REACT_APP_WEBSITE_URL}${
                  isEmpty(detail) === true ? '' : getArticleURL(detail)
                }`}
                target="_blank"
                rel="noopener noreferrer"
                className="ant-btn ant-btn-block preview-url"
              >{`${process.env.REACT_APP_WEBSITE_URL}${
                isEmpty(detail) === true ? '' : getArticleURL(detail)
              }`}</a>
            </Form.Item>

            <Form.Item label="Publish Status">
              {getFieldDecorator('status', {
                initialValue: detail.status,
                rules: [{ required: true, message: 'Please choose status!' }],
              })(
                <Radio.Group>
                  <Radio.Button value="1">
                    <i className="fa fa-check" style={{ marginRight: 5 }}></i>
                    Active
                  </Radio.Button>
                  <Radio.Button value="0">
                    <i className="fa fa-close" style={{ marginRight: 5 }}></i>
                    Inactive
                  </Radio.Button>
                </Radio.Group>,
              )}
            </Form.Item>

            <hr />

            <h2>Card Link</h2>

            <Form.Item label="Button name to (EN)">
              {getFieldDecorator('cl_button_name_en', {
                initialValue: detail.cl_button_name_en,
                rules: [
                  { required: false, message: 'Please input button name!' },
                ],
              })(<Input placeholder="" />)}
            </Form.Item>
            <Form.Item label="Button name to (BM)">
              {getFieldDecorator('cl_button_name_mm', {
                initialValue: detail.cl_button_name_mm,
                rules: [
                  { required: false, message: 'Please input button name!' },
                ],
              })(<Input placeholder="" />)}
            </Form.Item>
            <Form.Item label="Button link to (EN)">
              {getFieldDecorator('cl_button_link_en', {
                initialValue: detail.cl_button_link_en,
                rules: [{ required: false, message: 'Please input link!' }],
              })(<Input placeholder="" />)}
            </Form.Item>
            <Form.Item label="Button link to (BM)">
              {getFieldDecorator('cl_button_link_mm', {
                initialValue: detail.cl_button_link_mm,
                rules: [{ required: false, message: 'Please input link!' }],
              })(<Input placeholder="" />)}
            </Form.Item>
            <Form.Item label="Content to (EN)">
              {getFieldDecorator('cl_content_en', {
                initialValue: detail.cl_content_en,
                rules: [{ required: false, message: 'Please input link!' }],
              })(<TextArea rows={5} placeholder="" />)}
            </Form.Item>
            <Form.Item label="Content to (BM)">
              {getFieldDecorator('cl_content_mm', {
                initialValue: detail.cl_content_mm,
                rules: [{ required: false, message: 'Please input link!' }],
              })(<TextArea rows={5} placeholder="" />)}
            </Form.Item>
            <Form.Item label="Show Status">
              {getFieldDecorator('cl_status', {
                initialValue: detail.cl_status,
                rules: [{ required: true, message: 'Please choose status!' }],
              })(
                <Radio.Group>
                  <Radio.Button value="1">
                    <i className="fa fa-check" style={{ marginRight: 5 }}></i>
                    Active
                  </Radio.Button>
                  <Radio.Button value="0">
                    <i className="fa fa-close" style={{ marginRight: 5 }}></i>
                    Inactive
                  </Radio.Button>
                </Radio.Group>,
              )}
            </Form.Item>

            <div
              style={{
                position: 'absolute',
                right: 0,
                bottom: 0,
                width: '100%',
                textAlign: 'left',
                zIndex: 2,
              }}
            >
              <div
                className="text-right"
                style={{
                  paddingBottom: 10,
                  paddingRight: 16,
                  paddingLeft: 16,
                  paddingTop: 16,
                  background: 'rgba(255, 255, 255, 0.75)',
                }}
              >
                <Text className="">Please complete all required field*</Text>
              </div>

              <Row
                style={{
                  width: '100%',
                  background: '#fff',
                  padding: '10px 16px',
                  borderTop: '1px solid #e9e9e9',
                }}
              >
                <Col span={12}>
                  <Button
                    icon="close"
                    onClick={this.onClose}
                    style={{ backgroundColor: '#EDEDED' }}
                  >
                    Close
                  </Button>
                </Col>
                <Col span={12} className="text-right">
                  {detail.key && (
                    <Button
                      style={{
                        marginRight: 8,
                      }}
                      type="danger"
                      ghost
                      icon="delete"
                      onClick={this.showDeleteConfirm}
                      disabled={isSendingRequest}
                    >
                      Delete
                    </Button>
                  )}
                  <Button
                    htmlType="submit"
                    type="primary"
                    icon="save"
                    loading={isSendingRequest}
                    disabled={isSendingRequest}
                  >
                    {!detail.key ? 'Create' : 'Save'}
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        </Drawer>
        <Row>
          <Col span={24}>
            <Title level={1}>Article</Title>
          </Col>
        </Row>
        <div className="paper-panel">
          <Row>
            <Col span={18}>
              <Search
                placeholder="Title"
                onChange={({ target: { value } }) =>
                  this.handleSearchChange(value)
                }
                style={{ width: 240, marginRight: 8 }}
              />
              <Select
                defaultValue=""
                style={{ width: 150, marginRight: 8 }}
                onChange={this.handleCategoryChange}
              >
                <Select.Option value="">All Category</Select.Option>
                {category.map((value) => (
                  <Select.Option key={value.id} value={value.id}>
                    {value.name_en}
                  </Select.Option>
                ))}
              </Select>
              <Select
                defaultValue=""
                value={searchTopic}
                style={{ width: 150 }}
                onChange={this.handleTopicChange}
              >
                <Select.Option value="">All Topic</Select.Option>
                {topic.map((value) => (
                  <Select.Option key={value.id} value={value.id}>
                    {value.name_en}
                  </Select.Option>
                ))}
              </Select>
            </Col>
            <Col span={6} className="text-right">
              <Button
                type="primary"
                // style={{ minWidth: 140 }}
                onClick={() => this.showDrawer(-1)}
              >
                <Icon type="plus" />
                Create
              </Button>
            </Col>
          </Row>
          <Table
            columns={this.columns}
            dataSource={this.state.data}
            pagination={this.state.pagination}
            loading={this.state.loading}
            onChange={this.handleTableChange}
            rowKey={'key'}
          />
        </div>
      </div>
    );
  }
}

export default Form.create({ name: 'article_form' })(App);
