import React from 'react';
import {
  Modal,
  Form,
  Typography,
  Table,
  Button,
  Icon,
  Row,
  Col,
  Input,
  Select,
  Checkbox,
  Drawer,
  notification,
} from 'antd';
import 'antd/dist/antd.css';
import './sampling.css';
import reqwest from 'reqwest';
import Dotdotdot from 'react-dotdotdot';
import axios from 'axios';
import moment from 'moment';

const { Search } = Input;
const { Text, Title } = Typography;

let searchTimeoutId = false;

class App extends React.Component {
  state = {
    data: [],
    pagination: { pageSize: 10 },
    loading: false,
    visible: false,
    isSendingRequest: false,
    isDoneRequest: false,
    isExportingRequest: false,
    saveButton: false,
    searchValue: '',
    searchType: '',
    searchPregnant: '',
    searchStatus: '',
    detail: {
      id: '',
      fullname: '',
      status: '1',
      phone: '',
      is_pregnant: false,
      address_1: '',
      address_2: '',
      child_fullname: '',
      child_birthdate: '',
      get_support_by: '',
      profile_status: '',
    },
  };

  constructor(props) {
    super(props);

    this.columns = [
      {
        title: 'Requested',
        dataIndex: 'created_time',
        key: 'message',
        render: (data) => (
          <div>
            <Dotdotdot clamp={1}>
              <Text style={{ wordWrap: 'break-word' }}>
                {moment(data).format('DD/MM/YYYY')}
              </Text>
            </Dotdotdot>
          </div>
        ),
      },
      {
        title: 'Full name',
        dataIndex: 'fullname',
        key: 'fullname',
        render: (data) => (
          <div>
            <Dotdotdot clamp={1}>
              <Text style={{ wordWrap: 'break-word' }}>{data}</Text>
            </Dotdotdot>
          </div>
        ),
      },
      {
        title: 'Phone number',
        dataIndex: 'phone',
        key: 'phone',
        render: (data, row) => (
          <div>
            <Text>{data}</Text>
          </div>
        ),
      },
      {
        title: 'You are',
        dataIndex: 'type',
        key: 'type',
        render: (data, row) => (
          <div>
            <Text>{data.replace(/^\w/, (c) => c.toUpperCase())}</Text>
          </div>
        ),
      },
      {
        title: 'Are you pregnant?',
        dataIndex: 'pregnant',
        key: 'active',
        align: 'center',
        render: (pregnant) => (
          <div>
            <Text>{pregnant}</Text>
          </div>
        ),
      },
      {
        title: '',
        dataIndex: 'id',
        key: 'key',
        width: 100,
        render: (key) => (
          <Button
            style={{ width: 80 }}
            onClick={() => {
              this.showDrawer(key);
            }}
            className="icon-btn"
          >
            <Icon type="profile" />
            Detail
          </Button>
        ),
      },
      {
        title: 'Done',
        dataIndex: 'done',
        key: 'done',
        align: 'center',
        width: 100,
        render: (key, record, index) => {
          return (
            <Button
              // ghost={record.done === 1 ? true : false}
              type={record.done === 1 ? 'primary' : 'default'}
              icon={record.done === 1 ? 'check' : ' '}
              size="small"
              onClick={() => {
                this.handleToggleStatus(record.id);
              }}
            />
          );
        },
      },
    ];
  }

  componentDidMount() {
    document.title = 'Request Sampling';
    this.fetch();
  }

  fetch = (params = {}) => {
    this.props.pageLoading(true);

    reqwest({
      url: process.env.REACT_APP_API_URL + '/sampling',
      method: 'get',
      data: {
        size: this.state.pagination.pageSize,
        search: this.state.searchValue,
        type: this.state.searchType,
        pregnant: this.state.searchPregnant,
        status: this.state.searchStatus,
        ...params,
      },
      type: 'json',
    })
      .then((data) => {
        this.setState({
          data: data.results,
        });

        document.body.style.overflow = null;
      })
      .catch((err) => {})
      .always(() => {
        this.props.pageLoading(false);
      });
  };

  sendDoneRequest = (data = {}) => {
    return reqwest({
      url: process.env.REACT_APP_API_URL + '/sampling/done',
      method: 'post',
      type: 'json',
      data,
    })
      .then(() => {
        notification.success({
          key: 'notification',
          message: 'Success',
          description: `Update complete!`,
          duration: 3,
        });

        this.setState({ visible: false });
        this.fetch();
      })
      .catch((err) => {
        let message = err.message;

        if (err.responseText) {
          let jsonResponse = JSON.parse(err.responseText);
          message = jsonResponse.message;
        }

        notification.error({
          key: 'notification',
          message: 'Fail',
          description: message,
          duration: 5,
        });
      })
      .always(() => {
        this.setState({ isDoneRequest: false });
        // this.props.pageLoading(false);
      });
  };

  deleteRequest = (data = {}) => {
    return reqwest({
      url: process.env.REACT_APP_API_URL + '/sampling/delete',
      method: 'post',
      type: 'json',
      data,
    })
      .then(() => {
        notification.success({
          key: 'notification',
          message: 'Success',
          description: 'Delete complete!',
          duration: 3,
        });

        this.setState({ visible: false });

        this.fetch();
      })
      .catch((err) => {
        let message = err.message;

        if (err.responseText) {
          let jsonResponse = JSON.parse(err.responseText);
          message = jsonResponse.message;
        }

        notification.error({
          key: 'notification',
          message: 'Fail',
          description: message,
          duration: 5,
        });
      })
      .always(() => {
        this.setState({ isSendingRequest: false });
      });
  };

  exportRequest = (params = {}) => {
    this.setState({ isExportingRequest: true });

    axios
      .get(process.env.REACT_APP_API_URL + '/sampling/export', {
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json',
        },
        params,
      })
      .then((res) => {
        let fileName;

        const header = res.headers['content-disposition'];
        if (header) {
          fileName = header.split(';')[1].split('=')[1].replace(/["']/g, '');
        }

        if (!fileName) {
          fileName = `sampling_${+new Date()}.xlsx`; // whatever your file name .
        }

        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;

        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove(); // you need to remove that element which is created before.
      })
      .catch((err) => {
        let message = err.message;

        if (err.responseText) {
          let jsonResponse = JSON.parse(err.responseText);
          message = jsonResponse.message;
        }

        notification.error({
          key: 'notification',
          message: 'Fail',
          description: message,
          duration: 5,
        });
      })
      .then(() => {
        // always executed
        this.setState({ isExportingRequest: false });
      });
  };

  handleToggleStatus = (id) => {
    this.sendDoneRequest({ id });
  };

  showDrawer = async (key) => {
    const data = this.state.data.find(({ id }) => id === key) || {};

    this.setState({
      detail: data,
      visible: true,
    });
  };

  showDeleteConfirm = () => {
    Modal.confirm({
      title: 'Confirm',
      // icon: <ExclamationCircleOutlined />,
      content: 'Do you want to delete this item?',
      okText: 'Yes, Delete',
      cancelText: 'No',
      okType: 'danger',
      onOk: (e) => {
        const detail = this.state.detail;

        return this.deleteRequest({ id: detail.id });
      },
      onCancel: (e) => {
        Modal.destroyAll();
      },
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  getIndexInParent = (el) => {
    return Array.from(el.parentNode.children).indexOf(el);
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });

    this.fetch({
      size: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters,
    });
  };

  handleSearchChange(value) {
    this.setState({ searchValue: value });
    if (searchTimeoutId) {
      window.clearTimeout(searchTimeoutId);
    }
    searchTimeoutId = window.setTimeout(() => {
      this.fetch();
    }, 1500);
  }

  handleSearchType = (value) => {
    this.setState({ searchType: value });
    if (searchTimeoutId) {
      window.clearTimeout(searchTimeoutId);
    }
    searchTimeoutId = window.setTimeout(() => {
      this.fetch();
    }, 1500);
  };

  handleSearchPregnant = (value) => {
    this.setState({ searchPregnant: value });
    if (searchTimeoutId) {
      window.clearTimeout(searchTimeoutId);
    }
    searchTimeoutId = window.setTimeout(() => {
      this.fetch();
    }, 1500);
  };

  handleSearchStatus = (value) => {
    this.setState({ searchStatus: value });
    if (searchTimeoutId) {
      window.clearTimeout(searchTimeoutId);
    }
    searchTimeoutId = window.setTimeout(() => {
      this.fetch();
    }, 1500);
  };

  handleExport() {
    // window.open(
    //   process.env.REACT_APP_API_URL +
    //     '/sampling/export?search=' +
    //     this.state.searchValue,
    // );

    this.exportRequest({ search: this.state.searchValue || '' });
  }

  render() {
    const { isSendingRequest, searchValue, detail } = this.state;
    // const { getFieldDecorator } = this.props.form;

    return (
      <div id="sampling-component">
        <Row>
          <Col span={24}>
            <Title level={1}>Request Sampling</Title>
          </Col>
        </Row>
        <Drawer
          title="Detail"
          placement="right"
          closable={false}
          width={550}
          onClose={this.onClose}
          visible={this.state.visible}
          bodyStyle={{ paddingBottom: 80 }}
        >
          <Row>
            <Col span={6} style={{ textAlign: 'right', marginRight: '8px' }}>
              <Text>Full name:</Text>
            </Col>
            <Col span={8}>
              <Text>{detail.fullname}</Text>
            </Col>
          </Row>
          <Row>
            <Col span={6} style={{ textAlign: 'right', marginRight: '8px' }}>
              <Text>Phone number:</Text>
            </Col>
            <Col span={8}>
              <Text>{detail.phone}</Text>
            </Col>
          </Row>
          <Row>
            <Col span={6} style={{ textAlign: 'right', marginRight: '8px' }}>
              <Text>Address:</Text>
            </Col>
            <Col span={8}>
              <Text>
                {detail.address1} {detail.address2} {detail.address3}
              </Text>
            </Col>
          </Row>
          <Row>
            <Col span={6} style={{ textAlign: 'right', marginRight: '8px' }}>
              <Text>You are?</Text>
            </Col>
            <Col span={8}>
              <Text>{detail.type}</Text>
            </Col>
          </Row>
          <Row>
            <Col span={6} style={{ textAlign: 'right', marginRight: '8px' }}>
              <Text>Are you pregnant?</Text>
            </Col>
            <Col span={8}>
              <Text>{detail.pregnant}</Text>
            </Col>
          </Row>
          <Row>
            <Col span={6} style={{ textAlign: 'right', marginRight: '8px' }}>
              <Text>Child name:</Text>
            </Col>
            <Col span={8}>
              <Text>{detail.child_fullname}</Text>
            </Col>
          </Row>
          <Row>
            <Col span={6} style={{ textAlign: 'right', marginRight: '8px' }}>
              <Text>Child birthdate:</Text>
            </Col>
            <Col span={8}>
              <Text>{detail.child_birthday}</Text>
            </Col>
          </Row>
          <Row>
            <Col span={6} style={{ textAlign: 'right', marginRight: '8px' }}>
              <Text>Current milk:</Text>
            </Col>
            <Col span={8}>
              <Text>{detail.milk_brand}</Text>
            </Col>
          </Row>
          <Row>
            <Col span={6} style={{ textAlign: 'right', marginRight: '8px' }}>
              <Text>Status:</Text>
            </Col>
            <Col span={8}>
              <Checkbox
                dataId={detail.id}
                checked={detail.done === 1}
                onChange={() => {
                  this.handleToggleStatus(detail.id);
                }}
              />
            </Col>
          </Row>
          <div
            style={{
              position: 'absolute',
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e8e8e8',
              padding: '10px 16px',
              left: 0,
              background: '#fff',
              borderRadius: '0 0 4px 4px',
              zIndex: 2,
            }}
          >
            <Row>
              <Col span={12}>
                <Button
                  icon="close"
                  onClick={this.onClose}
                  style={{ backgroundColor: '#EDEDED' }}
                >
                  Close
                </Button>
              </Col>
              <Col span={12} className="text-right">
                <Button
                  type="danger"
                  ghost
                  icon="delete"
                  onClick={this.showDeleteConfirm}
                  disabled={isSendingRequest}
                >
                  Delete
                </Button>
              </Col>
            </Row>
          </div>
        </Drawer>

        <div className="paper-panel">
          <Row gutter={{ xs: 8, sm: 8, md: 8, lg: 8 }}>
            <Col span={12}>
              <Search
                placeholder="Full name / Phone Number "
                onChange={({ target: { value } }) =>
                  this.handleSearchChange(value)
                }
                style={{ width: '100%', marginRight: 15 }}
                value={searchValue}
              />
            </Col>
            <Col span={4}>
              <Select
                style={{ width: '100%', marginRight: 8 }}
                placeholder="You are"
                onChange={this.handleSearchType}
              >
                <Select.Option value="">All</Select.Option>
                <Select.Option value="mom">Mom</Select.Option>
                <Select.Option value="dad">Dad</Select.Option>
                <Select.Option value="others">Others</Select.Option>
              </Select>
            </Col>
            <Col span={4}>
              <Select
                style={{ width: '100%', marginRight: 8 }}
                placeholder="Pregnant"
                onChange={this.handleSearchPregnant}
              >
                <Select.Option value="">All</Select.Option>
                <Select.Option value="Yes">Yes</Select.Option>
                <Select.Option value="No">No</Select.Option>
                <Select.Option value="Trying to conceive">
                  Trying to conceive
                </Select.Option>
              </Select>
            </Col>
            <Col span={4}>
              <Select
                style={{ width: '100%', marginRight: 8 }}
                placeholder="Status"
                onChange={this.handleSearchStatus}
              >
                <Select.Option value="">All</Select.Option>
                <Select.Option value="1">Done</Select.Option>
                <Select.Option value="0">Undone</Select.Option>
              </Select>
            </Col>
            {/* <Col span={12} className="text-right">
              <Button
                type="primary"
                icon="file-excel"
                loading={isExportingRequest}
                disabled={isExportingRequest}
                onClick={() => this.handleExport()}
              >
                Export
              </Button>
            </Col> */}
          </Row>

          <Table
            rowKey="id"
            columns={this.columns}
            dataSource={this.state.data}
            pagination={this.state.pagination}
            loading={this.state.loading}
            onChange={this.handleTableChange}
          />
        </div>
      </div>
    );
  }
}

export default Form.create({ name: 'sampling' })(App);
